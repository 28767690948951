import React from 'react';
import styled from 'styled-components';

import shop from 'Images/join-bys.jpg';
import save from 'Images/shop-online.png';
import grow from 'Images/get-paid.jpg';

import styles from './index.css';

const HowItWorksWrapper = styled.div`${styles}`;

const HowItWorks = ({ content }) => (
  <HowItWorksWrapper className="hide-scrollbars" id="how-it-works">
    <h2>{content.how_it_works_heading}</h2>
    <div className="steps-container">
      <div className="step">
        <div className="icon-container">
          <img src={content.how_it_works_image_1} alt="shop" />
        </div>
        <div className="description">
            <b className="title">{content.how_it_works_step_1}</b>
          <span className="text">{content.how_it_works_text_1}</span>
        </div>
      </div>
      <div className="arrow">
        <i className="material-icons">keyboard_backspace</i>
      </div>
      <div className="step">
        <div className="icon-container">
          <img src={content.how_it_works_image_2} alt="shop" />
        </div>
        <div className="description">
            <b className="title">{content.how_it_works_step_2}</b>
          <span className="text">{content.how_it_works_text_2}</span>
        </div>
      </div>
      <div className="arrow">
        <i className="material-icons">keyboard_backspace</i>
      </div>
      <div className="step">
        <div className="icon-container">
          <img src={content.how_it_works_image_3} alt="shop" />
        </div>
        <div className="description">
            <b className="title">{content.how_it_works_step_3}</b>
          <span className="text">{content.how_it_works_text_3}</span>
        </div>
      </div>
    </div>
  </HowItWorksWrapper>
);

export default HowItWorks;
