import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import SvgIcon from "@mui/material/SvgIcon";

import ActionButton from 'SharedComponents/action-button';
import DropDownSelect from 'SharedComponents/dropdown-select';
import PopoverHover from 'SharedComponents/popover-hover';
import ModalDialogV3 from 'SharedComponents/modal-dialog-v3';

//import useBroadcastAbly from 'SharedHooks/receive-broadcast';

//import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

import style from './index.css';

const ContributeNowButtonWrapper = styled.div`${style}`;

const ContributeNowButton = ({
  contributeAndShop, largeButton, onShopNow, onShopNowType, storeDetails
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const roundups = useSelector(state => state.roundups);

  // useBroadcastAbly({
  //   channelName: `private:shopper.${authentication?.user.shopper_id}`,
  // });

  const currentPath = window.location.pathname;
  // Check if current location is not on settings. THis is to be removed once the router is updated and the link works on the settings path.
  const currentPathisNotOnSettings = !currentPath.includes('/settings');

   console.log ('ContributeNowButton triggered with largeButton:', largeButton);
  // console.log ('ContributeNowButton triggered with contributeAndShop:', contributeAndShop);
  // console.log('ContributeNowButton triggered with roundups:', roundups);
  // console.log('ContributeNowButton triggered with Object.keys(roundups).length : :', Object.keys(roundups).length);
  // console.log('ContributeNowButton triggered with storeDetails:', storeDetails);  
  // console.log('ContributeNowButton triggered with authentication:', authentication);

  const contributeNowOptions = [
  {
    label: '$1.00',
    value: 100, 
  }, {
    label: '$2.00',
    value: 200, 
  }, {
    label: '$5.00',
    value: 500, 
  }];

  const [showModalDialogV3, setShowModalDialogV3] = useState(false);
  const [localUnderstandsContributeNowFundsTransfer, setLocalUnderstandsContributeNowFundsTransfer] = useState(false); //useState(roundups.acceptedTerms);
  //const [localContributeNowContinueNotification, setLocalContributeNowContinueNotification] = useState(!roundups.contributeNowContinueNotification); //useState(roundups.acceptedTerms);

  const [localRoundups, setLocalRoundups] = useState(roundups);

  const [contributing, setContributing] = useState(false);
  const [onContributeSuccess, setOncontributeSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  //const [contributeNowAmount, setContributeNowAmount] = useState( roundups.contributeNowAmount );
  const [updatingContributeNowAmount, setUpdatingContributeNowAmount] = useState(false);
  const [contributeNowInfo, setContributeNowInfo] = useState('');

  //const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();

  // useEffect(() => {
  //   console.log('ContributeNowButton useEffect triggered with roundups: ', roundups);
  //   console.log('ContributeNowButton useEffect triggered with authentication: ', authentication);
  //     if ( 
  //         authentication?.user && 
  //         typeof roundups.accountsConnectingStatus === "undefined") 
  //       {fetchRoundUpsShopperData();}
  //        // { fetchRoundUpsUserData();
  //     //}
  // }, [roundups]); ///authentication change, roundups change, next iteration tie it to specific roundup variables
  //***CHECK DEPENDANCIES AS CURRENTLY SET TO GET THROUGH UPGRADE. POSSIBLY SHOULD BE MORE SPECIFIC
  useEffect(() => {
    console.log('ContributeNowButton useEffect triggered with roundups: ', roundups);
    console.log('ContributeNowButton useEffect triggered with authentication: ', authentication);
    if (localRoundups !== roundups) {
      console.log('ContributeNowButton useEffect triggered with roundups: ', roundups);
      setLocalRoundups(roundups);
    } 
  }, [roundups]);

  // useEffect(() => {
  //   console.log('useEffect triggered to update local contributeNowAmount with roundups.contributeNowAmount: ', roundups.contributeNowAmount); 
  //   setContributeNowAmount( roundups.contributeNowAmount );
  // }, [roundups.contributeNowAmount]);

  useEffect(() => {   //probably better to use onClose?
    if (!showModalDialogV3 && onContributeSuccess) { 
        setTimeout(() => {
          setOncontributeSuccess(false);
          if (contributeAndShop) { 
            console.log('contributeAndShop is : ', contributeAndShop);
            console.log('contributeAndShop trigger onShopNow with storeDetails: ', storeDetails);
            console.log('contributeAndShop trigger onShopNow with onShopNowType: ', onShopNowType);
            onShopNow( storeDetails, onShopNowType ) 
          }
        }, 2000);
    }
  }, [showModalDialogV3]);

  const handleClick = () => {
    console.log('handleClick triggered with button contributeAndShop: ', contributeAndShop);
    setShowModalDialogV3(true);
  };

  const toggleUnderstandsContributeNowFundsTransfer = () => {
    console.log('toggleUnderstandsContributeNowFundsTransfer triggered');
    console.log('localUnderstandsContributeNowFundsTransfer: ', localUnderstandsContributeNowFundsTransfer);
    setLocalUnderstandsContributeNowFundsTransfer((prevLocalUnderstandsContributeNowFundsTransfer) => !prevLocalUnderstandsContributeNowFundsTransfer);
    //updateUnderstandsContributeNowFundsTransfer(!localUnderstandsContributeNowFundsTransfer);
  };

  const toggleContributeNowContinueNotification = () => {
    console.log('toggleContributeNowContinueNotification triggered');
    console.log('localRoundups.contributeNowContinueNotification: ', localRoundups.contributeNowContinueNotification);
    setLocalContributeNowContinueNotification((prevLocalContributeNowContinueNotification) => !prevLocalContributeNowContinueNotification);
    //updateContributeNowContinueNotification(!localRoundups.contributeNowContinueNotification);
  };

  const getContributeNowLabel = (amount) => {
    const option = contributeNowOptions.find((option) => option.value === amount);
    return option ? option.label : '';
  };

  const hasRoundupsDebitEnabled = function(localRoundups) {
    if (
      localRoundups &&
      localRoundups.linkedAccounts &&
      Array.isArray(localRoundups.linkedAccounts) &&
      localRoundups.linkedAccounts.length > 0
    ) {
      for (let i = 0; i < localRoundups.linkedAccounts.length; i++) {
        if (localRoundups.linkedAccounts[i].debit_enabled === true) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (!localRoundups.acceptedRoundupsTerms ) {
        const infoMessage1 = `You must enable ROUND UPS on your account before you can use ${ !contributeAndShop ? 'CONTRIBUTE NOW' :  'CONTRIBUTE & SHOP' }`;
        setContributeNowInfo(infoMessage1);
    } else if ( !localRoundups.linkedAccounts || localRoundups.linkedAccounts.length === 0 ) {
        const infoMessage2 = `You must link a bank account (open banking) found under the ROUND UPS TAB settings before you can use ${ !contributeAndShop ? 'CONTRIBUTE NOW' :  'CONTRIBUTE & SHOP'}`;
        setContributeNowInfo(infoMessage2);
    } else if (!hasRoundupsDebitEnabled(localRoundups)) {
        const infoMessage3 = "You must enable a DEBIT STATUS of an account found in YOUR LINKED ACCOUNTS under ROUND UPS TAB settings. This is where funds will be sourced to make contributions.";
        setContributeNowInfo(infoMessage3);
    } else if ( !localRoundups.acceptedContributeNowTerms ) {
        const infoMessage4 = `Please accept the CONTRIBUTE NOW T&Cs (including charges) before you can enable ${ !contributeAndShop ? 'CONTRIBUTE NOW' :  'CONTRIBUTE & SHOP' }`;
        setContributeNowInfo(infoMessage4);
    } else if ( !localRoundups.contributeNowEnabled ) {
        const infoMessage5 = `You must enable CONTRIBUTE NOW found in settings before you can use ${ !contributeAndShop ? 'CONTRIBUTE NOW' :  'CONTRIBUTE & SHOP' }`;
        setContributeNowInfo(infoMessage5);
    } else {
        setContributeNowInfo('');
    }
  }, [localRoundups.acceptedRoundupsTerms, localRoundups.acceptedContributeNowTerms, localRoundups.linkedAccounts, localRoundups.contributeNowEnabled]);

  async function onContributeNow() {
    console.log('onContributeNow button clicked')
    setContributing(true);
    try {
      const result = await dispatch(RoundUpsActions.payFixedAmount( localRoundups.contributeNowAmount ));
      console.log('onContributeNow result', result);
      console.log('onContributeNow result.data', result.data);
      console.log('onContributeNow result.data.success', result.data.success);
      console.log('onContributeNow result.data.message', result.data.message);
      if (result.data.success) { // need to ensure a success message is returned
          setOncontributeSuccess(true);
      } else if (!result.data.success) {
        //setErrorMessage(result.data.message);
        console.log('onContributeNow result.data.message', result.data.message);
        log({
          event: "Contribute-Now-Error",
          'amount': localRoundups.contributeNowAmount,
          //'shopper_id': authentication? authentication.user.shopper_id : '000',
          info: result.data.message,
          ...result.data.message,
        });
        setErrorMessage(result.data.message);

        setTimeout(() => {
					setErrorMessage(null);
				}, 5000);

      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "ContributeNowButton - onContributeNow",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      log({
        event: "Contribute-Now-Success",
        'amount': localRoundups.contributeNowAmount,
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
      });
      setContributing(false);
    };
  }

  async function updateContributeNowAmount() {
    //Will need to update back end api to accept the new amount before can use this
    console.log('updateContributeNowAmount triggered');
    setUpdatingContributeNowAmount(true);
    try {
      const result = await dispatch(RoundUpsActions.updateContributeNowAmount( localRoundups.contributeNowAmount ));
      console.log('updateContributeNowAmount  result', result);
      dispatch(RoundUpsActions.setContributeNowAmount( result.data.data.contributeNowAmount ));
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "ContributeNowButton - updateContributeNowAmount",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      log({
        event: "Contribute-Now-Success",
        'amount': localRoundups.contributeNowAmount,
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
      });
      setUpdatingContributeNowAmount(false);
    };
  }


  return (
    <ContributeNowButtonWrapper 
      largeButton={largeButton}
      contributeAndShop={contributeAndShop}
    >
      {/* { authentication && authentication.user && !contributeAndShop ? (
        <div>
          <BroadcastAbly 
            channelName={"private:shopper."+authentication.user.shopper_id}
            //fetchRoundUpsShopperData={fetchRoundUpsShopperData}
          />
        </div>
      ) : null } */}
      { authentication && authentication.user && authentication.user.contribute_now_visible
        ? (
          <div className="contribute-now-container">
            { localRoundups.contributeNowEnabled ? (  
              <button 
                type="button" 
                className="contribute-now-action-button" 
                onClick={handleClick}
                //onClick={onContributeNow}
                disabled={contributing}
                  
                /* disabled={localRoundups.contributeNowEnabled} */
                >{ !contributeAndShop ? 'CONTRIBUTE NOW' : 'CONTRIBUTE & SHOP'}
              </button>
            ) : (
              <div className="pop-over-container">
                  <PopoverHover
                    content={
                      <div className="content-container" style={{ maxWidth: '340px' }}>
                        <span>
                        <p style={{color:'red'}}>{contributeNowInfo}</p>
                          { !hasRoundupsDebitEnabled ?
                          (
                            currentPathisNotOnSettings && ( //this check can be removed once router is updated and link works on the settings path. 4 to be removed.
                              <Link className="link" to="/settings?tab=round-ups">Round Ups Settings</Link>
                            )
                          ) : (
                            currentPathisNotOnSettings && (
                              <Link className="link" to="/settings?tab=contribute-now">Contribute Now Settings</Link>
                            )
                          ) }
                        </span>
                      </div>
                    }
                    header = { !hasRoundupsDebitEnabled ? "Enable Round Ups Required" : "Contribute Now Setup Incomplete" }
                    img = {null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    itemToPopover = {
                      <button 
                        type="button" 
                        className="contribute-now-action-button" 
                        //onClick={onContributeNow}
                        onClick={handleClick}
                        disabled={true}
                        //style={{ maxWidth: !largeButton ? '90px' : 'none' }}//
                        >{ !contributeAndShop ? 'CONTRIBUTE NOW' : 'CONTRIBUTE & SHOP'}
                      </button>
                    }
                  />
              </div>
            ) }
              
            <div className="contribute-now-select-amount">
              <DropDownSelect 
                className="dropdown-menu"
           /*   value={ localRoundups.contributeNowAmount } 
                label={ localRoundups.contributeNowAmount } */
                value={ localRoundups.contributeNowAmount } 
                label={ localRoundups.contributeNowAmount }
                //style={{ maxWidth: !largeButton ? '90px' : 'none' }}//
                //valueField="validRoundUpAmounts.value"
                dropUp={true}

                onChange={(value) => {setContributeNowAmount(value.value)}}
                options={ contributeNowOptions }
                //labelField="validRoundUpAmounts.label"
              />
            </div>
            <div className="contribute-now-info">
              <div className="pop-over-container">
                  <PopoverHover
                    content={ 
                        <div className="content-container" style={{ maxWidth: '340px' }}>
                          <span>
                          { !contributeAndShop ? (
                              <p><strong>CONTRIBUTE NOW </strong> allows you to make a quick contribution to your super right now!</p>
                            ) : (
                              <div>
                                <p><strong>CONTRIBUTE & SHOP</strong> allows you to make a quick contribution to your super while clicking through to one of our partner shops. </p>
                                <p>An amount will be immediately taken from your chosen savings account and deposited in your super account. If you make a purchase at the shop you will also earn free cashback for your super account.</p>
                              </div>
                            )
                          }
                            { !localRoundups.contributeNowEnabled ? 
                            (
                              <div>
                                <p style={{color:'red'}}>{contributeNowInfo}</p>
                                { !hasRoundupsDebitEnabled ?
                                  (
                                    currentPathisNotOnSettings && (
                                      <Link className="link" to="/settings?tab=round-ups">Round Ups Settings</Link>
                                    )
                                  ) : (
                                    currentPathisNotOnSettings && (
                                      <Link className="link" to="/settings?tab=contribute-now">Contribute Now Settings</Link>
                                    )
                                  ) }
                              </div>
                            ) : null }
                          </span>
                        </div>
                    }
                    header = { !contributeAndShop ? 'HOW CONTRIBUTE NOW WORKS' : 'HOW CONTRIBUTE & SHOP WORKS'}
                    img = {null}
                    itemToPopover = {
                      ( <i className="material-icons" style={{ margin: '0 10px 0 10px', display: 'flex', fontSize: `${!largeButton ? '24px' : '44px'}`, paddingRight: '10px' }} >info</i> ) 
                    }
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  />
              </div>
            </div>
          </div>

        ) : null 
      }
      <div className="modal-container">
        <ModalDialogV3 
          errorMessage={errorMessage}
          open={showModalDialogV3}
          onCloseModalDialogV3={() => setShowModalDialogV3(false)}
          titleComponent={
                          <div className="contribute-now-modal-title"
                          style={{
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '70px',
                            justifyContent: 'center'
                          }}
                          > 
                          {   onContributeSuccess ?
                              'You have Boosted Your Super by ' + `${getContributeNowLabel(localRoundups.contributeNowAmount)}` + '!'
                              : contributing ? 
                                'Contributing ' + `${getContributeNowLabel(localRoundups.contributeNowAmount)}` + ' to Your Super ...'
                              : 'Continue to transfer ' + `${getContributeNowLabel(localRoundups.contributeNowAmount)}` + ' to Your Super'
                          }
                          </div>
                          }
          explainerComponent={ 
            onContributeSuccess ?
                <div
                className="flex-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: 15
                }}
              >
                <div
                  className="flex-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 10px 0 0",
                    backgroundColor: "#c6ffc6",
                    padding: 3,
                    borderRadius: "50%"
                  }}
                >
                  <SvgIcon>
                    <path
                      fill="green"
                      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                    />
                  </SvgIcon>
                </div>
                <span style={{ fontSize: 20 }}>
                A funds transfer of {getContributeNowLabel(localRoundups.contributeNowAmount)} was successfull
                </span>
              </div>

            : !contributeAndShop ? 
              null
            : storeDetails && storeDetails.merchant_name ?
              <p>After funds are transferred you will be taken to <strong>{storeDetails.merchant_name}</strong> where you can earn further cashback for your super if you make a purchase.</p>
            : null
          }
          contentComponent={
            onContributeSuccess ?
               null
            :  contributing ? 
                ( <div className="contribute-notification" 
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '300px',
                      justifyContent: 'center'
                    }}
                  >
                    <div className="transffering-funds"
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 5, 
                        marginBottom: '5px', 
                        alignItems: 'center', 
                      }}
                    >
                      <BarLoader
                        sizeUnit={"px"}
                        size={200}
                        color="#000"
                        loading={true} 
                      />
                    </div>
                  </div>
                ) : (
                  <div className="contribute-notification" 
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px',
                    justifyContent: 'center'
                  }}
                  >
                      <div className="checkbox-entry"
                          style={{
                            display: 'flex',
                            padding: 5, 
                            marginBottom: '5px', 
                            alignItems: 'center', 
                          }}
                      >
                        <input
                          className="input-checkbox"
                          name="contirbute-now-notification"
                          type="checkbox"
                          placeholder="I understand an amount will be removed from my bank account"
                          checked={localUnderstandsContributeNowFundsTransfer}
                          required
                          onChange={ toggleUnderstandsContributeNowFundsTransfer }
                          style={{
                            minWidth: '30px',
                            height: '30px',
                            marginRight: '10px', 
                          }}
                        />
                        <span>I understand if I continue funds will be immediately tranferred from my savings account and deposited in my nominated superannuation account</span>
                        <span className="pop-over-container">
                            <PopoverHover
                              content={
                                <div style={{
                                  maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                }}
                                >
                                If you continue an amount will be immediately scheduled for transfer from your savings account to your superannuation account.
                                </div>
                              }
                              header = {null}
                              img = {null}
                              itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                              }
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                            />
                        </span>
                      </div>

                      <div className="checkbox-entry"
                          style={{
                            display: 'flex',
                            padding: 5, 
                            marginBottom: '5px', 
                            alignItems: 'center', 
                          }}
                      >
                        <input
                          className="input-checkbox"
                          name="contirbute-now-notification"
                          type="checkbox"
                          placeholder="I understand an amount will be removed from my bank account"
                          checked={localRoundups.contributeNowContinueNotification}
                          required
                          onChange={ toggleContributeNowContinueNotification }
                          style={{
                            minWidth: '30px',
                            height: '30px',
                            marginRight: '10px', 
                          }}
                        />
                        <span>As I understand how CONTRIBUTE NOW and CONTRIBUTE & SHOP works, do not show me this notification in the future. </span>
                        <span className="pop-over-container">
                            <PopoverHover
                              content={
                                <div style={{
                                  maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                }}
                                >
                                You can learn more about CONTRIBUTE NOW and CONTRIBUTE & SHOP here
                                </div>
                              }
                              header = {null}
                              img = {null}
                              itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                              }
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                            />
                        </span>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginTop: 35, marginBottom: 35 }}>
                        <ActionButton
                          text={'Continue'}
                          loading={contributing}
                          active={!contributing}
                          onClick={ onContributeNow }
                          color="#fff"
                          large={true}
                          disabled={!localUnderstandsContributeNowFundsTransfer }
                          title="Fill all mandatory fields before submitting"
                        />
                      </div>
                  </div>
                )
          }
        /> 
    </div>
    </ContributeNowButtonWrapper>
  );
};

ContributeNowButton.propTypes = {
  largeButton: PropTypes.bool,
  contributeAndShop: PropTypes.bool,
};

ContributeNowButton.defaultProps = {
  largeButton: false,
};

export default memo(ContributeNowButton);
