import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Fuse from 'fuse.js';
import GetExtensionStrip from 'SharedComponents/extension-install-strip';
import log from "Utils/logger";


import ShareStrip from 'SharedComponents/share-strip';
//import ShopsStrip from 'SharedComponents/shops-strip';
import Footer from 'SharedComponents/footer';
import { isChrome, isMobile } from 'react-device-detect';
// import FAQ from 'SharedComponents/faq';
//import ShopsCategories from 'SharedComponents/shops-categories';
import PromotedDeals from 'SharedComponents/promoted-deals';
// import HowItWorksTestimonials from 'SharedComponents/how-it-works-testimonials';
import LandingActionsBanner from 'SharedComponents/landing-actions-banner';
import BlackFridayBanner from 'SharedComponents/black-friday-banner';
import OnboardingJourney from 'SharedComponents/onboarding-journey';

import styles from './index.css';

import CONFIG from '../../../config';
import * as ShoppingActions from 'Flux/shopping/actions';
import * as TestimonialActions from 'Flux/testimonials/actions';
import * as AuthenticationActions from'Flux/authentication/actions';
import * as AppActionCreators from 'Flux/app/actions';

const LandingWrapper = styled.div`${styles}`;

@connect((state) => ({
  app: state.app,
  shopping: state.shopping,
  user: state.authentication.user,
  testimonials: state.testimonials,
}), (dispatch) => ({
  ShoppingActions: bindActionCreators(ShoppingActions, dispatch),
  TestimonialActions: bindActionCreators(TestimonialActions, dispatch),
  AppActions: bindActionCreators(AppActionCreators, dispatch),
  dispatch
}))
export default class Landing extends Component {
  constructor(props) {
    super(props);

    this.fetchDeals = this.fetchDeals.bind(this);
    this.fetchStores = this.fetchStores.bind(this);
    this.fetchStoreTags = this.fetchStoreTags.bind(this);
    this.fetchFavoriteShops = this.fetchFavoriteShops.bind(this);
    this.fetchCategories = this.fetchCategories.bind(this);
    this.onToggleLandingBanner = this.onToggleLandingBanner.bind(this);
    this.checkExtensionInstallationStatus = this.checkExtensionInstallationStatus.bind(this);
    //this.setFavShops = this.setFavShops.bind(this);


    this.state = {
      isFetchingDeals: false,
      isFetchingStores: false,
      hasFetchedStores: false,
      isFetchingPromotedDeals: false,
      isFetchingCategories: false,
      isFetchingFavoriteShops: false,
      hasFetchedFavoriteShops: false,
      isFetchingStoreTags: false,
      hasFetchedStoreTags: false,
      stores: props.shopping.stores,
      storesList: new Fuse(props.shopping.stores, { tokenize: true, threshold: 0.0, keys: ['merchant_name', 'categories_string', 'merchant_description'] }),
      favorites: props.shopping.favorites,
      favoritesList: new Fuse(props.shopping.favorites, { tokenize: true, threshold: 0.0, keys: ['merchant_name', 'categories_string', 'merchant_description'] }),
      isFetchingLandingPageContent: false,
      landingBannerOption: true,
      isExtensionInstalled: props.app.isExtensionInstalled,
    }
  }

  componentDidMount() {
    const { testimonials } = this.props;
    const { app } = this.props;
    console.log(['Landing Main * retail stores length : ', this.props.shopping.stores.length]);
    if (!this.props.shopping.stores.length){
 //     this.fetchStores();
 //     this.fetchDeals();
 //     this.fetchCategories();
 //     this.fetchStoreTags();
    }
 //   this.checkExtensionInstallationStatus();

    
    console.log("lets fetch Landing Page Content on Main in cDM");
    //this.fetchLandingPageContent();

    //this.fetchFavoriteShops();

    if (!testimonials.length) {
      this.fetchTestimonials();
    }

    console.log("about to check landing page content for this.props.app.landingPageContent.length : ");
    console.log("app.LandingPageContent state is in cDM", app.landingPageContent);
    if (app.landingPageContent == null) {
      console.log("**IT HAS NO LENGTH ... lets kick of fetchLandingPageCOntet");
      this.fetchLandingPageContent();

    }


  }
  componentDidUpdate(props,state){
     console.log(['componentDidUpdateTriggered(Landing Main) with state & props: ', state, props]);
//     if (this.props.authentication.user){  
//      console.log(['componetDidUpdateTriggered(Landing Main) : User is authenticated', state]);  
      if (state.hasFetchedStores) {
        console.log(['componetDidUpdate (Landing Main): stores are fetched', state]);
        if (!state.hasFetchedFavoriteShops){
         console.log(['componetDidUpdate(Landing Main) : fAv shops not yet fetched', state]);
         if(!state.isFetchingFavoriteShops) {
          console.log(['componetDidUpdate(Landing Main) : FetchingFaveShops is false', state]);
          if(!state.favorites.length){
           console.log(['componetDidUpdate(Landing Main) : FaveLength is zero - trigger fetchFavShops', state]); 
           //this.fetchFavoriteShops();
          }
           else {
            console.log(['componetDidUpdate(Landing Main) : Updating due to stores fetch - fetchFavShops', state]); 
            //this.fetchFavoriteShops();
           }
          }
        }
      }
    //}
  }

  static getDerivedStateFromProps(props, state) {
    console.log(['LandingMain - GDSFP triggered with state : ', state]);
    if (JSON.stringify(props.shopping.stores).length !== JSON.stringify(state.stores).length) {
      return {
        stores: props.shopping.stores,
        storesList: new Fuse(props.shopping.stores, {
          tokenize: true,
          threshold: 0.1,
          matchAllTokens: true,
          minMatchCharLength: 3,
          keys: [ 
            { name: "merchant_name", weight: 0.4 }, 
            { name: "tags", weight: 0.3 },
            { name: "categories_string", weight: 0.2 },
            { name: "merchant_description", weight: 0.1 } 
          ]
        }),
      }
    }
    if (props.app.isExtensionInstalled !== state.isExtensionInstalled) {
      return {
        isExtensionInstalled: props.app.isExtensionInstalled,
      }
    }
  }


  fetchLandingPageContent = async () => {
    const { AppActions } = this.props;
    this.setState({ isFetchingLandingPageContent: true });
    console.log("fetching LandingPageContent from DASHBOARD");
    try {
      const result = await AppActions.fetchLandingPageContent();
      AppActions.setLandingPageContent(result.data.content[0]);
    } catch (exception) {
      console.log('exception fetching landing page content is:', exception);
    } finally {
      this.setState({ isFetchingLandingPageContent: false });
      //console.log("app state after landing content fetch is: ", state.app);
      console.log("at dashboard landing page this.props.app is : ", this.props.app);
    }
  }


  fetchTestimonials = async () => {
    this.setState({ isFetchingTestimonials: true });
    const { TestimonialActions } = this.props;

    try {
      const fetchTestimonialsResult = await TestimonialActions.fetchTestimonials();

      TestimonialActions.setTestimonials(fetchTestimonialsResult.data.testimonials);
    } catch (exception) {
      console.log(exception);
    } finally {
      this.setState({ isFetchingTestimonials: false });
    }
  }

  onToggleLandingBanner() {
    const { landingBannerOption } = this.state;
    this.setState({ landingBannerOption: !landingBannerOption });
  }

  async fetchCategories() {
    const { ShoppingActions } = this.props;

    this.setState({ isFetchingCategories: true });

    try {
      const fetchCategoriesResult = await ShoppingActions.fetchCategories();
      ShoppingActions.addCategories(fetchCategoriesResult.data.tags);
    } catch (exception) {
      console.log(exception);
    } finally {
      this.setState({ isFetchingCategories: false });
    }
  }


  async fetchStoreTags() {
    const { ShoppingActions } = this.props;

    this.setState({ isFetchingStoreTags: true });
    {console.log('[About To Fetch StoreTags]')};
    try {
      const fetchStoreTagsResult = await ShoppingActions.fetchStoreTags();
      ShoppingActions.addStoreTags(fetchStoreTagsResult.data.data);
      console.log('fetchStoreTagsResult is: ', fetchStoreTagsResult );
      this.setState({ hasFetchedStoreTags: true });
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "FetchStoreTags - Landing Main",
        info: exception,
      });
    } finally {
      this.setState({ isFetchingStoreTags: false });
    }
  }

  async fetchStores() {
    const { ShoppingActions } = this.props;

    this.setState({ isFetchingStores: true });
    {console.log('[About To Fetch Stores at Landing Main]')};
    try {
      const fetchStoresResult = await ShoppingActions.fetchStores();
      //AuthenticationActions.setCsrf(fetchStoresResult.data.csrf_token);
      const stores = fetchStoresResult.data.retail_stores.map((store) => {
        let rate, commission;

        if (store.commission_rate) {
          if (!store.commission_rate.length) {
            rate = "";
          } else {
            if (store.commission_description.indexOf('$') > -1) {
              commission = '$' + (parseFloat(store.commission_rate) / 2).toFixed(2);
              /*  + store.commission_description.slice(1); */
            } else {
              commission = (parseFloat(store.commission_rate) / 2).toFixed(1)
              + "%";
              /* store.commission_description; */
            }
            rate = commission;
          }
        } else {
          rate = "";
        }
        store.rate = rate;

        if (store.retail_store_banner_url == null) {
          store.retail_store_banner_url = `${CONFIG.assetsUrl}/assets/merchants_logos/Drawer-600x150-Default.png`;
        }
        store.retail_store_logo_url = store.custom_logo_directory ? (store.custom_logo_directory.length && store.custom_logo_directory !== 'null' ? CONFIG.assetsUrl + '/assets/' + store.custom_logo_directory : store.avatar_url) : store.avatar_url;
        store.image_alt_text = store.merchant_name;
        store.image_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.more_info_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.deals_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.shop_now_link_url = `${CONFIG.assetsUrl}/transaction/${store.retail_store_web_name}/${store.retail_store_id}`;
        store.deals_count = store.coupons_count;
        if (store.tags) {
          store.tags = JSON.parse(store.tags);
        } else {
          store.tags = [];
        }
        //store.favorite = null;
        return store;
      });
      console.log(['(LandingMain)stores from fetchStores before adding to state: ', stores]);
      ShoppingActions.addStores(stores);
      this.setState({ hasFetchedStores: true });
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Error Fetching Stores - Landing Main",
        info: exception, 
        ...exception,
      });
    } finally {
      this.setState({ isFetchingStores: false });
    }
  }


  async fetchFavoriteShops() {
    const { ShoppingActions } = this.props;

    this.setState({ isFetchingFavoriteShops: true });
    {console.log('[About To Fetch Favorite Shops at Landing Main]')};
    
    try {
      const fetchFavoriteShopsResult = await ShoppingActions.fetchFavoriteShops();
      
      const favorites = fetchFavoriteShopsResult.data.favorites.map(favorite => favorite.retail_store_id)
      //const favorites = FavoriteActions.fetchFavoriteShops(fetchFavoriteShopsResult.map(favorite => favorite.retail_store_id));
      console.log(['about to set favorites on stores from Landing Main. The following are true: ', favorites]);
      console.log(ShoppingActions);

      ShoppingActions.setFavoritesOnStores(favorites);
      ShoppingActions.addFavoriteShops();
      //console.log(stores);
    } catch (exception) {   
      console.log(exception);
    } finally {
      this.setState({ isFetchingFavoriteShops: false });
      this.setState({ hasFetchedFavoriteShops: true });
      //console.log("check after adding faves and this.props.app is : ", this.props.app);
    }
  }


  async fetchDeals() {
    const { ShoppingActions } = this.props;

    this.setState({ isFetchingDeals: true });
    try {
      const fetchDealsResult = await ShoppingActions.fetchDeals();
      const deals = fetchDealsResult.data.coupons.map((coupon) => {
        let promotionTypeTitle, rate, commission;
        if (coupon.code) {
          coupon.code.length 
            ? promotionTypeTitle = 'Coupon' 
            : promotionTypeTitle = 'Promotion';
        } else {
          promotionTypeTitle = 'Promotion';
        }
        coupon.promotionTypeTitle = promotionTypeTitle;

        let expiry;
        let visibleExpiry;
        let endDateObject = new Date(coupon.end_date);

        if (endDateObject.toString() !== 'Invalid Date') {
          if (moment(coupon.end_date).diff(moment(), 'hours') < 48) {
            if (moment(coupon.end_date).diff(moment(), 'minutes') < 60) {
              visibleExpiry = moment(coupon.end_date).fromNow(true);
            } else {
              visibleExpiry = moment(coupon.end_date).diff(moment(), 'hours') + ' hours';
            }
          } else {
            visibleExpiry = moment(coupon.end_date).fromNow(true);
          }
        }

        endDateObject.toString() === 'Invalid Date'
          ? (expiry = 
          moment(coupon.start_date).fromNow(true) +
            ' old &nbsp;&nbsp;&nbsp;' +
            '<span className="coupon-expiry">NO EXPIRY</span>')
          : (expiry = moment(coupon.start_date).fromNow(true) +
          ' old &nbsp;&nbsp;&nbsp; ' +
          '<span className="coupon-expiry"><i className="fa fa-clock-o"></i>&nbsp;'
          + visibleExpiry +
          '</span>');
        coupon.expiry = expiry;

        if (endDateObject.toString() === 'Invalid Date') {
          coupon.end_date_for_sorting = moment()
            .add(1001, 'years')
            .format('YYYY-MM-DD HH:mm:ss');
        } else {
          coupon.end_date_for_sorting = moment(coupon.end_date).format(
            'YYYY-MM-DD HH:mm:ss'
            );
        }

        if (coupon.commission_rate) {
          if (!coupon.commission_rate.length) {
            rate = "";
          } else {
            if (coupon.commission_description.indexOf("$") > -1) {
              commission =
                "$" +
                (parseFloat(coupon.commission_rate) / 2).toFixed(2) +
                coupon.commission_description.slice(1);
            } else {
              commission =
                (parseFloat(coupon.commission_rate) / 2).toFixed(1) +
                coupon.commission_description;
            }
            rate = commission;
          }
        } else {
          rate = "";
          log({
            event: "System-Error-NoRate-On-Deal",
            shopper_id: authentication.user.shopper_id,
            retail_store_id: store.retail_store_id,
            activeTab,
            ...store,
          });
        }
        coupon.rate = rate;

        coupon.clipboardCode = coupon.code;
        coupon.retail_store_logo_url = coupon.custom_logo_directory 
          ? (coupon.custom_logo_directory.length &&
            coupon.custom_logo_directory !== 'null'
            ? CONFIG.assetsUrl +
            '/assets/' +
            coupon.custom_logo_directory
            : coupon.avatar_url) 
          : coupon.avatar_url;
        coupon.retail_store_logo_url_alt_text = coupon.merchant_name;
        coupon.coupon_banner_image_url = coupon.coupon_banner_url;
        coupon.shopNowLink = 
          CONFIG.assetsUrl +
          '/transaction/' +
          coupon.retail_store_web_name +
          '/' +
          coupon.id +
          '/coupon/browser/';
        coupon.shareLink = 
          CONFIG.assetsUrl + '/shop/deal/' + coupon.coupon_id;
        return coupon;
      });
      ShoppingActions.addDeals(deals);

      const promotedDeals = deals.filter((deal) => deal.currently_promoted === '1');
      ShoppingActions.addPromotedDeals(promotedDeals);

      if (fetchDealsResult.data.promotions.length) {
        const { promotions } = fetchDealsResult.data;
        ShoppingActions.setCurrentPromotion(promotions[0]);
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Error Fetching Deals - Landing Main",
        info: exception, 
        ...exception,
      });

    } finally {
      this.setState({ isFetchingDeals: false });
    }

  }

  async checkExtensionInstallationStatus() {
    const { AppActions } = this.props;
    console.log('[CHECKING EXTENSION INSTALLATION]');
    const img = new Image();
    img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

    img.onload = () => {
      console.log('[EXTENSION IS INSTALLED]');
      AppActions.setIsExtensionInstalled(true);
      /* this.setState({ isExtensionInstalled: true}); */
    };
  }

  render() {
    const { isExtensionInstalled, storesList, landingBannerOption } = this.state;
    const { user, shopping } = this.props;
    const { landingPageContent } = this.props.app;

        if (!landingPageContent) {
      return null;
    }

    return (
      <LandingWrapper>
        {/* {
          moment() < moment('2019-11-29 23:59:59') ? (
            <BlackFridayBanner />
          ) : user ? (
            <GetExtensionStrip />
          ) : null
        } */}
        { (isChrome && !isMobile && !isExtensionInstalled &&<GetExtensionStrip />) ? (
            <GetExtensionStrip />
          ) : user ? (
            <ShareStrip />   
          ) : null
        
        }
        {/* <OnboardingJourney /> */}
        <LandingActionsBanner />
        {/*
        <LandingBanner />
        */}
        {/* <HowItWorksTestimonials /> */}
{/*         <ShopsCategories
          promotedTags={[
            { title: 'Buy Food & Groceries', tag: 'food groceries', image: 'bys-food-groceries.jpg', headerColor: '#fafafa', backgroundPosition: 'center' },
            { title: 'Buy Health & Beauty', tag: 'health beauty', image: 'bys-health-beauty.jpg', headerColor: '#212121', backgroundPosition: 'center' },
            { title: 'Buy Clothes & Fashion', tag: 'clothes fashion', image: 'bys-fashion.jpg', headerColor: '#fff', backgroundPosition: 'center' },
            { title: 'Book your Trip', tag: 'travel', image: 'bys-travel.jpg', headerColor: '#fafafa', backgroundPosition: 'center' },
            { title: 'Buy Insurance', tag: 'insurance', image: 'bys-insurance.jpg', headerColor: '#fff', backgroundPosition: 'bottom' },
            { title: 'Buy for Babies', tag: 'baby', image: 'bys-baby.jpg', headerColor: '#fff', backgroundPosition: 'top' },
          ]}
          storesList={storesList}
        /> */}
        {/* <ShopsStrip /> */}
        {/* <FAQ /> */}
        <Footer />
      </LandingWrapper>
    );
  }
}
