export default ({ theme }) => {
  return `
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #d9d9d9;
  border-top: solid 1px #d9d9d9;
  width: 100%;
  // box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);

  .stepper {
    width: calc(100% - 40px);
    padding: 20px;
    margin: 20px 0 0;
    border-bottom: dashed 1px #d9d9d9;

    &.vertical {
      .step {
        .step-label {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;

          span {
            &:last-child {
              span {
                margin: 0 10px;
              }
            }
          }
        }

        &.active {
          width: 100%;
          .step-label {
            span {
              &:last-child {
                display: flex;

                span {
                  margin: 0 10px;
                }
              }
            }
          }

          .step-content {
            display: flex;
            width: calc(100% - 30px);
            overflow: auto;
            // margin: 10px 0px 5px 7px;
            // padding: 10px;
            margin: 10px 0 10px 10px;
            padding: 10px;

            >div {
              width: 100%;
            }
          }
        }
      }
    }

    .step {
      &.active {
        svg {
          color: ${theme.activeNotificationColor};
        }
      }

      .step-label {
        // display: flex;
        // flex-direction: row;
      }

      .step-content {
        display: flex;
        width: calc(100% - 30px);
        overflow: auto;
        // margin: 10px 0px 5px 7px;
        // padding: 10px;
        margin: 10px 0 10px 10px;
        padding: 10px;
      }
    }
  }

  .active-step {
    width: calc(100% - 20px);
    margin: 10px;
    min-height:462px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media(max-width: 768px) {
      min-height: unset !important;
      }

  }

  .stepper-control {
    align-items: center;
    width: calc(100% - 20px);
    overflow: auto;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;

    .button-group {
      display: flex;
    }
  }
  
  h2 {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .description {
    max-width: 700px;
  }
`
};
