import React from 'react';
import styled from 'styled-components';

import styles from './CampaignAddFavStores.css';

const CampaignAddFavStoresWrapper = styled.div`${styles}`;

const CampaignAddFavStores = () => {
  return (
    <CampaignAddFavStoresWrapper>
      <p className="description">This month add at least 3 shops to your faves list and we will put you on FavShopsBonusBoost5%</p>
      <div className="image-container">
        <i className="material-icons" style={{ color: '#ff4569' }}>favorite</i>
      </div>
      <p className="description">It's easy. Simply head over to the shops and click the Fav Icon found on your fav shops. </p>
    </CampaignAddFavStoresWrapper>
  );
}

export default CampaignAddFavStores;