import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActionButton from 'SharedComponents/action-button';
import CONFIG from 'Config';

import styles from './GetExtension.css';

const GetExtensionWrapper = styled.div`${styles}`;

let isChromeExtensionInstalledInterval;

const GetExtension = ({ onNext }) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    checkExtensionInstallationStatus();
    return () => {
      window.clearInterval(isChromeExtensionInstalledInterval);
    }
  })

  function checkExtensionInstallationStatus() {
    console.log('[CHECKING EXTENSION INSTALLATION]');
    const img = new Image();
    img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

    img.onload = () => {
      setIsExtensionInstalled(true);
    };
  }

  function onLearnHowItWorks() {
    console.log('[onLearnHowItWorks]', `${window.location.host}/notifier/testrun`);
    const howItWorksWindow = window.open(`${window.location.host}/notifier/testrun`, '_blank');
    howItWorksWindow.focus();
  }

  function onInstallChromeExtension() {
    const webstoreWindow = window.open(`https://chrome.google.com/webstore/detail/boost-your-super/${CONFIG.chromeExtensionId}`, '_blank');
    webstoreWindow.focus();

    isChromeExtensionInstalledInterval = window.setInterval(() => {
      console.log('[RUNNING INTERVAL]');
	    const img = new Image();
			img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

			img.onload = () => {
        // // redirect to test run page
				// if (window.location.href.indexOf('notifier/testrun') === -1) {
				// 	// redirect to test run page if not already there
				// 	webstoreWindow.location = `${window.location.host}/notifier/testrun`;
        //   webstoreWindow.focus();
				// }
        checkExtensionInstallationStatus();
	      window.clearInterval(isChromeExtensionInstalledInterval);
	    };

	    img.onerror = () => {
	      console.log('[EXTENSION NOT INSTALLED]');
	    };
	  }, 1000);
  }

  return (
    <GetExtensionWrapper>
      <p>Notifies you when you visit participating stores so you don't miss free Super. Users who install it earn much more.</p>  
      {
        !isExtensionInstalled ? (
          <div className="not-installed">
            <ActionButton text="Install&nbsp;Extension" icon="extension" onClick={onInstallChromeExtension} large />
           </div> 
        ) : (
          <div className="installed">
            <ActionButton text="Extension&nbsp;Installed" icon="check_circle" style={{ color: '#47E6B1' }} large />
            {/* <p>Chrome Extension installed Successfully.</p>
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i> */}
            {/*
            <ActionButton text="Learn&nbsp;How&nbsp;It&nbsp;Works" onClick={onLearnHowItWorks} />
            */}
          </div>
        )
      }

    </GetExtensionWrapper>
  );
}

export default GetExtension;
