import React from 'react';
import styled from 'styled-components';

import styles from './HacksForYourSuper.css';

const HacksForYourSuperWrapper = styled.div`${styles}`;

const HacksForYourSuper = () => {
  return (
    <HacksForYourSuperWrapper>
      <p className="description">In our Hacks for Your Super series emails we interview superannuation experts with questions from our members. We make sure their answers are easy to understand and action.</p>
      <p className="description">Be sure to stay subscribed to our email so you receive Tips and Tricks on how you can retire with more super. </p>
    </HacksForYourSuperWrapper>
  );
}

export default HacksForYourSuper;