import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
//import Spinner from 'react-spinner-material';
import { BarLoader } from 'react-spinners';
//import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';

import ActionButton from 'SharedComponents/action-button';
import ModalDialogV3 from 'SharedComponents/modal-dialog-v3';
//import FormLinkBankAccount from 'SharedComponents/form-link-bank-account';
import RoundUpsAcctsTable from 'SharedComponents/table-roundups-accounts';


import basiqLogo from 'Images/logo-400px-BASIQ.png';
import pokitPalLogo from 'Images/logo-400px-PokitPal.png';
import privacyImage from 'Images/privacy1-400px.png';
import BYSlogo from 'Images/logo-400px-BoostYourSuper.png'; 
import consumerDataRightLogo from 'Images/logo-400px-ConsumerDataRight.png'; //working?

import log from "Utils/logger";

import styles from './SetBankAccounts.css';

const SetBankAccountsWrapper = styled.div`${styles}`;

const SetBankAccounts = (
  { //authentication,
    //app,
    onError, 
    roundups,
    //RoundUpsActions,
    registeringNewShopperForRoundups,
    requestConsentLink,
    requestingConsentLinkRef,
    localActiveBankConsentLink,
    //hasFetchedRoundUpsShopperData,
    syncShopperDataFromOpenBank,
    deleteBankConnection,
   }) => {
  
  console.log('*SetBankAccounts triggered with roundups: ', roundups);
  console.log('*SetBankAccounts triggered with localActiveBankConsentLink: ', localActiveBankConsentLink);  
  console.log('*SetBankAccounts triggered with requestingConsentLinkRef.current: ', requestingConsentLinkRef.current);
  console.log('*SetBankAccounts triggered with registeringNewShopperForRoundups: ', registeringNewShopperForRoundups);


  //const dispatch = useDispatch();
  //const authentication = useSelector(state => state.authentication);
  //const roundups = useSelector(state => state.roundups);

  const [showModalDialogV3, setShowModalDialogV3] = useState(false);

  //const [obtainingUserOpenBankConsent, setObtainingUserOpenBankConsent] = useState(false);
  //const [fetchingRoundUpsLinkedAccounts, setfetchingRoundUpsLinkedAccounts] = useState(false);
  //const [hasFetchedRoundUpsLinkedAccounts, setHasFetchedRoundUpsLinkedAccounts] = useState(false);
  


const connectYourBankInfoCard = (
  <div className="bank-connection-card">
    <h2 className="title">Connect Your Bank</h2>
    <p className="description">Connect your bank so we can calculate round up amounts and transfer them to your super.</p>

    <div className="button-container">
      <ActionButton
        text="Connect A Bank"
        onClick={() =>  handleConnectAccountClick() }
        color="#fff"
        large={true}
        disabled={((roundups.accountsConnectingStatus !== null) && !roundups.consentLinkStatus) }
        //disabled={((roundups.accountsConnectingStatus !== null) || !localActiveBankConsentLink) }
        //use the above line once connecting status is fixed.
        //cross check roundups.consentLinkStatus which is currently false. Not sure why. Could be consentlink error issues.
        //disabled={false}
      />
      { !localActiveBankConsentLink ?  
        (
          <div>
            <p> Please wait. Setting up link for BASIQ Open Banking connection</p>
            <BarLoader
              sizeUnit={"px"}
              size={400}
              color="#000"
              loading={true}
            />
          </div>
          )
          : <p>You will be taken to the BASIQ Open Banking portal to link your bank account.</p> 
          }

      
{/*       <ActionButton
        text="Sync Account Data (test button only)"
        onClick={() =>  handleSyncAccountClick() }
        color="#fff"
        large={true}
      /> */}
    </div> 
  </div> 
);

const howWeConnectYourBankInfoCard = (
  <div className="bank-connection-card">
    <h2 className="title">How We Connect Your Bank</h2>
    <p className="description">We have partnered with experts in Open Banking and Round Ups.</p>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={basiqLogo} alt="Basiq" />
      </div>
      <div className="description-container">
        <p><strong>Open Banking. </strong>Our Open Banking solution is powered by BASIQ, leaders in Secure Open Banking in Australia.</p>
      </div>
    </div>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={pokitPalLogo} alt="Roundups Calculating company" />
      </div>
      <div className="description-container">
        <p><strong>Round Ups. </strong>PokitPal are specialists at calculating round up amounts based on your spending.</p>
      </div>
    </div>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={privacyImage} alt="Privacy" />
      </div>
      <div className="description-container">
        <p><strong>Privacy. </strong>We only extract Round Ups transactions from your accounts. No bank transactional data is stored on any of our systems. </p>
      </div>
    </div>
  </div>
);

const aboutOpenBankingInfoCard = (
  <div className="bank-connection-card">
    <h2 className="title">About Open Banking</h2>
    <p className="description">Open Banking gives consumers the right to direct their banks to safely share banking data with service providers who are Consumer Data Recepients in order to access new services such as Round Ups.</p>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={consumerDataRightLogo} alt="Consumer Data Right" />
      </div>
      <div className="description-container">
        <p><strong>Open Banking Regulation. </strong>Open Banking is mandated and regulated by the Australian Government through the Consumer Data Right (CDR)</p>
        <p><strong>Accreditation </strong>Australian Consumer and Competition Commission (ACCC) accredits Consumer Data RIght Recipients.</p>
      </div>
    </div>
    {/* <p className="description"><strong>Boost Your Super App</strong> is accredited by the ACCC via Pokitpal Pty Ltd as a Sponsored Affiliate. Pokitpal is an Accredited Data Recipient.</p> */}
    <p className="description">Consumer Data Right follows strict regulations to protect your data and privacy.</p>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={BYSlogo} alt="Roundups Calculating company" />
      </div>
      <div className="description-container">
        <p><strong>Accreditation. </strong>Boost Your Super App is accredited by the ACCC via Pokitpal Pty Ltd as a Sponsored Affiliate. Pokitpal is an Accredited Data Recipient.</p>
      </div>
    </div>
    <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={basiqLogo} alt="Basiq" />
      </div>
      <div className="description-container">
        <p><strong>Open Banking. </strong>Our Open Banking solution is powered by BASIQ, leaders in Secure Open Banking in Australia.</p>
      </div>
    </div>
    <p className="description">You can find out more on the CDR website.</p>
        <ActionButton 
          style={{margin: "10px"}}
          text={"More on CDR Website"}
          large={true}
          onClick={() => window.open(`https://cdr.gov.au/your-rights`, '_blank')}
        />
{/*     <div className="logo-and-description-container">
      <div className="logo-container">
        <img className="logo" src={privacyImage} alt="Privacy" />
      </div>
      <div className="description-container">
        <p><strong>Privacy. </strong>We only extract Round Ups transactions from your accounts. No bank transactional data is stored on any of our systems. </p>
      </div>
    </div> */}
  </div>
);

useEffect(() => {

    if (
           !localActiveBankConsentLink
        && !requestingConsentLinkRef.current
        && !registeringNewShopperForRoundups
      ) {
          console.log('**UseEffect SetBankAccounts did not find an activeBankConsentLink that is less than 1 hour old. Lets request a new one with roundups: ', roundups);
          console.log ('**UseEffect SetBankAccounts localActiveBankConsentLink: ', localActiveBankConsentLink); 
          log({
            event: "System-Notification",
            source: "SetBankAccounts",
            notification: `request consent link with localActiveBankConsentLink: ${localActiveBankConsentLink}`,
            error: null,
          });
          log({
            event: "System-Notification",
            source: "SetBankAccounts",
            notification: `request consent link with requestingConsentLink.current: ${requestingConsentLinkRef.current}`,
            error: null,
          });
          log({
            event: "System-Notification",
            source: "SetBankAccounts",
            notification: `request consent link with registeringNewShopperForRoundups: ${registeringNewShopperForRoundups}`,
            error: null,
          });

          requestConsentLink();
    }
}, [roundups.bankConsented, localActiveBankConsentLink, requestingConsentLinkRef, registeringNewShopperForRoundups, roundups.accountsConnectingStatus ]);

  const handleConnectAccountClick = () => {
    console.log('handleConnectAccountClick triggered with roundups: ', roundups);
    const createdAtMoment = localActiveBankConsentLink ? moment(localActiveBankConsentLink.created_at, 'YYYY-MM-DD HH:mm:ss') : null;
    log({
      event: "System-Notification",
      source: "SetBankAccounts",
      notification: `handleConnectAccountClick triggered with localActiveBankConsentLink createdAtMoment: ${createdAtMoment}`,
      error: null,
    });

    if (localActiveBankConsentLink && createdAtMoment.isAfter(moment().subtract(1, 'hour'))) {
      console.log("handleConnectAccountClick triggered with link < 1hr open link");
      window.open(localActiveBankConsentLink.url, 'width=500,height=500' ); // '_blank');
      log({
        event: "System-Notification",
        source: "SetBankAccounts",
        notification: "handleConnectAccountClick triggered with localActiveBankConsentLink < 1hr (fresh) open link in new window",
        error: null,
      });

    } else {
      console.log('handleConnectAccountClick triggered to requestConsentLink with roundups: ', roundups);
      log({
        event: "System-Notification",
        source: "SetBankAccounts",
        notification: "handleConnectAccountClick triggered with localActiveBankConsentLink > 1hr (old) so request new link",
        error: null,
      });
      requestConsentLink();
    };
  };

  const handleSyncAccountClick = () => {
    console.log('handleSyncAccountClick triggered with roundups: ', roundups);
    syncShopperDataFromOpenBank();
  };

  const handleDeleteBankClick = () => {
    console.log('handleSyncAccountClick triggered with roundups: ', roundups);
    deleteBankConnection();
  };

  
  return (
    <SetBankAccountsWrapper>
    <div className="content-container">
      {
           registeringNewShopperForRoundups ?
        (
          <div className="waiting">
            <h1>Setting up your Round Ups account</h1>
            {/* <p>{merchantName} will {rate}</p> */}
            <p>Please wait while we set up your Round Ups account</p>
            <p>1. Registering Account for Round Ups</p>
            <p>2. Creating user profile in Open Banking</p>
            <BarLoader
              sizeUnit={"px"}
              size={400}
              color="#000"
              loading={true}
            />

            {/* <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
            <span style={{ margin: '10px' }}>Setting up your Boost Your Super Round Ups Account</span> */}
          
          </div>
        ) : (
          <div className="bank-accounts-container">
            {(roundups.linkedAccounts && roundups.linkedAccounts.length > 0) ? 
              null : (
                <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
                  <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      {connectYourBankInfoCard}
                    </Col>
{/*                     <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      {howWeConnectYourBankInfoCard}
                    </Col> */}
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      {aboutOpenBankingInfoCard}
                    </Col>
                  </Row>
                </Container>
              )}
            <h2 className="title">Your Linked Accounts</h2>
            {
              roundups.accountsConnectingStatus === 'deleting' ?
                ( <div className="waiting">
                    <p>We are deleting your approval and removing any accounts connected.</p>
                    <p>This process normally takes 3-5 minutes. You will only be able to connect another bank after the process is complete.</p>
                    <BarLoader 
                      label='Loading...'
                      height={4} 
                      width={50} 
                      loading={true}
                    />
                  </div>
                )
              : roundups.accountsConnectingStatus === 'retrieving' ?
                (
                  <div className="waiting">
                    <p>We have successfully connected your bank.</p>
                    <p>We are now retrieving your accounts. It could take up to a  minute. You may leave this page and return later.</p>
                    <p>Once your accounts are retrieved you will need to <strong>enable Round Ups on the account</strong>.</p>
                    <BarLoader 
                      label='Loading...'
                      height={4} 
                      width={50}
                      size={200} 
                      sizeUnit={"px"} 
                      loading={true}
                    />
                  </div>
                )

            : (roundups.linkedAccounts && roundups.linkedAccounts.length > 0) ? 
                (
                  <div>
                    <RoundUpsAcctsTable
                      onError={onError}
                    />
                    <div className="button-container">
                      <ActionButton
                        text="Connect Another Bank"
                        onClick={() =>  handleConnectAccountClick() }
                        color="#fff"
                        large={true}
                        disabled={((roundups.accountsConnectingStatus !== null) && !roundups.consentLinkStatus) }
                        //use the above line once connecting status is fixed.
                        //cross check roundups.consentLinkStatus which is currently false. Not sure why. Could be consentlink error issues.
                        //disabled={false}
                      />
                      { !localActiveBankConsentLink ?  
                        (
                          <div>
                            <p> Please wait a moment. Setting up link for BASIQ Open Banking connection</p>
                            <BarLoader
                              sizeUnit={"px"}
                              size={400}
                              color="#000"
                              loading={true}
                            />
                          </div>
                          )
                          : <p>You will be taken to the BASIQ Open Banking portal to link your bank account.</p> 
                      }
                    </div>
                    {/* <ActionButton
                      text="Sync Account Data (test button only)"
                      onClick={() =>  handleSyncAccountClick() }
                      color="#fff"
                      large={true}
                    /> */}
                    <ActionButton
                      text="Delete All Connected Banks"
                      onClick={() =>  setShowModalDialogV3(true) }
                      color="#fff"
                      large={false}
                      disabled={roundups.consentLinkStatus ? false : true}
                    />
                    <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
                      <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                          {aboutOpenBankingInfoCard}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )
            : ( 
                <div className="waiting">
                  <p>You have no linked Accounts</p>
                </div>
                )

            }
          </div>
          
        )
  }
    </div>
    <div>
        <ModalDialogV3 //remove MOdalDialog in final code - no need modal for deleting accts
          open={showModalDialogV3}
          onCloseModalDialogV3={() => setShowModalDialogV3(false)}
          titleComponent={"Are You Sure?"}
          errorMessage={"All connected bank accounts will be removed from your account."}
          explainerComponent={
            <p>You will have to add them back each individually to connect them again to our Round Ups service.</p>
          }
          contentComponent={
            <ActionButton
              text="Yes - Delete All Connected Banks"
              onClick={() =>  handleDeleteBankClick() }
              color="#fff"
              large={false}
              disabled={roundups.consentLinkStatus ? false : true}
            />
          }
        /> 
    </div>
    </SetBankAccountsWrapper>
  );
}

export default SetBankAccounts;