import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import SimplePagination from 'SharedComponents/simple-pagination';
import Tabs from 'SharedComponents/tabs';
import log from "Utils/logger";
import { Link } from 'react-router-dom';

import PopoverHover from 'SharedComponents/popover-hover';
import TransactionsTable from './TransactionsTable';
import TransactionsTableRoundups from './TransactionsTableRoundups';
import TransactionsTableContributeNow from './TransactionsTableContributeNow';
import DonationsStrip from './DonationsStrip';
import StatCard from 'SharedComponents/stat-card';
import ActionButton from 'SharedComponents/action-button';
import Table from 'SharedComponents/table';

import useFetchRoundUpsTransactions from 'SharedHooks/fetch-round-ups-transactions';
import useFetchCashbackTransactions from 'SharedHooks/fetch-cashback-transactions';
import useFetchCashbackSummary from 'SharedHooks/fetch-cashback-summary';
import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';

import styles from './index.css.js';

numeral.defaultFormat('0,0.00');

// set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');

const Container = styled.div`${styles}`;

const Purchases = () => {
  const [showRaiseInquiryDialog, setShowRaiseInquiryDialog] = useState(false);
  const [activeTab, setActiveTab] = useState('cashback-transactions');

  const [localRoundUpsTransactions, setLocalRoundUpsTransactions] = useState([]);
  const [localContributeNowTransactions, setLocalContributeNowTransactions] = useState([]);
  const [localCashbackTransactions, setLocalCashbackTransactions] = useState([]);
  const [localCashbackSummary, setLocalCashbackSummary] = useState({});

  const [localContributeNowTotalClearing, setLocalContributeNowTotalClearing] = useState(0);
  const [localContributeNowTotalCleared, setLocalContributeNowTotalCleared] = useState(0);
  const [localContributeNowTotalReceived, setLocalContributeNowTotalReceived] = useState(0);
  const [localContributeNowTotalPaid, setLocalContributeNowTotalPaid] = useState(0);

  const [localRoundUpsTotalClearing, setLocalRoundUpsTotalClearing] = useState(0);
  const [localRoundUpsTotalCleared, setLocalRoundUpsTotalCleared] = useState(0);
  const [localRoundUpsTotalReceived, setLocalRoundUpsTotalReceived] = useState(0);
  const [localRoundUpsTotalPaid, setLocalRoundUpsTotalPaid] = useState(0);

  
  const { fetchRoundUpsTransactions } = useFetchRoundUpsTransactions();
  const { isFetchingCashbackTransactions, fetchCashbackTransactions } = useFetchCashbackTransactions();
  const { fetchCashbackSummary } = useFetchCashbackSummary();
  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();

  const cashbackTransactions = useSelector(state => state.transactions.items);
  const cashbackSummary = useSelector(state => state.transactions.cashBackSummary);
  //const roundUpsTransactions = useSelector(state => state.roundUpsTransactions.roundUpsTransactions); 
  const roundUpsTransactions = useSelector(state => state.roundUpsTransactions);

  const authentication = useSelector(state => state.authentication);
  //const contributeNowTransactions = useSelector(state => state.roundUpsTransactions.contributeNowTransactions);


  const dispatch = useDispatch();

  console.log('Purchases triggered');
  //console.log('Purchases mounted with roundUps: ', roundUps);
  //console.log('Mounting status of isFetchingRoundUpsTransactions: ', isFetchingRoundUpsTransactions)

  useEffect (() => {
    console.log('Purchases - Mounted');
    return () => {
      console.log('Purchases - Unmounted');
    }

  }, []);

  useEffect(() => {
    setLocalContributeNowTotalClearing(roundUpsTransactions.contributeNowTotalClearing);
    setLocalContributeNowTotalCleared(roundUpsTransactions.contributeNowTotalCleared);
    setLocalContributeNowTotalReceived(roundUpsTransactions.contributeNowTotalReceived);
    setLocalContributeNowTotalPaid(roundUpsTransactions.contributeNowTotalPaid);
  }, [roundUpsTransactions.contributeNowTotalPaid]);

  useEffect(() => {
    setLocalRoundUpsTotalClearing(roundUpsTransactions.roundUpsTotalClearing);
    setLocalRoundUpsTotalCleared(roundUpsTransactions.roundUpsTotalCleared);
    setLocalRoundUpsTotalReceived(roundUpsTransactions.roundUpsTotalReceived);
    setLocalRoundUpsTotalPaid(roundUpsTransactions.roundUpsTotalPaid);
  }, [roundUpsTransactions.roundUpsTotalPaid]);


  useEffect(() => {
    console.log('Purchases - useEffect - update localRoundUpsTransactions with : ', roundUpsTransactions.roundUpsTransactions);
    setLocalRoundUpsTransactions(roundUpsTransactions.roundUpsTransactions);

  }, [roundUpsTransactions.roundUpsTransactions]);

  useEffect(() => {
    console.log('Purchases - useEffect - update localContributeNowTransactions, with : ', roundUpsTransactions.contributeNowTransactions); 
    setLocalContributeNowTransactions(roundUpsTransactions.contributeNowTransactions);
  }, [roundUpsTransactions.contributeNowTransactions]);

  useEffect(() => {
    console.log('Purchases - useEffect - update localCashbackTransactions with : ', cashbackTransactions);
    setLocalCashbackTransactions(cashbackTransactions);
  }, [cashbackTransactions]);

  useEffect(() => {
    console.log('Purchases - useEffect - update localCashbackSummary with : ', cashbackSummary);
    setLocalCashbackSummary(cashbackSummary);
  }, [cashbackSummary]);


//setting up for passing as a prop to a base table. Not currently active.
  const ExpandedContributeNowComponent = ({data}) => {
    (
      <div className="expander-row-container">
        <div className="transaction-details">
          <div className="transaction-item"> Date Time: <span style={{fontWeight:"bold"}}>&nbsp;{moment(data.paymentData.created_at).local().format('DD MMM YYYY, h:mm:ss a')}</span></div>
          <div className="transaction-item"> Bank Reference: <span style={{fontWeight:"bold"}}>&nbsp;{data.party_bank_ref}</span></div>
          <div className="transaction-item"> Boost To Super: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.paymentData.payout.amount/100).format()}</span></div>
          <div className="transaction-item"> Status: 
            <span style={{fontWeight:"bold"}}>&nbsp;{ data.status}</span>
            {/* <span style={{fontWeight:"bold"}}>&nbsp;{TransactionStatus (data.status,data.paid_charity)}</span> */}
              <span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    status currently being updated...
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
                  horizontalPosn = "right"
                />
              </span>
          </div>
          <div className="transaction-item"> Description: <span style={{fontWeight:"bold"}}>&nbsp;{data.description}</span></div>
        </div>
      </div>
     );
  }

  const onCloseRaiseInquiryDialog = () => {
    setShowRaiseInquiryDialog(false);
  }


  const KeyTransactionStatusDescriptionsMap = {
    'Manually Approved': 'This transaction has been approved manually. Manual Approval is usually the result of an inquiry.',
    'Inquiry Raised': 'An inquiry has been raised and is being looked into with the shop. Please note, some shops can take a few months to resolve. We continually check up on all inquiries until they are resolved.',
    'Payment Bounced': 'Payment to your super account was rejected. This is normally due to incorrect or missing payment details. Please go to your account section and update your payment details.',
    'Invalid Pay Details': 'Payment to your super account was rejected. This is normally due to incorrect or missing payment details. Please go to your account section and update your payment details.',
    Clicked: 'Click has been registered and will track transactions you make within 24 hrs.',
    Pending: 'The transaction is pending approval from the merchant. In most instances shops will wait 30-45 days before approving to ensure order was not cancelled.',
    Paid: 'Amount has been paid to your super account where it can grOW.',
    Void: 'Shop has rejected transaction. This is usually due to order being cancelled.',
    Approved: 'Amount has been approved but not yet paid to your super account. Once a store approves they will pay us in thier next payment cycle which varies from shop to shop. Normally 30-45 days but with some shops this can be as long as 6 months.'
  };

  return (
    <Container>
      <div className="content">
        <div className="stats-strip">
          <div className="stats-container">
          <h2>Cashback Totals</h2>
            <StatCard
              //fetching={isFetchingRoundUpsTransactions}
              label="Pending Approval:"
              value={!cashbackSummary ? null : cashbackSummary.total_waiting_payment_donation_amount} //cashbackSummary.total_pending_amount}
              valueTypePercent={false}
              moreInfo="These amounts are waiting approval from the shop. Each shop has their own approval time but in general shops wait 30-45 days to ensure goods are not returned to store before they will approve."
              majorStat={false}
            />
            <StatCard
              //fetching={isFetchingRoundUpsTransactions}
              label="Approved:"
              value={!cashbackSummary ? null : cashbackSummary.total_pending_amount} 
              valueTypePercent={false}
              moreInfo="These amounts have been approved to be paid by the shops but payment has not yet been made to your super."
              majorStat={false}
            />
            <StatCard
              //fetching={isFetchingRoundUpsTransactions}
              label="Paid:"
              value={!cashbackSummary ? null : cashbackSummary.total_paid_amount} 
              valueTypePercent={false}
              moreInfo="This amount has been paid to your super account where it can grOW."
              majorStat={false}
            />
            <StatCard
              //fetching={isFetchingRoundUpsTransactions}
              label="Total Boost:"
              value={!cashbackSummary ? null : cashbackSummary.total_commission
              } 
              valueTypePercent={false}
              moreInfo="Total of all amounts Pending Approval, Approved and Paid."
              majorStat={true}
            />
          </div>
          { !authentication.user.roundups_visible ?
            null : (
              <div className="stats-container">
                <h2>Round Ups Totals</h2>
                { ((roundUpsTransactions.roundUpsTotalPaid + roundUpsTransactions.roundUpsTotalClearing + roundUpsTransactions.roundUpsTotalCleared) === 0) ? (
                  <div className="notification-action" >
                    <p> Complete setting up <Link className="link" to="/settings?tab=round-ups">Round Ups</Link> to Boost more.</p>
                    <p> The average user Rounds Up $69.71 per month. See how it can impact super on our Super Calculator.</p>
                    <Link className="link" to="/superannuation-calculators">
                      <ActionButton 
                        text="See Impact on Super Calculator" 
                        large={true}
                      />
                    </Link>
                  </div>
                )  : ( 
                <div>
                    <StatCard
                      //fetching={isFetchingRoundUpsTransactions}
                      label="Clearing:"
                      value={roundUpsTransactions.roundUpsTotalClearing / 100}
                      valueTypePercent={false}
                      moreInfo="This amount is currently being processed by the bank."
                      majorStat={false}
                    />
                    <StatCard
                      //fetching={isFetchingRoundUpsTransactions}
                      label="Cleared:"
                      value={roundUpsTransactions.roundUpsTotalCleared / 100}
                      valueTypePercent={false}
                      moreInfo="This amount has been cleared from your bank and is on the way to your Super."
                      majorStat={false}
                    />
                    <StatCard
                      //fetching={isFetchingRoundUpsTransactions}
                      label="Paid:"
                      value={roundUpsTransactions.roundUpsTotalPaid / 100}
                      valueTypePercent={false}
                      moreInfo="This amount has been paid to your super account where it can grOW."
                      majorStat={false}
                    />
                    <StatCard
                      //fetching={isFetchingRoundUpsTransactions}
                      label="Total Boost:"
                      value={(roundUpsTransactions.roundUpsTotalPaid + roundUpsTransactions.roundUpsTotalClearing + roundUpsTransactions.roundUpsTotalCleared) / 100}
                      valueTypePercent={false}
                      moreInfo="This amount is the total of paid and processing funds."
                      majorStat={true}
                    />
                    </div> 
                )}
              </div>
          )}
          { !authentication.user.contribute_now_visible ?
            null : (
              <div className="stats-container">
                <h2>Contribute Now Totals</h2>
                <StatCard
                  //fetching={isFetchingPayments}
                  label="Cleared:"
                  value={roundUpsTransactions.contributeNowTotalCleared / 100}
                  valueTypePercent={false}
                  moreInfo="This amount has been cleared from your bank and is on the way to your Super."
                  majorStat={false}
                />
                <StatCard
                  //fetching={isFetchingPayments}
                  label="Clearing:"
                  value={roundUpsTransactions.contributeNowTotalClearing / 100}
                  valueTypePercent={false}
                  moreInfo="This amount is currently being processed by the bank."
                  majorStat={false}
                />
                <StatCard
                  //fetching={isFetchingPayments}
                  label="Paid:"
                  value={roundUpsTransactions.contributeNowTotalPaid / 100}
                  valueTypePercent={false}
                  moreInfo="This amount has been paid to your super account where it can grOW."
                  majorStat={false}
                />
                <StatCard
                  //fetching={isFetchingPayments}
                  label="Total Boost:"
                  value={(roundUpsTransactions.contributeNowTotalPaid + roundUpsTransactions.contributeNowTotalClearing + roundUpsTransactions.contributeNowTotalCleared) / 100}
                  valueTypePercent={false}
                  moreInfo="This amount is the total of paid and processing funds."
                  majorStat={true}
                />
              </div>
            )} 
          </div>
        { authentication.user.roundups_visible && (
          <Tabs
            tabs={[
              { 
                label: 'Cashback',
                value: 'cashback-transactions' 
              },
              ( authentication.user.roundups_visible) ?
              { 
                label: 'Round Ups',
                value: 'round-ups'
              } : null,
              (authentication.user.contribute_now_visible) ?  
              { 
                label: 'Contribute Now',
                value: 'contribute-now-transactions' 
              } : null, 
            ].filter(tab => tab !== null)}
            activeTab={activeTab} 
            onActiveTabChanged={(nextTab) => setActiveTab(nextTab)}
          />
        )}
        <div className="transactions-container">
          { activeTab === 'round-ups' ? 
              (
                //<pre>Hello {JSON.stringify(roundUpsTransactions, null, 2)}</pre>
                //<Table
                <TransactionsTableRoundups 
                  roundUpsTransactions={localRoundUpsTransactions}
                  //tableData={localRoundUpsTransactions}
                  currentPage={1}
                  pageSize={10}
                  //isFetchingTableData={isFetchingRoundUpsTransactions}
                  //showRaiseInquiryDialog={showRaiseInquiryDialog}
                  //onShowRaiseInquiryDialog={() => setShowRaiseInquiryDialog(true)}
                  //onCloseRaiseInquiryDialog={onCloseRaiseInquiryDialog}
                  //onFetchCauseDonations={onFetchCauseDonations}
                  //KeyTransactionStatusDescriptionsMap={KeyTransactionStatusDescriptionsMap}
                  //roundupsVisibility={authentication.user.roundups_visible}
                  //dataName={ 'RoundUpsTransactions' }
                />
              ) 
            : activeTab === 'contribute-now-transactions' ? 
              (
                //<Table
                <TransactionsTableContributeNow
                  //tableData={localContributeNowTransactions}
                  contributeNowTransactions={localContributeNowTransactions}
                  currentPage={1}
                  pageSize={10}
                  //isFetchingTableData={isFetchingPayments}
                  //showRaiseInquiryDialog={showRaiseInquiryDialog}
                  //onShowRaiseInquiryDialog={() => setShowRaiseInquiryDialog(true)}
                  //onCloseRaiseInquiryDialog={onCloseRaiseInquiryDialog}
                  //onFetchCauseDonations={onFetchCauseDonations}
                  //KeyTransactionStatusDescriptionsMap={KeyTransactionStatusDescriptionsMap}
                  //contributeNowVisibility={authentication.user.contribute_now_visible}
                  //expandedComponent={ !localContributeNowTransactions ? null : ExpandedContributeNowComponent }
                  dataName={ 'ContributeNowTransactions' }
                />
              ) 
            : (
                <TransactionsTable 
                  cashbackTransactions={localCashbackTransactions}
                  //currentPage={localCashbackTransactions.page}
                  currentPage={1}
                  pageSize={10}
                  //pageSize={localCashbackTransactions.perPage}
                  //isFetchingShopperDonations={isFetchingCashbackTransactions}
                  showRaiseInquiryDialog={showRaiseInquiryDialog}
                  onShowRaiseInquiryDialog={() => setShowRaiseInquiryDialog(true)}
                  onCloseRaiseInquiryDialog={onCloseRaiseInquiryDialog}
                  onFetchCauseDonations={fetchCashbackTransactions}
                  KeyTransactionStatusDescriptionsMap={KeyTransactionStatusDescriptionsMap}
                />
              )
          }
        </div>
        {/* {(localCashbackTransactions.count > localCashbackTransactions.perPage) ? (
          <SimplePagination 
            totalItems={localCashbackTransactions.count} 
            perPage={localCashbackTransactions.perPage} 
            currentPage={localCashbackTransactions.page} 
            onFetchNextPage={() => onFetchCauseDonations(localCashbackTransactions.page + 1)} 
            onFetchPreviousPage={() => onFetchCauseDonations(localCashbackTransactions.page - 1)} 
            isFetchingShopperDonations={isFetchingCashbackTransactions} 
          />
        ) : null} */}
      </div>
    </Container>
  );
}

export default Purchases;

