export default ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description {
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }

  .not-installed {
    margin: 25px 0px 0px 0px;
  }

  .installed {
    width: calc(100% + 20px);
    margin: 25px 0px 0px 0px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      text-align: center;
      margin: 5px 10px;
    }
  }
`;
