import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';


import Account from './containers/Account';
import Landing from './containers/Landing';
import LandingMain from './containers/LandingMain';
import MainLayout from './layouts/main';
import NotFound from './containers/NotFound';
import BoostSettings from './containers/Settings';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import Shopping from './containers/Shopping';
import Purchases from './containers/Purchases';
import PrivacyPolicy from './containers/PrivacyPolicy';
import EULA from './containers/EULA';
import TermsAndConditions from './containers/TermsAndConditions';
import FinancialServicesGuide from './containers/FinancialServicesGuide';
import ForgotPassword from './containers/ForgotPassword';
import TransactionLoading from './containers/TransactionLoading';
import FAQ from './containers/FAQ';
import Calculators from './containers/Calculators';
import Dashboard from './containers/Dashboard';


const AppRoutes = () => {

  const user = useSelector((state) => state.authentication.user); 

  console.log('AppRoutes triggered with test user:', user);
  const location = useLocation();

  console.log('AppRoutes triggered with location:', location);

  return (
    <MainLayout>
      <Routes location={location}>
        {/* Root route conditionally rendering based on user authentication */}
        <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Landing />} />
        {/* Public Routes */}
        <Route path="/financial-services-guide" element={<FinancialServicesGuide />} />
        <Route path="/eula" element={<EULA />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/transaction-loading" element={<TransactionLoading />} />
        <Route path="/superannuation-calculators" element={<Calculators />} />
        <Route path="/dashboard" element={<Dashboard />} />
        
        
        {/* Authentication and User Routes */}
        <Route path="/account" element={user ? <Account /> : <Navigate to="/login" replace />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/login" element={ <FAQ /> } /> */}
        <Route path="/login" element={!user ? <SignIn /> : <Navigate to="/dashboard" replace />} />
        <Route path="/signup" element={user ? <Navigate to="/account" replace /> : <SignUp />} />
        <Route path="/settings" element={user ? <BoostSettings /> : <Navigate to="/login" replace />} />
        <Route path="/transactions" element={user ? <Purchases /> : <Navigate to="/login" replace />} />
        
        {/* Redirecting old paths */}
        <Route path="/shop" element={<Navigate to="/shops" />} />
        <Route path="/shops" element={<Shopping />} />
        <Route path="/calculators" element={<Navigate to="/superannuation-calculators" />} />
        
        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MainLayout>

  );
};

AppRoutes.propTypes = {
  user: PropTypes.object,
};

export default AppRoutes;
