import React from 'react';
import styled from 'styled-components';

import makeAWishLogo from 'Images/make-a-wish-australia-logo.png';
import bannerAvatar from 'Images/make-a-wish-australia-banner.png';

import styles from './index.css';


const CharityBannerWrapper = styled.div`${styles}`;

export default () => (
  <CharityBannerWrapper>
    <h4 className="title">When you Boost Your Super we match 1% to Make A Wish Australia</h4>
    <div className="content">
      <div className="left section">
        <img className="logo" src={makeAWishLogo} alt="make-a-wish-logo" />
      </div>
      <div className="middle section">
        <div className="avatar-banner-container">
          <img className="avatar-banner" src={bannerAvatar} alt="make-a-wish-logo" />
        </div>
      </div>
      <div className="right section">
        <p className="sub-title">Make a Wish has brought more than 10,000 wishes to life for sick kids all over Australia!</p>
      </div>
    </div>
  </CharityBannerWrapper>
);
