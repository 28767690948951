import React, {Component} from 'react';
import {PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import { BarLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import PopoverHover from 'SharedComponents/popover-hover';
import ActionButton from 'SharedComponents/action-button';
import Formsy from "formsy-react";
import TextInput from "SharedComponents/text-input";
import Accordion from 'SharedComponents/accordion';
import log from "Utils/logger";

import styles from './index.css.js';
import { colors } from '@mui/material';
import * as CalculatorActions from "Flux/calculators/actions";

const CalculatorWrapper = styled.section`${styles}`;
 

@connect(
    state => ({
      app: state.app,
      authentication: state.authentication,
      calculators: state.calculators
    }),
    dispatch => ({
      CalculatorActions: bindActionCreators(CalculatorActions, dispatch),
      dispatch
    })
  )

  export default class SuperCalculator extends Component {
    constructor(props){ 

        super(props);
        console.log('calculator props in constructor is: ', props);

        this.state = {

            age: props.calculators.age,
            gender: props.calculators.gender,
            desiredRetirementAge: props.calculators.desired_retirement_age,
            income: props.calculators.income,
            currentSuperBalance: props.calculators.current_super_balance,
            investmentReturn: props.calculators.pa_investment_return,
            inflation: props.calculators.inflation,
            beforeTaxContributionAmt: props.calculators.before_tax_contributions,
            beforeTaxContributionFrq: props.calculators.before_tax_contributions_frequency,
            beforeTaxContributionRes: 0,
            
            //afterTaxContributionAmt: props.calculators.after_tax_contributions,
            //afterTaxContributionFrq: props.calculators.after_tax_contributions_frequency,
            //afterTaxContributionRes: 0,

            roundUpsAfterTaxContributionAmt: props.calculators.round_ups_after_tax_contributions,
            roundUpsAfterTaxContributionFrq: props.calculators.round_ups_after_tax_contributions_frequency,
            roundUpsAfterTaxContributionRes: 0,

            personalAfterTaxContributionAmt: props.calculators.personal_after_tax_contributions,
            personalAfterTaxContributionFrq: props.calculators.personal_after_tax_contributions_frequency,
            personalAfterTaxContributionRes: 0,

            otherAfterTaxContributionAmt: props.calculators.other_after_tax_contributions,
            otherAfterTaxContributionFrq: props.calculators.other_after_tax_contributions_frequency,
            otherAfterTaxContributionRes: 0,

            vData: {},
            energyAmt: 0,

            resultSuperBalanceNoBoost:0,
            resultSuperBalanceBeforeTaxBoostOnly:0,
            resultSuperBalanceAfterTaxBoostOnly:0,
            resultSuperBalanceCashbackBoostOnly:0,
            resultSuperBalancePercentageSpendBoostOnly:0,
            resultSuperBalanceTotalBoost:0,
            resultBoostedByAmountBeforeTaxBoostOnly:0,
            resultBoostedByAmountAfterTaxBoostOnly:0,
            resultBoostedByAmountCashbackBoostOnly:0,
            resultBoostedByAmountPercentageSpendOnly:0,
            resultBoostedByAmountTotalBoost:0,

            sliderValues: [0,16,21.33,32,64],//[64,32,21.33,16,0],
            contributionsTaxRate: 15/100,
            superEarningsTaxRate: 15/100,
            feesAmount: parseFloat(props.calculators.superfund_fees_pa),
            feesPercentage: parseFloat(props.calculators.superfund_fees_pc),
            additionalContributions: false,  //is this being used?
            otherFundFees: false,
            cashbackContributions: false,
            changedInput: false,
            showResults: false,
            firstLoad: true,
            calculatingResults: false,
            graphColors: ['#484848', this.props.app.partnerContent.alternate_accent_color, '#ff5961', '#47e6b1', '#004b87'],

            defaultCashbackVariables: [],

            isFetchingCalculatorData: false, 
            isSavingCalculatorData: false,
            hasFetchedCalculatorData: false,
            isFetchingDefaultCalculatorCashbackVariables: false,
            //hasFetchedDefaultCalculatorCashbackVariables: false,

        }
        
        this.changeInput = this.changeInput.bind(this);
        //this.changeInput1 = this.changeInput1.bind(this);
        this.changeOtherFundFees = this.changeOtherFundFees.bind(this);
        this.changeInputBasic = this.changeInputBasic.bind(this);
        this.calculate = this.calculate.bind(this);
        this.initiateCalculation = this.initiateCalculation.bind(this);
        this.resetCashback = this.resetCashback.bind(this);
        this.resetEnergy = this.resetEnergy.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.changeSlider = this.changeSlider.bind(this);
        //this.changeCashbackContributions = this.changeCashbackContributions.bind(this);
        this.addCommas = this.addCommas.bind(this);
        this.fetchShopperCalculatorData = this.fetchShopperCalculatorData.bind(this);
        this.saveShopperCalculatorData = this.saveShopperCalculatorData.bind(this);
        this.fetchDefaultCalculatorCashbackVariables = this.fetchDefaultCalculatorCashbackVariables.bind(this);

    }

    componentDidMount(){
        const { user } = this.props.authentication;

        /* const { app } = this.props.app; */
        const { altAccentColor } = this.props.app.partnerContent.alternate_accent_color;

       
        
        this.fetchDefaultCalculatorCashbackVariables();
        console.log("componentDidMount triggered");
        console.log("componentDidMount this.state is: ", this.state);
        console.log("componentDidMount this.props is: ", this.props);
        console.log("componentDidMount user is: ", user);
        //console.log("componentDidMount app is: ", app);


        console.log("componentDidMount this.props.authentication is: ", this.props.authentication);
        console.log('componentDidMount this.props.app is: ', this.props.app);
        console.log("componentDidMount altAccentColor is: ", altAccentColor);
        console.log("componentDidMount this.props.app.partnerContent is: ", this.props.app.partnerContent);
        console.log("componentDidMount this.props.app.partnerContent.alternate_accent_color is: ", this.props.app.partnerContent.alternate_accent_color);
        
        //this.fetchShopperCalculatorData();
        if (!!user) {
            console.log("componentDidMount user available let's fetchShopperCalculatorData");
            this.fetchShopperCalculatorData();
        }
/* 
        if (this.state.afterTaxContributionAmt !== 0 && this.state.afterTaxContributionRes === 0) {
            const afterTaxContributionRes = (this.state.afterTaxContributionFrq==="D"?this.state.afterTaxContributionAmt*(100/100)*(365):(this.state.afterTaxContributionFrq==="W"?this.state.afterTaxContributionAmt*(100/100)*(52):(this.state.afterTaxContributionFrq==="F"?this.state.afterTaxContributionAmt*(100/100)*(26):(this.state.afterTaxContributionFrq==="M"?this.state.afterTaxContributionAmt*100*12/100:(this.state.afterTaxContributionFrq==="A"?this.state.afterTaxContributionAmt*(100/100):(this.state.afterTaxContributionFrq==="B"?this.state.afterTaxContributionAmt*(100/100)*2:(this.state.afterTaxContributionFrq==="E2Y"?this.state.afterTaxContributionAmt*(100/100)/2:(this.state.afterTaxContributionFrq==="E3Y"?this.state.afterTaxContributionAmt*(100/100)/3:(this.state.afterTaxContributionFrq==="E4Y"?this.state.afterTaxContributionAmt*(100/100)/4:this.state.afterTaxContributionRes)))))))))
            console.log ('componentDidMount update state afterTaxContributionRes to: ', afterTaxContributionRes);
            this.setState({ afterTaxContributionRes: afterTaxContributionRes });
        } */

        if (this.state.beforeTaxContributionAmt !== 0 && this.state.beforeTaxContributionRes === 0) {
            const beforeTaxContributionRes = (this.state.beforeTaxContributionFrq==="D"?this.state.beforeTaxContributionAmt*(100/100)*(365):(this.state.beforeTaxContributionFrq==="W"?this.state.beforeTaxContributionAmt*(100/100)*(52):(this.state.beforeTaxContributionFrq==="F"?this.state.beforeTaxContributionAmt*(100/100)*(26):(this.state.beforeTaxContributionFrq==="M"?this.state.beforeTaxContributionAmt*100*12/100:(this.state.beforeTaxContributionFrq==="A"?this.state.beforeTaxContributionAmt*(100/100):(this.state.beforeTaxContributionFrq==="B"?this.state.beforeTaxContributionAmt*(100/100)*2:(this.state.beforeTaxContributionFrq==="E2Y"?this.state.beforeTaxContributionAmt*(100/100)/2:(this.state.beforeTaxContributionFrq==="E3Y"?this.state.beforeTaxContributionAmt*(100/100)/3:(this.state.beforeTaxContributionFrq==="E4Y"?this.state.beforeTaxContributionAmt*(100/100)/4:this.state.beforeTaxContributionRes)))))))))
            console.log ('componentDidMount update state beforeTaxContributionRes to: ', beforeTaxContributionRes);
            this.setState({ beforeTaxContributionRes: beforeTaxContributionRes });
        }

        if (this.state.roundUpsAfterTaxContributionAmt !== 0 && this.state.roundUpsAfterTaxContributionRes === 0) {
            const roundUpsAfterTaxContributionRes = (this.state.roundUpsAfterTaxContributionFrq==="D"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(365):(this.state.roundUpsAfterTaxContributionFrq==="W"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(52):(this.state.roundUpsAfterTaxContributionFrq==="F"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(26):(this.state.roundUpsAfterTaxContributionFrq==="M"?this.state.roundUpsAfterTaxContributionAmt*100*12/100:(this.state.roundUpsAfterTaxContributionFrq==="A"?this.state.roundUpsAfterTaxContributionAmt*(100/100):(this.state.roundUpsAfterTaxContributionFrq==="B"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*2:(this.state.roundUpsAfterTaxContributionFrq==="E2Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/2:(this.state.roundUpsAfterTaxContributionFrq==="E3Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/3:(this.state.roundUpsAfterTaxContributionFrq==="E4Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/4:this.state.roundUpsAfterTaxContributionRes)))))))))
            console.log ('componentDidMount update state roundUpsAfterTaxContributionRes to: ', roundUpsAfterTaxContributionRes);
            this.setState({ roundUpsAfterTaxContributionRes: roundUpsAfterTaxContributionRes });
        }

        if (this.state.personalAfterTaxContributionAmt !== 0 && this.state.personalAfterTaxContributionRes === 0) {
            const personalAfterTaxContributionRes = (this.state.personalAfterTaxContributionFrq==="D"?this.state.personalAfterTaxContributionAmt*(100/100)*(365):(this.state.personalAfterTaxContributionFrq==="W"?this.state.personalAfterTaxContributionAmt*(100/100)*(52):(this.state.personalAfterTaxContributionFrq==="F"?this.state.personalAfterTaxContributionAmt*(100/100)*(26):(this.state.personalAfterTaxContributionFrq==="M"?this.state.personalAfterTaxContributionAmt*100*12/100:(this.state.personalAfterTaxContributionFrq==="A"?this.state.personalAfterTaxContributionAmt*(100/100):(this.state.personalAfterTaxContributionFrq==="B"?this.state.personalAfterTaxContributionAmt*(100/100)*2:(this.state.personalAfterTaxContributionFrq==="E2Y"?this.state.personalAfterTaxContributionAmt*(100/100)/2:(this.state.personalAfterTaxContributionFrq==="E3Y"?this.state.personalAfterTaxContributionAmt*(100/100)/3:(this.state.personalAfterTaxContributionFrq==="E4Y"?this.state.personalAfterTaxContributionAmt*(100/100)/4:this.state.personalAfterTaxContributionRes)))))))))
            console.log ('componentDidMount update state personalAfterTaxContributionRes to: ', personalAfterTaxContributionRes);
            this.setState({ personalAfterTaxContributionRes: personalAfterTaxContributionRes });
        }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount - Supercalculator is unmounted - why?');
      }

    // static getDerivedStateFromProps(props, state) {
    //     console.log('getDerivedStateFromProps triggered');
    //     console.log('props is: ', props);
    //     console.log('state is: ', state);
    //     console.log('props.calculators is: ', props.calculators);

    //     if (props.calculators !== state.hasFetchedCalculatorData) {
    //         console.log('props.calculators is: ', props.calculators);

    //         return {
    //             age: props.calculators.age,
    //             desiredRetirementAge: props.calculators.desired_retirement_age,
    //             income: props.calculators.income,
    //             currentSuperBalance: props.calculators.current_super_balance,
    //             investmentReturn: props.calculators.pa_investment_return,
    //             inflation: props.calculators.inflation,
    //             beforeTaxContributionAmt: props.calculators.before_tax_contributions,
    //             beforeTaxContributionFrq: props.calculators.before_tax_contributions_frequency,
    //             //beforeTaxContributionRes: 0,
                
    //             afterTaxContributionAmt: props.calculators.after_tax_contributions,
    //             afterTaxContributionFrq: props.calculators.after_tax_contributions_frequency,
    //             //afterTaxContributionRes: 0,
    //         }
    //     }
    // }


    componentDidUpdate(prevProps,prevState){
        const {  
            age,
            gender,
            desired_retirement_age,
            income,
            current_super_balance,
            pa_investment_return,
            inflation,
            before_tax_contributions,
            before_tax_contributions_frequency,
            after_tax_contributions,
            after_tax_contributions_frequency,

            superfund_fees_pa,
            superfund_fees_pc,

            isFetchingDefaultCalculatorCashbackVariables,
        } = this.props.calculators;

        console.log("componentDidUpdate this.state is: ", this.state);
        console.log("componentDidUpdate this.props is: ", this.props);


        console.log('componentDidUpdate triggered');
        let vData = this.state.vData;
        console.log('componentDidUpdate vData is : ', vData);
        console.log('componentDidUpdate Object.keys(vData).length is : ', Object.keys(vData).length);
        //console.log('componentDidUpdate state is : ', state);
        console.log('componentDidUpdate found length of .calc-input is:',(document.querySelectorAll('.calc-input').length));
        if ((document.querySelectorAll('.calc-input').length != 0) && (!Object.keys(vData).length)){
            console.log('componentDidUpdate found length of .calc-input is:',(document.querySelectorAll('.calc-input').length ));
            var changeInput = this.changeInput;
            var ev = new Event('input', { bubbles: true});
            //console.log('2nd componentDidMount this.changeInput : ', this.changeInput);
            console.log('componentDidUpdate ev : ', ev);
            console.log('componentDidUpdate querySelectorAll calc-input is : ',document.querySelectorAll('.calc-input'));
            document.querySelectorAll('.calc-input').forEach(function(elem){
                elem.addEventListener("input", function() {
                    console.log('componentDidUpdat elem : ', elem);
                    changeInput(elem)
                });
                elem.dispatchEvent(ev);
            });
            this.frequency = {"Daily":"D","Weekly":"W","Fortnightly":"F","Monthly":"M","Twice/Year":"B","Annually":"A","Every 2 Yrs":"E2Y","Every 3 Yrs":"E3Y","Every 4 Yrs":"E4Y"};
        }

        if (!this.state.isFetchingDefaultCalculatorCashbackVariables && prevState.isFetchingDefaultCalculatorCashbackVariables) {
            console.log('componentDidUpdate this.state.isFetchingDefaultCalculatorCashbackVariables is: ', this.state.isFetchingDefaultCalculatorCashbackVariables);
            console.log('componentDidUpdate prevState.isFetchingDefaultCalculatorCashbackVariables is: ', prevState.isFetchingDefaultCalculatorCashbackVariables);
            console.log('componentDidUpdate has just finished fetching default calculator cashback variables, lets reset the shoppeing variables');
            this.resetCashback();
        }

        if (!this.state.calculatingResults && prevState.calculatingResults) {
            console.log('componentDidUpdate this.state.calculatingResults is: ', this.state.calculatingResults);
            console.log('componentDidUpdate prevState.calculatingResults is: ', prevState.calculatingResults);
            console.log('componentDidUpdate has just finished calculating results, lets saveShopperCalculatorData')
            this.saveShopperCalculatorData();
        }

        if (this.state.beforeTaxContributionAmt !== prevState.beforeTaxContributionAmt) {
            const beforeTaxContributionRes = (this.state.beforeTaxContributionFrq==="D"?this.state.beforeTaxContributionAmt*(100/100)*(365):(this.state.beforeTaxContributionFrq==="W"?this.state.beforeTaxContributionAmt*(100/100)*(52):(this.state.beforeTaxContributionFrq==="F"?this.state.beforeTaxContributionAmt*(100/100)*(26):(this.state.beforeTaxContributionFrq==="M"?this.state.beforeTaxContributionAmt*100*12/100:(this.state.beforeTaxContributionFrq==="A"?this.state.beforeTaxContributionAmt*(100/100):(this.state.beforeTaxContributionFrq==="B"?this.state.beforeTaxContributionAmt*(100/100)*2:(this.state.beforeTaxContributionFrq==="E2Y"?this.state.beforeTaxContributionAmt*(100/100)/2:(this.state.beforeTaxContributionFrq==="E3Y"?this.state.beforeTaxContributionAmt*(100/100)/3:(this.state.beforeTaxContributionFrq==="E4Y"?this.state.beforeTaxContributionAmt*(100/100)/4:this.state.beforeTaxContributionRes)))))))))
            this.setState({ beforeTaxContributionRes: beforeTaxContributionRes });
        }

        if (this.state.roundUpsAfterTaxContributionAmt !== prevState.roundUpsAfterTaxContributionAmt) {
            console.log ('componentDidUpdate  this.state.roundUpsAfterTaxContributionAmt has changed. Lets calculate roundUpsAfterTaxContributionRes')
            const roundUpsAfterTaxContributionRes = (this.state.roundUpsAfterTaxContributionFrq==="D"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(365):(this.state.roundUpsAfterTaxContributionFrq==="W"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(52):(this.state.roundUpsAfterTaxContributionFrq==="F"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(26):(this.state.roundUpsAfterTaxContributionFrq==="M"?this.state.roundUpsAfterTaxContributionAmt*100*12/100:(this.state.roundUpsAfterTaxContributionFrq==="A"?this.state.roundUpsAfterTaxContributionAmt*(100/100):(this.state.roundUpsAfterTaxContributionFrq==="B"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*2:(this.state.roundUpsAfterTaxContributionFrq==="E2Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/2:(this.state.roundUpsAfterTaxContributionFrq==="E3Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/3:(this.state.roundUpsAfterTaxContributionFrq==="E4Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/4:this.state.roundUpsAfterTaxContributionRes)))))))))
            console.log ('componentDidUpdate  this.state.roundUpsAfterTaxContributionAmt is: ', this.state.roundUpsAfterTaxContributionAmt);
            console.log ('componentDidUpdate this.state.roundUpsAfterTaxContributionFrq is: ', this.state.roundUpsAfterTaxContributionFrq);
            console.log ('componentDidUpdate amt change typeof(roundUpsAfterTaxContributionRes) is: ', typeof(roundUpsAfterTaxContributionRes), 'with value : ', roundUpsAfterTaxContributionRes);
            this.setState({ roundUpsAfterTaxContributionRes: roundUpsAfterTaxContributionRes });
        }

        if (this.state.personalAfterTaxContributionAmt !== prevState.personalAfterTaxContributionAmt) {
            console.log ('componentDidUpdate  this.state.personalAfterTaxContributionAmt has changed. Lets calculate personalAfterTaxContributionRes')
            const personalAfterTaxContributionRes = (this.state.personalAfterTaxContributionFrq==="D"?this.state.personalAfterTaxContributionAmt*(100/100)*(365):(this.state.personalAfterTaxContributionFrq==="W"?this.state.personalAfterTaxContributionAmt*(100/100)*(52):(this.state.personalAfterTaxContributionFrq==="F"?this.state.personalAfterTaxContributionAmt*(100/100)*(26):(this.state.personalAfterTaxContributionFrq==="M"?this.state.personalAfterTaxContributionAmt*100*12/100:(this.state.personalAfterTaxContributionFrq==="A"?this.state.personalAfterTaxContributionAmt*(100/100):(this.state.personalAfterTaxContributionFrq==="B"?this.state.personalAfterTaxContributionAmt*(100/100)*2:(this.state.personalAfterTaxContributionFrq==="E2Y"?this.state.personalAfterTaxContributionAmt*(100/100)/2:(this.state.personalAfterTaxContributionFrq==="E3Y"?this.state.personalAfterTaxContributionAmt*(100/100)/3:(this.state.personalAfterTaxContributionFrq==="E4Y"?this.state.personalAfterTaxContributionAmt*(100/100)/4:this.state.personalAfterTaxContributionRes)))))))))
            console.log ('componentDidUpdate  this.state.personalAfterTaxContributionAmt is: ', this.state.personalAfterTaxContributionAmt);
            console.log ('componentDidUpdate this.state.personalAfterTaxContributionFrq is: ', this.state.personalAfterTaxContributionFrq);
            console.log ('componentDidUpdate amt change typeof(personalAfterTaxContributionRes) is: ', typeof(personalAfterTaxContributionRes), 'with value : ', personalAfterTaxContributionRes);
            this.setState({ personalAfterTaxContributionRes: personalAfterTaxContributionRes });
        }

        if (this.state.otherAfterTaxContributionAmt !== prevState.otherAfterTaxContributionAmt) {
            console.log ('componentDidUpdate  this.state.otherAfterTaxContributionAmt has changed. Lets calculate otherAfterTaxContributionRes')
            const otherAfterTaxContributionRes = (this.state.otherAfterTaxContributionFrq==="D"?this.state.otherAfterTaxContributionAmt*(100/100)*(365):(this.state.otherAfterTaxContributionFrq==="W"?this.state.otherAfterTaxContributionAmt*(100/100)*(52):(this.state.otherAfterTaxContributionFrq==="F"?this.state.otherAfterTaxContributionAmt*(100/100)*(26):(this.state.otherAfterTaxContributionFrq==="M"?this.state.otherAfterTaxContributionAmt*100*12/100:(this.state.otherAfterTaxContributionFrq==="A"?this.state.otherAfterTaxContributionAmt*(100/100):(this.state.otherAfterTaxContributionFrq==="B"?this.state.otherAfterTaxContributionAmt*(100/100)*2:(this.state.otherAfterTaxContributionFrq==="E2Y"?this.state.otherAfterTaxContributionAmt*(100/100)/2:(this.state.otherAfterTaxContributionFrq==="E3Y"?this.state.otherAfterTaxContributionAmt*(100/100)/3:(this.state.otherAfterTaxContributionFrq==="E4Y"?this.state.otherAfterTaxContributionAmt*(100/100)/4:this.state.otherAfterTaxContributionRes)))))))))
            console.log ('componentDidUpdate  this.state.otherAfterTaxContributionAmt is: ', this.state.otherAfterTaxContributionAmt);
            console.log ('componentDidUpdate this.state.otherAfterTaxContributionFrq is: ', this.state.otherAfterTaxContributionFrq);
            console.log ('componentDidUpdate amt change typeof(otherAfterTaxContributionRes) is: ', typeof(otherAfterTaxContributionRes), 'with value : ', otherAfterTaxContributionRes);
            this.setState({ otherAfterTaxContributionRes: otherAfterTaxContributionRes });
        }

/*         if (this.state.afterTaxContributionAmt !== prevState.afterTaxContributionAmt) {
            console.log ('componentDidUpdate  this.state.afterTaxContributionAmt has changed. Lets calculate afterTaxContributionRes')
            const afterTaxContributionRes = (this.state.afterTaxContributionFrq==="D"?this.state.afterTaxContributionAmt*(100/100)*(365):(this.state.afterTaxContributionFrq==="W"?this.state.afterTaxContributionAmt*(100/100)*(52):(this.state.afterTaxContributionFrq==="F"?this.state.afterTaxContributionAmt*(100/100)*(26):(this.state.afterTaxContributionFrq==="M"?this.state.afterTaxContributionAmt*100*12/100:(this.state.afterTaxContributionFrq==="A"?this.state.afterTaxContributionAmt*(100/100):(this.state.afterTaxContributionFrq==="B"?this.state.afterTaxContributionAmt*(100/100)*2:(this.state.afterTaxContributionFrq==="E2Y"?this.state.afterTaxContributionAmt*(100/100)/2:(this.state.afterTaxContributionFrq==="E3Y"?this.state.afterTaxContributionAmt*(100/100)/3:(this.state.afterTaxContributionFrq==="E4Y"?this.state.afterTaxContributionAmt*(100/100)/4:this.state.afterTaxContributionRes)))))))))
            console.log ('componentDidUpdate  this.state.afterTaxContributionAmt is: ', this.state.afterTaxContributionAmt);
            console.log ('componentDidUpdate this.state.afterTaxContributionFrq is: ', this.state.afterTaxContributionFrq);
            console.log ('componentDidUpdate amt change typeof(afterTaxContributionRes) is: ', typeof(afterTaxContributionRes), 'with value : ', afterTaxContributionRes);
            this.setState({ afterTaxContributionRes: afterTaxContributionRes });
        } */

        if (this.state.beforeTaxContributionFrq !== prevState.beforeTaxContributionFrq) {
            const beforeTaxContributionRes = (this.state.beforeTaxContributionFrq==="D"?this.state.beforeTaxContributionAmt*(100/100)*(365):(this.state.beforeTaxContributionFrq==="W"?this.state.beforeTaxContributionAmt*(100/100)*(52):(this.state.beforeTaxContributionFrq==="F"?this.state.beforeTaxContributionAmt*(100/100)*(26):(this.state.beforeTaxContributionFrq==="M"?this.state.beforeTaxContributionAmt*100*12/100:(this.state.beforeTaxContributionFrq==="A"?this.state.beforeTaxContributionAmt*(100/100):(this.state.beforeTaxContributionFrq==="B"?this.state.beforeTaxContributionAmt*(100/100)*2:(this.state.beforeTaxContributionFrq==="E2Y"?this.state.beforeTaxContributionAmt*(100/100)/2:(this.state.beforeTaxContributionFrq==="E3Y"?this.state.beforeTaxContributionAmt*(100/100)/3:(this.state.beforeTaxContributionFrq==="E4Y"?this.state.beforeTaxContributionAmt*(100/100)/4:this.state.beforeTaxContributionRes)))))))))
            this.setState({ beforeTaxContributionRes: beforeTaxContributionRes });
        }

        if (this.state.roundUpsAfterTaxContributionFrq !== prevState.roundUpsAfterTaxContributionFrq) {
            const roundUpsAfterTaxContributionRes = (this.state.roundUpsAfterTaxContributionFrq==="D"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(365):(this.state.roundUpsAfterTaxContributionFrq==="W"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(52):(this.state.roundUpsAfterTaxContributionFrq==="F"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*(26):(this.state.roundUpsAfterTaxContributionFrq==="M"?this.state.roundUpsAfterTaxContributionAmt*100*12/100:(this.state.roundUpsAfterTaxContributionFrq==="A"?this.state.roundUpsAfterTaxContributionAmt*(100/100):(this.state.roundUpsAfterTaxContributionFrq==="B"?this.state.roundUpsAfterTaxContributionAmt*(100/100)*2:(this.state.roundUpsAfterTaxContributionFrq==="E2Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/2:(this.state.roundUpsAfterTaxContributionFrq==="E3Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/3:(this.state.roundUpsAfterTaxContributionFrq==="E4Y"?this.state.roundUpsAfterTaxContributionAmt*(100/100)/4:this.state.roundUpsAfterTaxContributionRes)))))))))
            console.log ('freq change typeof(roundUpsAfterTaxContributionRes) is: ', typeof(roundUpsAfterTaxContributionRes));
            this.setState({ roundUpsAfterTaxContributionRes: roundUpsAfterTaxContributionRes });
        }

        if (this.state.personalAfterTaxContributionFrq !== prevState.personalAfterTaxContributionFrq) {
            const personalAfterTaxContributionRes = (this.state.personalAfterTaxContributionFrq==="D"?this.state.personalAfterTaxContributionAmt*(100/100)*(365):(this.state.personalAfterTaxContributionFrq==="W"?this.state.personalAfterTaxContributionAmt*(100/100)*(52):(this.state.personalAfterTaxContributionFrq==="F"?this.state.personalAfterTaxContributionAmt*(100/100)*(26):(this.state.personalAfterTaxContributionFrq==="M"?this.state.personalAfterTaxContributionAmt*100*12/100:(this.state.personalAfterTaxContributionFrq==="A"?this.state.personalAfterTaxContributionAmt*(100/100):(this.state.personalAfterTaxContributionFrq==="B"?this.state.personalAfterTaxContributionAmt*(100/100)*2:(this.state.personalAfterTaxContributionFrq==="E2Y"?this.state.personalAfterTaxContributionAmt*(100/100)/2:(this.state.personalAfterTaxContributionFrq==="E3Y"?this.state.personalAfterTaxContributionAmt*(100/100)/3:(this.state.personalAfterTaxContributionFrq==="E4Y"?this.state.personalAfterTaxContributionAmt*(100/100)/4:this.state.personalAfterTaxContributionRes)))))))))
            console.log ('freq change typeof(personalAfterTaxContributionRes) is: ', typeof(personalAfterTaxContributionRes));
            this.setState({ personalAfterTaxContributionRes: personalAfterTaxContributionRes });
        }

        if (this.state.otherAfterTaxContributionFrq !== prevState.otherAfterTaxContributionFrq) {
            const otherAfterTaxContributionRes = (this.state.otherAfterTaxContributionFrq==="D"?this.state.otherAfterTaxContributionAmt*(100/100)*(365):(this.state.otherAfterTaxContributionFrq==="W"?this.state.otherAfterTaxContributionAmt*(100/100)*(52):(this.state.otherAfterTaxContributionFrq==="F"?this.state.otherAfterTaxContributionAmt*(100/100)*(26):(this.state.otherAfterTaxContributionFrq==="M"?this.state.otherAfterTaxContributionAmt*100*12/100:(this.state.otherAfterTaxContributionFrq==="A"?this.state.otherAfterTaxContributionAmt*(100/100):(this.state.otherAfterTaxContributionFrq==="B"?this.state.otherAfterTaxContributionAmt*(100/100)*2:(this.state.otherAfterTaxContributionFrq==="E2Y"?this.state.otherAfterTaxContributionAmt*(100/100)/2:(this.state.otherAfterTaxContributionFrq==="E3Y"?this.state.otherAfterTaxContributionAmt*(100/100)/3:(this.state.otherAfterTaxContributionFrq==="E4Y"?this.state.otherAfterTaxContributionAmt*(100/100)/4:this.state.otherAfterTaxContributionRes)))))))))
            console.log ('freq change typeof(otherAfterTaxContributionRes) is: ', typeof(otherAfterTaxContributionRes));
            this.setState({ otherAfterTaxContributionRes: otherAfterTaxContributionRes });
        }

/*         if (this.state.afterTaxContributionFrq !== prevState.afterTaxContributionFrq) {
            const afterTaxContributionRes = (this.state.afterTaxContributionFrq==="D"?this.state.afterTaxContributionAmt*(100/100)*(365):(this.state.afterTaxContributionFrq==="W"?this.state.afterTaxContributionAmt*(100/100)*(52):(this.state.afterTaxContributionFrq==="F"?this.state.afterTaxContributionAmt*(100/100)*(26):(this.state.afterTaxContributionFrq==="M"?this.state.afterTaxContributionAmt*100*12/100:(this.state.afterTaxContributionFrq==="A"?this.state.afterTaxContributionAmt*(100/100):(this.state.afterTaxContributionFrq==="B"?this.state.afterTaxContributionAmt*(100/100)*2:(this.state.afterTaxContributionFrq==="E2Y"?this.state.afterTaxContributionAmt*(100/100)/2:(this.state.afterTaxContributionFrq==="E3Y"?this.state.afterTaxContributionAmt*(100/100)/3:(this.state.afterTaxContributionFrq==="E4Y"?this.state.afterTaxContributionAmt*(100/100)/4:this.state.afterTaxContributionRes)))))))))
            console.log ('freq change typeof(afterTaxContributionRes) is: ', typeof(afterTaxContributionRes));
            this.setState({ afterTaxContributionRes: afterTaxContributionRes });
        } */

        if (!this.state.isFetchingCalculatorData && prevState.isFetchingCalculatorData) {
            console.log('componentDidUpdate this.state.isFetchingCalculatorData is: ', this.state.isFetchingCalculatorData);
            console.log('componentDidUpdate prevState.isFetchingCalculatorData is: ', prevState.isFetchingCalculatorData);
            console.log('componentDidUpdate has just finished fetching calculator data, lets update the state with props');
            console.log('componentDidUpdate after Fetching shopperCalcData this.state is ', this.state);
            console.log('componentDidUpdate after Fetching shopperCalcData this.props is ', this.props);
            console.log('componentDidUpdate after Fetching shopperCalcData this.props.calculators is ', this.props.calculators);

           this.setState({
                age: age,
                gender: gender,
                desiredRetirementAge: desired_retirement_age,
                income: income,
                currentSuperBalance: current_super_balance,
                investmentReturn: pa_investment_return,
                inflation: inflation,
                beforeTaxContributionAmt: before_tax_contributions,
                beforeTaxContributionFrq: before_tax_contributions_frequency,
                afterTaxContributionAmt: after_tax_contributions,
                afterTaxContributionFrq: after_tax_contributions_frequency,
                feesAmount: Number(superfund_fees_pa),
                feesPercentage: Number(superfund_fees_pc),
            });
        }
    }

    async fetchDefaultCalculatorCashbackVariables() {
        console.log('fetchDefaultCalculatorCashbackVariables triggered in super-calculator');
        const { CalculatorActions } = this.props;
        this.setState({ isFetchingDefaultCalculatorCashbackVariables: true });
    
        try {
          const onFetchDefaultCalculatorCashbackVariablesResult = await CalculatorActions.fetchDefaultCalculatorCashbackVariables();
          console.log('onFetchDefaultCalculatorCashbackVariablesResult is: ', onFetchDefaultCalculatorCashbackVariablesResult );
          this.setState({ defaultCashbackVariables: onFetchDefaultCalculatorCashbackVariablesResult.data.calculator_variables });
          //CalculatorActions.setShopperCalculator(onFetchShopperCalculatorDataResult.data);

        } catch (exception) {
          console.log(exception);
          log({
            event: "System-Error",
            source: "Fetch Default Calculator Cashback Variables",
            info: exception,
            ...exception,
          });
        } finally {
          this.setState({ isFetchingDefaultCalculatorCashbackVariables: false });
          console.log ('this.state.defaultCashbackVariables in finally after setting them in state: ', this.state.defaultCashbackVariables);
        }
      }

    async fetchShopperCalculatorData() {
        const { CalculatorActions } = this.props;
        this.setState({ isFetchingCalculatorData: true });
    
        try {
          const onFetchShopperCalculatorDataResult = await CalculatorActions.fetchShopperCalculatorData();
          console.log('onFetchShopperCalculatorDataResult is: ', onFetchShopperCalculatorDataResult );
          if (onFetchShopperCalculatorDataResult.data.data.length > 0){
            CalculatorActions.setShopperCalculatorData(onFetchShopperCalculatorDataResult.data.data[0]);
            this.setState({ hasFetchedCalculatorData: true });
          }

        } catch (exception) {
          console.log(exception);
          log({
            event: "System-Error",
            source: "Fetch Calculator Shopper Content",
            info: exception,
            ...exception,
          });
        } finally {
          this.setState({ isFetchingCalculatorData: false });
        }
      }

      async saveShopperCalculatorData() {
        const { CalculatorActions } = this.props;
        this.setState({ isSavingCalculatorData: true });
        const form = {
            age: this.state.age,
            gender: this.state.gender,
            desired_retirement_age: this.state.desiredRetirementAge,
            income: this.state.income,
            current_super_balance: this.state.currentSuperBalance,
            pa_investment_return: this.state.investmentReturn,
            inflation: this.state.inflation,
            superfund_fees_pa: this.state.feesAmount,
            superfund_fees_pc: this.state.feesPercentage,
            before_tax_contributions: this.state.beforeTaxContributionAmt,
            before_tax_contributions_frequency: this.state.beforeTaxContributionFrq,
            //the following 2 lines can be removed after the backend is updated to accept the new fields
            after_tax_contributions: this.totalAfterTaxBoost(), //using as a trigger to store data
            after_tax_contributions_frequency: this.state.personalAfterTaxContributionFrq, //using as a trigger to store data
            // after_tax_contributions: this.state.afterTaxContributionAmt,
            // after_tax_contributions_frequency: this.state.afterTaxContributionFrq,
            round_ups_after_tax_contributions: this.state.roundUpsAfterTaxContributionAmt,
            round_ups_after_tax_contributions_frequency: this.state.roundUpsAfterTaxContributionFrq,
            personal_after_tax_contributions: this.state.personalAfterTaxContributionAmt,
            personal_after_tax_contributions_frequency: this.state.personalAfterTaxContributionFrq,
            other_after_tax_contributions: this.state.otherAfterTaxContributionAmt,
            other_after_tax_contributions_frequency: this.state.otherAfterTaxContributionFrq,
        }

        try {
          const onsaveShopperCalculatorDataResult = await CalculatorActions.saveShopperCalculatorData( form );

          console.log('onsaveShopperCalculatorDataResult is: ', onsaveShopperCalculatorDataResult );
          //CalculatorActions.saveCalculatorShopperData(onsaveShopperCalculatorDataResult.data);
        } catch (exception) {
          console.log(exception);
          log({
            event: "System-Error",
            source: "Save Calculator Shopper Data",
            info: exception,
            ...exception,
          });
        } finally {
            console.log('finished saving shopper calc data so lets update props with latest form: ', form);
            CalculatorActions.setShopperCalculatorData( form );
            this.setState({ isSavingCalculatorData: false });
        }
      }

    changeInput(elem){
        console.log('changeInput triggered with elem: ', elem);
        elem = elem.target || elem
        let id = elem.id;
        let avg = elem.dataset.avg;
        let x = id.slice(0,id.length - 3);
        let inp = x+'Amt';
        let sel = x+'Trm';
        let res = x+'Res';
        let amt = this[inp].value;
        let term = this[sel].value;
        let vData = this.state.vData;
       
        // let average = this.state.average;

        // let result = (term==="D"?amt*(average[x]/100)*(365):(term==="W"?amt*(average[x]/100)*(52):(term==="F"?amt*(average[x]/100)*(26):(term==="M"?amt*average[x]*12/100:(term==="A"?amt*(average[x]/100):(term==="B"?amt*(average[x]/100)*2:(term==="E2Y"?amt*(average[x]/100)/2:(term==="E3Y"?amt*(average[x]/100)/3:(term==="E4Y"?amt*(average[x]/100)/4:"error")))))))));
        let result = (term==="D"?amt*(avg/100)*(365):(term==="W"?amt*(avg/100)*(52):(term==="F"?amt*(avg/100)*(26):(term==="M"?amt*avg*12/100:(term==="A"?amt*(avg/100):(term==="B"?amt*(avg/100)*2:(term==="E2Y"?amt*(avg/100)/2:(term==="E3Y"?amt*(avg/100)/3:(term==="E4Y"?amt*(avg/100)/4:"error")))))))));
        vData[res] = Number(result);
        this.setState({
            // [res]: result,
            vData: vData,
            // showResults: false,
            changedInput: true,
        })

         console.log('***** result,this.state', result, this.state);
         console.log('***** vData is: ',vData )
    }

    //changeInput1({target: { id, value }}){
    // changeInput1(event){
    //     console.log("changeInput1 Triggered with event.target.id: ",event.target.id," and event.target.value: ",event.target.value);
    //     const newValue = event.target.value;
    //     const changedItem = event.target.id
    //     // this seems the same as changeInputBasic except for id vs name. See if you can combine them
    //         this.setState({
    //             [changedItem]: newValue,
    //             changedInput: true,
    //         }) 
    // }

	changeInputBasic(event) {
		console.log('form change event is : ', event);
		console.log('this.state is: ', this.state);
        console.log('event target name is: ', event.target.id);
        console.log('event target value is: ', event.target.value);
        this.setState({ 
            [event.target.name]: event.target.value,
            changedInput: true,	
        });
    }

    changeOtherFundFees(event){

        this.setState({
            [event.target.id]: Number(event.target.value), //q : what does Number do? 
            changedInput: true,
        });
    }
    changeSlider(){
        console.log('changeSlider Triggered with this.energyAmt.value: ', this.energyAmt.value);
        this.setState({
            energyAmt: this.state.sliderValues[Number(this.energyAmt.value-1)],
            // showResults: false,
            changedInput: true,
         });
    }
    
    cashbackBoost(){
        console.log(' cashbackBoost Triggered with this.state.vData: ', this.state.vData);
        let s = this.state.vData;
        let total = this.state.energyAmt;
        Object.keys(s).forEach((key,index)=>{
            total += s[key];
        });
        return total
    }

    totalAfterTaxBoost(){
        console.log(' totalAfterTaxBoost Triggered ');
        const totalAfterTaxBoost = 
            //this.state.afterTaxContributionRes +
            this.state.roundUpsAfterTaxContributionRes +
            this.state.personalAfterTaxContributionRes +
            this.state.otherAfterTaxContributionRes;
        return totalAfterTaxBoost
        
    }

    // changeCashbackContributions(){
    //     let cashbackContributions = this.cashbackContributions.value==='yes'?true:false;
    //     // console.log(this.additional.value,additional)
    //     this.setState({
    //         cashbackContributions: cashbackContributions,
    //         // showResults: false,
    //         changedInput: true,
    //     });
    // }
/*     totalBoost(){
        return(this.cashbackBoost() + this.state.beforeTaxContributionAmt+ this.state.afterTaxContributionAmt)
    } */
    graph1Data(){
        const graph1data = [
            {   name: 'NoExtraContributions', value: Math.round(this.state.resultSuperBalanceNoBoost) },
        ];
        return graph1data;
    }
            
    graph2Data(){
        const graph2data = [
            {   name: 'NoExtraContributions', value: Math.round(this.state.resultSuperBalanceNoBoost) },
            {   name: 'ExtraSuperTotalContributions', value: Math.round(this.state.resultBoostedByAmountTotalBoost)}
        ];
        return graph2data;
    }

    graph3Data(){
        const graph3data = [
            {   name: 'Super Balance At Retirement Age', 
                valueNoBoost: Math.round(this.state.resultSuperBalanceNoBoost),
                valueBoost: Math.round(this.state.resultBoostedByAmountTotalBoost)
            },
            /* {   name: 'ExtraSuperTotalContributions', valueBoost: Math.round(this.state.resultBoostedByAmountTotalBoost)} */
        ];
        return graph3data;
    }
/*             {
                name: 'Super Balance No Boost',
                NoExtraContributions: Math.round(this.state.resultSuperBalanceNoBoost),
                TotalSuperBalance: Math.round(this.resultSuperBalanceBeforeTaxBoostOnly)
            },
            {
                name: 'Super Balance plus Before Tax Contributions',
                NoExtraContributions: Math.round(this.state.resultSuperBalanceNoBoost),
                ExtraSuperBeforeTaxContributions: Math.round(this.state.resultBoostedByAmountBeforeTaxBoostOnly),
                TotalSuperBalance: Math.round(this.resultSuperBalanceBeforeTaxBoostOnly)
            },
            {
                name: 'Super Balance plus After Tax Contributions',
                NoExtraContributions: Math.round(this.state.resultSuperBalanceNoBoost),
                ExtraSuperAfterTaxContributions: Math.round(this.state.resultBoostedByAmountAfterTaxBoostOnly),
                TotalSuperBalance: Math.round(this.resultSuperBalanceAfterTaxBoostOnly)
            },
            {
                name: 'Super Balance plus Cash Back Contributions',
                NoExtraContributions: Math.round(this.state.resultSuperBalanceNoBoost),
                ExtraSuperCashbackContributions: Math.round(this.state.resultBoostedByAmountCashbackBoostOnly),
                TotalSuperBalance: Math.round(this.resultSuperBalanceCashbackBoostOnly)
            }, 
            {
                name: 'Super Balance with ALL contributions',
                NoExtraContributions: Math.round(this.state.resultSuperBalanceNoBoost),
                ExtraSuperTotalContributions: Math.round(this.state.resultBoostedByAmountTotalBoost),
                TotalSuperBalance: Math.round(this.resultSuperBalanceBeforeTaxBoostOnly)
                
            },*/


    totalOtherContributions(){
        //return( this.state.beforeTaxContributionRes + this.state.afterTaxContributionRes )
        return( this.state.beforeTaxContributionRes + this.totalAfterTaxBoost() );
    }

    updateDate(year) {
        let d = new Date();
        let currDate = d.getDate();
        let currMonth = d.getMonth()+1;
        let currYear = d.getFullYear()+year;
        console.log("currDate is: ", currDate);
        console.log("currMonth is: ", currMonth);
        console.log("currYear is: ", currYear);
        //return ((currDate<10) ? '0'+currDate : currDate )+ "/"  + ((currMonth<10) ? '0'+currMonth : currMonth )+ "/" + currYear;
        //console.log("updateDate return TEST curr date is ", (currYear + "-"  + ((currMonth<10) ? '0'+ currMonth : currMonth ) + "-" + ((currDate<10) ? '0'+currDate : currDate)));
        console.log("updateDate return curr date is ", (currYear + "-"  + ((currMonth<10) ? '0'+ currMonth : currMonth ) + "-" + ((currDate<10) ? '0'+currDate : currDate)));
        return (currYear + "-"  + ((currMonth<10) ? '0'+ currMonth : currMonth ) + "-" + ((currDate<10) ? '0'+currDate : currDate)) ;
    }
    
    initiateCalculation(){
        console.log('initiateCalculation triggered with this.state.calculatingResults: ', this.state.calculatingResults);
        this.setState({
                showResults: true,
                calculatingResults: true
            },
            () => { this.calculate() }
        );
    }

    resetEnergy(){
        console.log('resetEnergy triggered');
        const energyInput = document.getElementById("energy-input-frequency");
        if (energyInput) {
            // Add event listener setup here

            energyInput.value = 1;
            energyInput.dispatchEvent(new Event("change", { bubbles: true }));
            //changeSlider();
        }

        //this.energyAmt.value = 0;
        this.setState({
            energyAmt: 0,
            // showResults: false,
            changedInput: true,
        });
    }

    resetCashback(){
        console.log('resetCashback triggered');
        let s = this.state.vData;

        if (Object.keys(s).length === 0) {
            console.log("vData is empty. No action performed.");
            return;
        }

        for (const key in s) {
            if (Object.prototype.hasOwnProperty.call(s, key)) {
                const id = key.replace("Res", "Amt");
                const input = document.getElementById(id);
                if (input) {
                    input.value = 0;
                    input.dispatchEvent(new Event('input', { bubbles: true }));
                    //changeInput(input);
                }
            }
        }

     this.resetEnergy() ;

    }




    calculate(){
        console.log("calculate triggered")
        console.log("this.state.income  : ",this.state.income);
        console.log("this.state.inflation : ",this.state.inflation);
        console.log("this.state.age : ",this.state.age);
        console.log("this.state.gender : ",this.state.gender);
        console.log("this.state.currentSuperBalance : ",this.state.currentSuperBalance);
        console.log("this.state.beforeTaxContributionAmt: ",this.state.beforeTaxContributionAmt);
        //console.log("this.beforeTaxContributionAmt: ",this.beforeTaxContributionAmt);
        //console.log("this.beforeAmt: ",this.beforeAmt);
        
        console.log("this.cashbackBoost() : ",this.cashbackBoost());
        console.log("this.cashbackContributions : ",this.cashbackContributions);
        //console.log("this.calculatingResults is : ", this.calculatingResults);
        console.log("this.state.calculatingResults is : ", this.state.calculatingResults);

        let table = [];
        
        let ageVal = this.state.age;
        //let dates = ['1/1/2021','1/1/2022','1/1/2023','1/1/2024','1/1/2025'];
        let dates = ['2021-01-01T12:00','2022-01-01T12:00','2023-01-01T12:00','2024-01-01T12:00','2025-01-01T12:00'];
        try {
            var age,
                income,
                employerContributionsPercentage,
                ieNoBoostBalance,
                ieBeforeTaxContributionsBalance,
                ieAfterTaxContributionsBalance,
                ieCashbackContributionsBalance,
                iePercentageSpendContributionsBalance,
                ieOnTotalBoostAmount,
                contributionsEmployer,
                contributionsBeforeTax,
                contributionsAfterTax,
                contributionsCashback,
                contributionsPercentSpend,
                balanceNoBoost,
                balanceBeforeTaxBoostOnly,
                balanceAfterTaxBoostOnly,
                balanceCashbackBoostOnly,
                balancePercentSpendContributionsOnly,
                balanceTotalBoost,
                //amountNoBoost,
                boostAmountBeforeTaxBoostOnly,
                boostAmountAfterTaxBoostOnly,
                boostAmountCashbackBoostOnly,
                boostAmountPercentageSpendOnly,
                boostAmountTotalBoost,
                incomePrev,
                employerContributionsPercentagePrev,
                indexPrev,
                balanceNoBoostPrev,
                balanceBeforeTaxBoostOnlyPrev,
                balanceAfterTaxBoostOnlyPrev,
                balanceCashbackBoostOnlyPrev,
                balancePercentSpendContributionsOnlyPrev,
                balanceTotalBoostPrev;
            
            for(let i=0;i<=50;i++){
                let row = [];
                let date = this.updateDate(i);
                if(i===0){
                    age = ageVal;
                    income = this.state.income*(Math.pow(1+Number(this.state.inflation/100),i));
                    employerContributionsPercentage = (new Date(date)<new Date(dates[0]) ? 
                                                                                0.095 : 
                                                    (new Date(date)<new Date(dates[1]) ? 
                                                                                    0.1 :
                                                    (new Date(date)<new Date(dates[2]) ?
                                                                                0.105 :
                                                    (new Date(date)<new Date(dates[3]) ?
                                                                                0.11  :
                                                    (new Date(date)<new Date(dates[4]) ?
                                                                                0.115  : 0.12)))));
                    console.log("employerContributionsPercentage calculated by date is: ", employerContributionsPercentage);
                    ieNoBoostBalance = ''; // the old ienb
                    ieBeforeTaxContributionsBalance = '';
                    ieAfterTaxContributionsBalance = '';
                    ieCashbackContributionsBalance = '';
                    iePercentageSpendContributionsBalance = '';
                    ieOnTotalBoostAmount = '';

                    contributionsEmployer = '';
                    contributionsBeforeTax = '';
                    contributionsAfterTax = '';
                    contributionsCashback = '';
                    contributionsPercentSpend = '';

                    balanceNoBoost = Number(this.state.currentSuperBalance);
                    balanceBeforeTaxBoostOnly = Number(this.state.currentSuperBalance);
                    balanceAfterTaxBoostOnly = Number(this.state.currentSuperBalance);
                    balanceCashbackBoostOnly = Number(this.state.currentSuperBalance);
                    balancePercentSpendContributionsOnly = Number(this.state.currentSuperBalance);
                    balanceTotalBoost = Number(this.state.currentSuperBalance);

                    boostAmountBeforeTaxBoostOnly = balanceBeforeTaxBoostOnly - balanceNoBoost;
                    boostAmountAfterTaxBoostOnly = balanceAfterTaxBoostOnly - balanceNoBoost;
                    boostAmountCashbackBoostOnly = balanceCashbackBoostOnly - balanceNoBoost;
                    boostAmountPercentageSpendOnly = balancePercentSpendContributionsOnly - balanceNoBoost;
                    boostAmountTotalBoost = balanceTotalBoost - balanceNoBoost;

                }else{
                age++;
                income = this.state.income*(Math.pow(1+Number(this.state.inflation/100),i));

                console.log("current date is : ", date);
                console.log("current new Date(date) is ", new Date(date));
                console.log("current new Date() is ", new Date());
                console.log(" dates[0] is : ", dates[0]);
                console.log(["key new Date(dates[0]) is: ", new Date(dates[0])]);
                console.log(["key new Date(dates[1]) is: ", new Date(dates[1])]);
                console.log(["key new Date(dates[2]) is: ", new Date(dates[2])]);
                console.log(["key new Date(dates[3]) is: ", new Date(dates[3])]);
                console.log(["key new Date(dates[4]) is: ",new  Date(dates[4])]);

                employerContributionsPercentage = (new Date(date)<new Date(dates[0]) ? 
                                                                                0.095 : 
                                                    (new Date(date)<new Date(dates[1]) ? 
                                                                                    0.1 :
                                                    (new Date(date)<new Date(dates[2]) ?
                                                                                0.105 :
                                                    (new Date(date)<new Date(dates[3]) ?
                                                                                0.11  :
                                                    (new Date(date)<new Date(dates[4]) ?
                                                                                0.115  : 0.12)))));

                console.log("employerContributionsPercentage calculated by date is: ", employerContributionsPercentage);

                ieNoBoostBalance = balanceNoBoostPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate); // [5]
                ieBeforeTaxContributionsBalance = balanceBeforeTaxBoostOnlyPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate);
                ieAfterTaxContributionsBalance = balanceAfterTaxBoostOnlyPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate);
                console.log("**balanceCashbackBoostOnlyPrev : ",balanceCashbackBoostOnlyPrev);
                console.log("**Number(this.state.investmentReturn/100) : ", Number(this.state.investmentReturn/100));
                console.log("**Number(1-this.state.superEarningsTaxRate) : ", Number(1-this.state.superEarningsTaxRate));
                ieCashbackContributionsBalance = balanceCashbackBoostOnlyPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate); // [8]
                console.log("** multiply above 3 numbers should get ieCashbackContributionsBalance : ",ieCashbackContributionsBalance);
                iePercentageSpendContributionsBalance = balancePercentSpendContributionsOnlyPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate);
                ieOnTotalBoostAmount = balanceTotalBoostPrev*Number(this.state.investmentReturn/100)*Number(1-this.state.superEarningsTaxRate);

                contributionsEmployer = (incomePrev*employerContributionsPercentagePrev)*Number(1-this.state.contributionsTaxRate); //[11]
                contributionsBeforeTax = (Number(this.state.beforeTaxContributionRes)*Math.pow(1+Number(this.state.inflation/100),indexPrev))*Number(1-this.state.contributionsTaxRate);
                //contributionsAfterTax = (Number(this.state.afterTaxContributionRes)*Math.pow(1+Number(this.state.inflation/100),indexPrev));
                contributionsAfterTax = (Number(this.totalAfterTaxBoost())*Math.pow(1+Number(this.state.inflation/100),indexPrev));
                contributionsCashback = (Number(this.cashbackBoost()))*Math.pow(1+Number(this.state.inflation/100),indexPrev); // [14]
                contributionsPercentSpend = '0'; //have to add a percentageSpendAmount // [15]

                
                
                console.log ("**** ieNoBoostBalance : ", ieNoBoostBalance);
                console.log ("**** contributionsEmployer : ", contributionsEmployer);
                console.log ("**** Number(this.state.feesPercentage/100) : ", Number(this.state.feesPercentage/100));

                console.log ("**** 1stNumber balanceNoBoostPrev : ", balanceNoBoostPrev);
                console.log ("**** 2ndNumber to minus : ", ((Number(balanceNoBoostPrev)+ieNoBoostBalance+contributionsEmployer)*Number(this.state.feesPercentage/100)));
                console.log ("**** 3rdNumber to minus : ", (Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i)));

                balanceNoBoost = (balanceNoBoostPrev + ieNoBoostBalance + contributionsEmployer )
                                -(balanceNoBoostPrev + ieNoBoostBalance + contributionsEmployer ) * Number(this.state.feesPercentage/100)
                                -(Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i));

                balanceBeforeTaxBoostOnly = (balanceBeforeTaxBoostOnlyPrev + contributionsBeforeTax + contributionsEmployer + ieBeforeTaxContributionsBalance) 
                                            -(balanceBeforeTaxBoostOnlyPrev + contributionsBeforeTax + contributionsEmployer + ieBeforeTaxContributionsBalance) * Number(this.state.feesPercentage/100)
                                            -(Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i));
                
                    balanceAfterTaxBoostOnly = (balanceAfterTaxBoostOnlyPrev + contributionsAfterTax + contributionsEmployer + ieAfterTaxContributionsBalance)
                                            - (balanceAfterTaxBoostOnlyPrev + contributionsAfterTax + contributionsEmployer + ieAfterTaxContributionsBalance) * Number(this.state.feesPercentage/100)
                                            - (Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i));
                    
                    balanceCashbackBoostOnly = (balanceCashbackBoostOnlyPrev + contributionsCashback + contributionsEmployer + ieCashbackContributionsBalance)
                                            - (balanceCashbackBoostOnlyPrev + contributionsCashback + contributionsEmployer + ieCashbackContributionsBalance) * Number(this.state.feesPercentage/100)
                                            - (Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i));

                console.log("CHECK CALCULATING balanceBeforeTaxBoostOnly..");
                console.log("CHECK balanceBeforeTaxBoostOnlyPrev : ", balanceBeforeTaxBoostOnlyPrev);
                console.log("CHECK contributionsBeforeTax : ", contributionsBeforeTax);
                console.log("CHECK contributionsEmployer : ",contributionsEmployer);
                console.log("CHECK ieBeforeTaxContributionsBalance : ", ieBeforeTaxContributionsBalance);
                console.log("CHECK balanceBeforeTaxBoostOnly : ", balanceBeforeTaxBoostOnly);
                console.log("CHECK Number(this.state.beforeTaxContributionAmt) : ", Number(this.state.beforeTaxContributionAmt));
                console.log("CHECK this.state.beforeTaxContributionAmt : ", this.state.beforeTaxContributionAmt);
                console.log("CHECK this.beforeTaxContributionAmt : ", this.beforeTaxContributionAmt);

                balancePercentSpendContributionsOnly = '0'; //[20]
                console.log("*** balanceTotalBoostPrev : ",balanceTotalBoostPrev);
                console.log("*** contributionsBeforeTax : ",contributionsBeforeTax);
                console.log("*** contributionsAfterTax : ",contributionsAfterTax);
                console.log("*** contributionsEmployer : ",contributionsEmployer);
                console.log("*** contributionsCashback : ",contributionsCashback);
                console.log("*** ieOnTotalBoostAmount : ",ieOnTotalBoostAmount);
                
                console.log("middle of calculation this.state.calculatingResults is : ", this.state.calculatingResults);

                balanceTotalBoost = (balanceTotalBoostPrev + contributionsBeforeTax + contributionsAfterTax + contributionsEmployer + contributionsCashback + ieOnTotalBoostAmount)
                                    -(balanceTotalBoostPrev + contributionsBeforeTax + contributionsAfterTax + contributionsEmployer + contributionsCashback + ieOnTotalBoostAmount) * Number(this.state.feesPercentage/100)
                                    -(Number(this.state.feesAmount)*Math.pow(1+Number(this.state.inflation/100),i));


                boostAmountBeforeTaxBoostOnly = balanceBeforeTaxBoostOnly - balanceNoBoost; //[22]
                boostAmountAfterTaxBoostOnly = balanceAfterTaxBoostOnly - balanceNoBoost; //[23]
                boostAmountCashbackBoostOnly = balanceCashbackBoostOnly - balanceNoBoost; //[24]
                boostAmountPercentageSpendOnly = balancePercentSpendContributionsOnly - balanceNoBoost;  //[25]
                boostAmountTotalBoost = balanceTotalBoost - balanceNoBoost; //[26]
                
                }

                incomePrev = income;
                employerContributionsPercentagePrev = employerContributionsPercentage;
                indexPrev = i;
                balanceNoBoostPrev = balanceNoBoost;
                balanceBeforeTaxBoostOnlyPrev = balanceBeforeTaxBoostOnly;
                balanceAfterTaxBoostOnlyPrev = balanceAfterTaxBoostOnly;
                balanceCashbackBoostOnlyPrev = balanceCashbackBoostOnly;
                balancePercentSpendContributionsOnlyPrev = '0';
                balanceTotalBoostPrev = balanceTotalBoost;

                row.push(i,age,income,date,employerContributionsPercentage,ieNoBoostBalance,ieBeforeTaxContributionsBalance,ieAfterTaxContributionsBalance,ieCashbackContributionsBalance,iePercentageSpendContributionsBalance,ieOnTotalBoostAmount,contributionsEmployer,contributionsBeforeTax,contributionsAfterTax,contributionsCashback,contributionsPercentSpend,balanceNoBoost,balanceBeforeTaxBoostOnly,balanceAfterTaxBoostOnly,balanceCashbackBoostOnly,balancePercentSpendContributionsOnly,balanceTotalBoost,boostAmountBeforeTaxBoostOnly,boostAmountAfterTaxBoostOnly,boostAmountCashbackBoostOnly,boostAmountPercentageSpendOnly,boostAmountTotalBoost);
                console.log([i,age,income,date,employerContributionsPercentage,ieNoBoostBalance,ieBeforeTaxContributionsBalance,ieAfterTaxContributionsBalance,ieCashbackContributionsBalance,iePercentageSpendContributionsBalance,ieOnTotalBoostAmount,contributionsEmployer,contributionsBeforeTax,contributionsAfterTax,contributionsCashback,contributionsPercentSpend,balanceNoBoost,balanceBeforeTaxBoostOnly,balanceAfterTaxBoostOnly,balanceCashbackBoostOnly,balancePercentSpendContributionsOnly,balanceTotalBoost,boostAmountBeforeTaxBoostOnly,boostAmountAfterTaxBoostOnly,boostAmountCashbackBoostOnly,boostAmountPercentageSpendOnly,boostAmountTotalBoost]);

                table.push(row);

                if(Number(age)===Number(this.state.desiredRetirementAge)){
                    this.setState({
                        resultSuperBalanceNoBoost:row[16],
                        resultSuperBalanceBeforeTaxBoostOnly:row[17],
                        resultSuperBalanceAfterTaxBoostOnly:row[18],
                        resultSuperBalanceCashbackBoostOnly:row[19],
                        resultSuperBalancePercentageSpendBoostOnly:row[20],
                        resultSuperBalanceTotalBoost:row[21],
                        resultBoostedByAmountBeforeTaxBoostOnly:row[22],
                        resultBoostedByAmountAfterTaxBoostOnly:row[23],
                        resultBoostedByAmountCashbackBoostOnly:row[24],
                        resultBoostedByAmountPercentageSpendOnly:row[25],
                        resultBoostedByAmountTotalBoost:row[26],
                    });
                }
            }

        } catch (exception) {
            log({
                event: "Calculator-Error",
                source: "Calculate function",
                info: exception,
                ...exception,
            })

        } finally {
            console.log('finally lets set calculatingResults to false this.state is currently : ', this.state)
            this.setState({
                showResults: true,
                changedInput: false,
                firstLoad: false,
                calculatingResults: false
             });
            console.log("final table ", table,this.state)
        }
        
    }
    
    addCommas(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1,$2');
        }
        return x1 + x2;
    }
    
    render(){
        const {
            age,
            gender,
            desiredRetirementAge,
            income,
            currentSuperBalance,
            investmentReturn,
            inflation,
            feesAmount,
            feesPercentage,
            beforeTaxContributionAmt,
            beforeTaxContributionFrq,
            beforeTaxContributionRes,
            // afterTaxContributionAmt,
            // afterTaxContributionFrq,
            // afterTaxContributionRes,
            roundUpsAfterTaxContributionAmt,
            roundUpsAfterTaxContributionFrq,
            roundUpsAfterTaxContributionRes,
            personalAfterTaxContributionAmt,
            personalAfterTaxContributionFrq,
            personalAfterTaxContributionRes,
            otherAfterTaxContributionAmt,
            otherAfterTaxContributionFrq,
            otherAfterTaxContributionRes,
            resultBoostedByAmountTotalBoost,
        } = this.state;

        const { user } = this.props.authentication; //can be removed after roundups is live across platform

        
        console.log('user in render is: ', user);
        

        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
            const x = cx > 250 ? cx + radius * Math.cos(-midAngle * (Math.PI/180)) : cx;
            const y = cx > 250 ? cy + radius * Math.sin(-midAngle * (Math.PI/180)) : cy;
          
                return (
                    index > 0 ?

                            <text x={x} y={y} fill="black" fontSize="15" fontWeight="bold" textAnchor={ x == cx ? 'middle' : x > cx ? 'start' : 'end'} dominantBaseline="central">
                                {'Extra $' + this.addCommas(Math.round(resultBoostedByAmountTotalBoost))}
                            </text>

                    : null
                );
          
        };

        const renderCustomizedBarLabel = ( props ) => {
            const { x, y, width, height, value } = props;
            console.log ('renderCustomizedBarLabel props is: ', props);
            return (
                <text x={x+width / 2} y={y} fill="black" fontSize="15" fontWeight="bold" textAnchor="middle" >
                    {'$' + this.addCommas(Math.round(value))}
                </text>
            );
        }
        const moreInfoResultsExplanationWithBoost = (
            <div className="more-info-results-explanation-container" style={{
                maxWidth: '460px', width: 'calc(100% - 20px)', padding: '10px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
              }}>
                <text> Calculated with contributions & fees:</text>
                    <ul>
                        <li><b>Shopping Cashback*:</b> 
                            {   this.cashbackBoost() > 0
                                ? <span className="bsc-highlight">{'$' + this.addCommas((this.cashbackBoost()).toFixed(2)) + '  pa'}</span>
                                : '$' + this.addCommas((this.cashbackBoost()).toFixed(2)) + '  pa'
                            }
                        </li>
                        <li><b>After Tax:</b> 
                            {   this.totalAfterTaxBoost() > 0
                                ? <span className="bsc-highlight">{'$' + (this.totalAfterTaxBoost()).toFixed(2) + '  pa'}</span>
                                : '$' + (this.totalAfterTaxBoost()).toFixed(2) + '  pa'
                            }
                        </li>
                        <li><b>Before Tax:</b> 
                            {   this.state.beforeTaxContributionRes > 0
                                ? <span className="bsc-highlight">{'$' + (this.state.beforeTaxContributionRes).toFixed(2) + '  pa'}</span>
                                : '$' + (this.state.beforeTaxContributionRes).toFixed(2) + '  pa'
                            }
                        </li>
                        <li><b>Fees amount:</b>
                            {   this.state.feesAmount > 0
                                ? <span className="bsc-highlight">{'$' + (this.state.feesAmount).toFixed(2) + '  pa'}</span>
                                : '$' + (this.state.feesAmount).toFixed(2) + '  pa'
                            }
                        </li>
                        <li><b>Fees %age:</b>
                            {   this.state.feesPercentage > 0
                                ? <span className="bsc-highlight">{(this.state.feesPercentage).toFixed(2) + '  %'}</span>
                                : (this.state.feesPercentage).toFixed(2) + '  %'
                            }
                        </li>
                    </ul>
                <text>You can adjust the amounts in the calculator inputs.</text>
                <text style={{ fontSize: 'small'}}><i>*Shopping Cashback is an After Tax Contribution but is displayed separately for ease of comparisson.</i></text>
            </div>
        );

        const moreInfoResultsExplanationNoBoost = (
            <div className="more-info-results-explanation-container" style={{
                maxWidth: '460px', width: 'calc(100% - 20px)', padding: '10px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
              }}>
                <text> Calculated using employer contributions and the following fees:</text>
                    <ul>
                        <li><b>Fees amount:</b>
                            {   this.state.feesAmount > 0
                                ? <span className="bsc-highlight">{'$' + (this.state.feesAmount).toFixed(2) + '  pa'}</span>
                                : '$' + (this.state.feesAmount).toFixed(2) + '  pa'
                            }
                        </li>
                        <li><b>Fees %age:</b>
                            {   this.state.feesPercentage > 0
                                ? <span className="bsc-highlight">{(this.state.feesPercentage).toFixed(2) + '  %'}</span>
                                : (this.state.feesPercentage).toFixed(2) + '  %'
                            }
                        </li>
                    </ul>
                <text>You can adjust fees in the calculator inputs.</text>
                
                <text style={{ fontSize: 'small'}}><i>Results take into account incremental increases of employer contributions which are rising to 12% on 1 Jul 2025.</i></text>
            </div>
        ); 

        return(
            <CalculatorWrapper>
                <div className="bsc-wrapper">
                    <div className="bsc-container">
                        <h2 className="bsc-heading">Boost Your Super Calculator</h2>
                        <h3 className="bsc-sub-heading">See how different contributions can affect super</h3>
                        <div className="bsc-info-text">This is a model not a prediction. It is designed  to show the effect  of using Boost Your Super and is based  on assumption and estimation. Please see the disclaimers assumptions and limitations below.</div>
                    
                        <div className="bsc-section">

                            <div className="bsc-section bsc-section-3">
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Age <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Min 18, max 75</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                    <div className="bsc-input prepend-input-group">
                                        <span> </span>
                                            <input type="number" id="age" name="age" value={age} onChange={this.changeInputBasic}/></div>
                                        <span> </span>
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Gender <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">You can add your gender</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        <div className="bsc-select ml0 prepend-input-group">
                                            <span> </span>
                                            <select id="gender" name="gender" value={gender} onChange={this.changeInputBasic} >
                                                <option value="Not specified">Not specified</option>
                                                <option value="Female">Female</option> 
                                                <option value="Male">Male</option> 
                                                <option value="Non binary">Non binary</option> 
                                                <option value="Not telling">Not telling</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Desired retirement age <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Min 60, max 75</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        <div className="bsc-select ml0 prepend-input-group">
                                            <span> </span>
                                            <select id="retirementAge" name="desiredRetirementAge" value={desiredRetirementAge} onChange={this.changeInputBasic} >
                                                <option value="60">60</option> 
                                                <option value="61">61</option> 
                                                <option value="62">62</option> 
                                                <option value="63">63</option> 
                                                <option value="64">64</option> 
                                                <option value="65">65</option> 
                                                <option value="66">66</option> 
                                                <option value="67">67</option> 
                                                <option value="68">68</option> 
                                                <option value="69">69</option> 
                                                <option value="70">70</option> 
                                                <option value="71">71</option> 
                                                <option value="72">72</option> 
                                                <option value="73">73</option> 
                                                <option value="74">74</option> 
                                                <option value="75">75</option> 
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Income <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">$p.a. before tax and super</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        
                                        <div className="bsc-input prepend-input-group">
                                            <span>$</span>
                                            <input
                                                name="income"
                                                type="number"
                                                id="income"
                                                value={income}
                                                onChange={this.changeInputBasic}
                                                label="Income2"
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Current super balance <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This is used to calculate the compounding effect it has on additional contributions from Boost Your Super. According to ABS the 2017-2018 mean balances for 55-64 age range were $332,700 for males and $183,000 for females.</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        <div className="bsc-input prepend-input-group">
                                            <span>$</span>
                                            <input 
                                                type="number"
                                                name="currentSuperBalance"
                                                id="super"
                                                value={currentSuperBalance}
                                                onChange={this.changeInputBasic}
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">PA Investment return <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">You can use the following as a guide: Cash = 2.7%, Conservative=3.8%, Moderate = 4.4%, Balanced = 4.8%, Growth = 5%, High Growth = 5.3%</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        <div className="bsc-input prepend-input-group">
                                            <span>%</span>
                                            <input type="number"
                                                id="investmentReturn"
                                                name="investmentReturn"
                                                value={investmentReturn}
                                                //defaultValue="5"
                                                onChange={this.changeInputBasic}
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="bsc-section-row">
                                    <div className="bsc-label">Inflation <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">According to ABS the average inflation rate for 25 years 1991-2018 is 2.5%.</div></span></span></div>
                                    <div className="bsc-entry justify-center">
                                        <div className="bsc-input prepend-input-group">
                                            <span>%</span>
                                            <input type="number"
                                                id="inflation"
                                                name="inflation"
                                                value={inflation}
                                                onChange={this.changeInputBasic}/>
                                        </div>
                                        
                                    </div>
                                </div>

{/* **************************************** */}
                                {/* <div className="bsc-section-row"> */}

                                    {/* <div className="bsc-label">TestOtherContributions <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Testing Testing</div></span></span></div> */}
                                    {/* <div className="bsc-label"></div> */}

                                    {/* <div className="bsc-entry justify-center"> */}

                                            <div className="bsc-more-contributions-section">
                                                <Accordion 
                                                    header={ 
                                                                (this.totalOtherContributions()) == 0 ? 
                                                                (
                                                                    <div className="bsc-more-contributions-header">
                                                                        Include additional contributions eg &nbsp;<span className="bsc-highlight" >Round Ups</span> &nbsp;?
                                                                        <div className="circle" style={{ background: `linear-gradient( #96e996 , ${this.props.app.partnerContent.alternate_accent_color})`, }}>
                                                                            <div className="circle-content">
                                                                            <span className="pop-over-container" style={{ padding: '0px' }}>
                                                                                    <PopoverHover
                                                                                    content={
                                                                                        <div style={{
                                                                                            maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                                                                                        }}
                                                                                        >
                                                                                            <p><strong>SuperCalcBonusBoost5%</strong> is available by adding a Round Ups After Tax contribution before you calculate.</p>
                                                                                            { !user && <p>You will need to be logged in to earn this Bonus Boost</p> }
                                                                                        </div>
                                                                                    }
                                                                                    header = {'EARN  BONUS BOOST HERE'}
                                                                                    img = {null}
                                                                                    itemToPopover = {
                                                                                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', color: 'black', fontSize:'22px', }} >info</i> )
                                                                                    }
                                                                                    anchorOrigin={{
                                                                                        vertical: 'center',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'center',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div> 
                                                                ) : (
                                                                    <div className="bsc-more-contributions-header">
                                                                        Included other additional contributions: ${this.addCommas((this.totalOtherContributions()).toFixed(2))} pa
                                                                    </div>
                                                                )
                                                            }

                                                    headerLeftPadding="33%"

                                                    content={  
                                                                <div className="bsc-section bsc-section-2">
                                                                        <div>  {/* //huh what dis div doin ere? maybe a spaca? */}

                                                                        <div className="bsc-content-heading">Before Tax contributions</div>

                                                                        <div className="bsc-section-row">
                                                                            <div className="bsc-label">Before Tax contributions<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Salary Sacrifice</div></span></span></div>
                                                                            <div className="bsc-entry">
                                                                                <div className="bsc-input prepend-input-group">
                                                                                    <span>$</span>
                                                                                    <input 
                                                                                        type="number" 
                                                                                        id="beforeTaxContributionAmt"
                                                                                        name="beforeTaxContributionAmt"                                                                     onChange={this.changeInputBasic} 
                                                                                        value={ beforeTaxContributionAmt }
                                                                                    />
                                                                                </div>
                                                                                <div className="bsc-select">
                                                                                    <select name="beforeTaxContributionFrq" id="beforeTaxContributionFrq" value={ beforeTaxContributionFrq } onChange={this.changeInputBasic}>
                                                                                        <option value="D">Daily</option> 
                                                                                        <option value="W">Weekly</option> 
                                                                                        <option value="F">Fortnightly</option> 
                                                                                        <option value="M">Monthly</option> 
                                                                                        <option value="B">Twice/Year</option> 
                                                                                        <option value="A">Annually</option> 
                                                                                        <option value="E2Y">Every 2 Yrs</option> :
                                                                                        <option value="E4Y">Every 4 Yrs</option> 
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bsc-result"><span className="bsc-res">{'$' + (beforeTaxContributionRes).toFixed(2) + '  pa'}</span></div>
                                                                        </div>

                                                                        <div className="bsc-content-heading">After Tax contributions</div>
                                                                        <div className="bsc-section-row">
                                                                            <div className="bsc-label"><span className="bsc-highlight" >Round Ups*</span><span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Round Ups are coming soon to Boost Your Super. The average Round Ups user raises $69.71 per month.</div></span></span></div>
                                                                            <div className="bsc-entry">
                                                                                <div className="bsc-input prepend-input-group">
                                                                                    <span>$</span>
                                                                                    <input 
                                                                                        type="number"
                                                                                        id="roundUpsAfterTaxContributionAmt"
                                                                                        name="roundUpsAfterTaxContributionAmt"
                                                                                        onChange={this.changeInputBasic} 
                                                                                        value={ roundUpsAfterTaxContributionAmt }
                                                                                    />
                                                                                </div>
                                                                                <div className="bsc-select">
                                                                                    <select 
                                                                                        name="roundUpsAfterTaxContributionFrq"
                                                                                        id="roundUpsAfterTaxContributionFrq"
                                                                                        value={ roundUpsAfterTaxContributionFrq } 
                                                                                        onChange={this.changeInputBasic}>
                                                                                            <option value="D">Daily</option> 
                                                                                            <option value="W">Weekly</option> 
                                                                                            <option value="F">Fortnightly</option> 
                                                                                            <option value="M" >Monthly</option> 
                                                                                            <option value="B">Twice/Year</option> 
                                                                                            <option value="A">Annually</option> 
                                                                                            <option value="E2Y">Every 2 Yrs</option> 
                                                                                            <option value="E3Y">Every 3 Yrs</option> 
                                                                                            <option value="E4Y">Every 4 Yrs</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bsc-result">
                                                                                <span className="bsc-res">{'$' + (roundUpsAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="bsc-section-row">
                                                                            <div className="bsc-label">Personal<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This could include tax returns or regular personal contributions, etc</div></span></span></div>
                                                                            <div className="bsc-entry">
                                                                                <div className="bsc-input prepend-input-group">
                                                                                    <span>$</span>
                                                                                    <input 
                                                                                        type="number"
                                                                                        id="personalAfterTaxContributionAmt"
                                                                                        name="personalAfterTaxContributionAmt"
                                                                                        onChange={this.changeInputBasic} 
                                                                                        value={ personalAfterTaxContributionAmt }
                                                                                    />
                                                                                </div>
                                                                                <div className="bsc-select">
                                                                                    <select 
                                                                                        name="personalAfterTaxContributionFrq"
                                                                                        id="personalAfterTaxContributionFrq"
                                                                                        value={ personalAfterTaxContributionFrq } 
                                                                                        onChange={this.changeInputBasic}>
                                                                                            <option value="D">Daily</option> 
                                                                                            <option value="W">Weekly</option> 
                                                                                            <option value="F">Fortnightly</option> 
                                                                                            <option value="M" >Monthly</option> 
                                                                                            <option value="B">Twice/Year</option> 
                                                                                            <option value="A">Annually</option> 
                                                                                            <option value="E2Y">Every 2 Yrs</option> 
                                                                                            <option value="E3Y">Every 3 Yrs</option> 
                                                                                            <option value="E4Y">Every 4 Yrs</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bsc-result">
                                                                                <span className="bsc-res">{'$' + (personalAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
                                                                            </div>
                                                                        </div>


                                                                        <div className="bsc-section-row">
                                                                            <div className="bsc-label">Other<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This could include govt co-contributions spouse contributions etc</div></span></span></div>
                                                                            <div className="bsc-entry">
                                                                                <div className="bsc-input prepend-input-group">
                                                                                    <span>$</span>
                                                                                    <input 
                                                                                        type="number"
                                                                                        id="otherAfterTaxContributionAmt"
                                                                                        name="otherAfterTaxContributionAmt"
                                                                                        onChange={this.changeInputBasic} 
                                                                                        value={ otherAfterTaxContributionAmt }
                                                                                    />
                                                                                </div>
                                                                                <div className="bsc-select">
                                                                                    <select 
                                                                                        name="otherAfterTaxContributionFrq"
                                                                                        id="otherAfterTaxContributionFrq"
                                                                                        value={ otherAfterTaxContributionFrq } 
                                                                                        onChange={this.changeInputBasic}>
                                                                                            <option value="D">Daily</option> 
                                                                                            <option value="W">Weekly</option> 
                                                                                            <option value="F">Fortnightly</option> 
                                                                                            <option value="M" >Monthly</option> 
                                                                                            <option value="B">Twice/Year</option> 
                                                                                            <option value="A">Annually</option> 
                                                                                            <option value="E2Y">Every 2 Yrs</option> 
                                                                                            <option value="E3Y">Every 3 Yrs</option> 
                                                                                            <option value="E4Y">Every 4 Yrs</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bsc-result">
                                                                                <span className="bsc-res">{'$' + (otherAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="bsc-section-row">
                                                                            <div className="bsc-label bsc-center-res-label">Total After Tax contributions PA:</div>
                                                                            <div className="bsc-result bsc-center-res-text">{'$'+this.addCommas((this.totalAfterTaxBoost()).toFixed(2))}</div>
                                                                        </div>


                                                                        </div>
                                                                    </div>
                                                            }/>
                                                </div> 
                                    {/* </div> */}
                                   {/*  </div> */}
                               

{/* ******************************************************************************* */}


                                
                            </div>
                        {this.state.showResults &&
                            <div className="bsc-results-container">

                                { this.state.calculatingResults  ?
                                     <div className="loading-container">
                                        <span>Calculating...</span>
                                        <BarLoader
                                            sizeUnit={"px"}
                                            size={400}
                                            color="#000"
                                            loading={true}
                                        />
                                    </div>
                                
                                    : ( 


                                        <><div className="bsc-section">
                                                    {/* add the required ones to report below.
                                                        resultSuperBalanceBeforeTaxBoostOnly:row[17],
                                                        resultSuperBalanceAfterTaxBoostOnly:row[18],
                                                        resultSuperBalanceCashbackBoostOnly:row[19],
                                                        resultSuperBalancePercentageSpendBoostOnly:row[20],
                                                        resultSuperBalanceTotalBoost:row[21],
                                                        resultBoostedByAmountBeforeTaxBoostOnly:row[22],
                                                        resultBoostedByAmountAfterTaxBoostOnly:row[23],
                                                        resultBoostedByAmountCashbackBoostOnly:row[24],
                                                        resultBoostedByAmountPercentageSpendOnly:row[25],
                                                        resultBoostedByAmountTotalBoost:row[26], */}
                                                 <div className="bsc-graphs-container">

                                                    
                                                      
                                                    {/* <div className="bsc-graph-and-label-container">
                                                        <div className="bsc-graph">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <BarChart
                                                                    width={500}
                                                                    height={300}
                                                                    data={this.graph3Data()}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                >
                                                                    
                                                                    <XAxis dataKey="name" />
                                                                    <YAxis type="number" domain={[0, 'dataMax + 500000']} hide={true} />
                                                                    
                                                                    <Legend chartWidth={0}/>
                                                                    <Bar 
                                                                        dataKey="valueNoBoost"
                                                                        stackId="a"
                                                                         label={renderCustomizedBarLabel} 
                                                                        legendType={false}
                                                                        maxBarSize={100}
                                                                        activeBar={false}
                                                                        fill="#484848"
                                                                        >
                                                                        <LabelList dataKey="valueNoBoost" content={renderCustomizedBarLabel} /> 
                                                                     </Bar>
                                                                    <Bar 
                                                                        dataKey="valueBoost"
                                                                        stackId="a"
                                                                        label={true}
                                                                        legendType={false}
                                                                        maxBarSize={100}
                                                                        activeBar={false}
                                                                        fill={this.props.app.partnerContent.alternate_accent_color}
                                                                        >
                                                                            <LabelList dataKey="valueNoBoost" position="insideTop" angle="45" /> 
                                                                    </Bar>
                                                                    

                                                                </BarChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                        <div className="bsc-graph-result-label-container">
                                                            <div className="bsc-graph-result-label">
                                                                Balance no boost
                                                            </div>
                                                            <div className="bsc-graph-result-text-amount"> 
                                                                { this.state.calculatingResults ? 
                                                                    ( 
                                                                    <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                                                    ) : (
                                                                    '$' + this.addCommas(Math.round(this.state.resultSuperBalanceNoBoost))
                                                                    )
                                                                }
                                                                <div className="pop-over-container">
                                                                    <PopoverHover
                                                                        content={ moreInfoResultsExplanationNoBoost }
                                                                        header = {null}
                                                                        img = {null}
                                                                        itemToPopover = {
                                                                        ( <i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i> ) 
                                                                        }
                                                                        horizontalPosn = "right"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="bsc-graph-and-label-container">
                                                        <div className="bsc-graph">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <PieChart width={400} height={400}>
                                                                <Pie
                                                                    data={this.graph1Data()}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    isAnimationActive={false}
                                                                    label={false}
                                                                    innerRadius={60}
                                                                    outerRadius={120}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {this.graph1Data().map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={this.state.graphColors[index % this.state.graphColors.length]} />
                                                                    ))}
                                                                </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                        <div className="bsc-graph-result-label-container">
                                                            <div className="bsc-graph-result-label">
                                                                Balance no boost
                                                            </div>
                                                            <div className="bsc-graph-result-text-amount"> 
                                                                { this.state.calculatingResults ? 
                                                                    ( 
                                                                    <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                                                    ) : (
                                                                    '$' + this.addCommas(Math.round(this.state.resultSuperBalanceNoBoost))
                                                                    )
                                                                }
{/*                                                                 <div className="pop-over-container">
                                                                    <PopoverHover
                                                                        content={ moreInfoResultsExplanationNoBoost }
                                                                        header = {null}
                                                                        img = {null}
                                                                        itemToPopover = {
                                                                        ( <i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i> ) 
                                                                        }
                                                                        horizontalPosn = "right"
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="bsc-graph-more-info">
                                                            {moreInfoResultsExplanationNoBoost}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="bsc-graph-and-label-container">
                                                        <div className="bsc-graph">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <PieChart width={400} height={400}>
                                                                <Pie
                                                                    data={this.graph2Data()}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    isAnimationActive={false}
                                                                    label={renderCustomizedLabel}
                                                                    innerRadius={60}
                                                                    outerRadius={120}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {this.graph2Data().map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={this.state.graphColors[index % this.state.graphColors.length]} />
                                                                    ))}
                                                                </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                        <div className="bsc-graph-result-label-container">
                                                            <div className="bsc-graph-result-label">
                                                                Balance boosted
                                                            </div>
                                                            <div className="bsc-graph-result-text-amount">
                                                                { this.state.calculatingResults ? 
                                                                    ( <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                                                    ) : (
                                                                    '$' + this.addCommas(Math.round(this.state.resultSuperBalanceTotalBoost))
                                                                    )
                                                                }
                                                                {/* <div className="pop-over-container">
                                                                    <PopoverHover
                                                                        content={ 
                                                                            moreInfoResultsExplanationWithBoost
                                                                        }
                                                                        header = {null}
                                                                        img = {null}
                                                                        itemToPopover = {
                                                                        ( <i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i> ) 
                                                                        }
                                                                        horizontalPosn = "right"
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="bsc-graph-more-info">
                                                            {moreInfoResultsExplanationWithBoost}
                                                        </div>
                                                    </div>
                                                </div>   
                                                </div>
                                                {/* <div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Balance no Xtra Contributions: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultSuperBalanceNoBoost))}</div>
                                                        </div>
                                                    </div>
                                                    <div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Balance with Cashback contributions only: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultSuperBalanceCashbackBoostOnly))}</div>
                                                        </div>
                                                    </div><div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Balance with Before Tax contributions only: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultSuperBalanceBeforeTaxBoostOnly))}</div>
                                                        </div>
                                                    </div><div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Balance with After Tax contributions only: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultSuperBalanceAfterTaxBoostOnly))}</div>
                                                        </div>
                                                    </div>
                                                    <div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Balance ALL contributions: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultSuperBalanceTotalBoost))}</div>
                                                        </div>
                                                    </div> 
                                                    <div className="bsc-section">
                                                        <div className="bsc-section-row bsc-bottom-res">
                                                            <div className="bsc-bottom-res-label">Boosted super amount: </div>
                                                            <div className="bsc-bottom-res-text"> {'$' + this.addCommas(Math.round(this.state.resultBoostedByAmountTotalBoost))}</div>
                                                        </div>
                                                                        </div> */}
                                        </>
                                    )}
                            </div>
                            
                        }
                            <div className="bsc-section-row calc-btn-sec mt35">
                                <div className="bsc-label"></div>
                                <div className="bsc-entry  justify-center align-items-center btn-desktop-center">
                                <ActionButton text="Calculate" onClick={this.initiateCalculation} large={true}/>
                                {/* <button className="calculator-btn " id="calculate" onClick={this.initiateCalculation}>Calculate</button> */}
                                </div>
                                {this.state.firstLoad ? <div className="btn-info-text">Click Calculate for result</div> : this.state.changedInput && <div className="btn-info-text red-text">Click Calculate to update</div>}
                            </div>
                        {this.state.showResults &&

                            <div className="bsc-more-contributions-container">   
                                <div className="bsc-more-contributions-section">
                                <Accordion 
                                    header={ this.cashbackBoost() == 0 ? 
                                        (
                                            <div className="bsc-more-contributions-header">
                                                Include shopping cashback contributions?
                                            </div> 
                                        ) : (
                                            <div className="bsc-more-contributions-header">
                                                Included shopping cashback contributions: ${this.addCommas((this.cashbackBoost()).toFixed(2))} pa
                                            </div>
                                        )}
                                    headerLeftPadding="33%"
                                    content={  
                                        <div className="bsc-section-1">
                                            <div className="bsc-content-heading">Online Shopping Spend
                                            
                                            </div>
                                            <ActionButton text="Reset to zero" onClick={this.resetCashback} large={true}/>

                                            {
                                            
                                            Object.keys(this.state.defaultCashbackVariables).map((key,index)=>{
                                                let v = this.state.defaultCashbackVariables[key];
                                                let id = (v.shopping_category.replace(/,/g,'').replace(/\s/g, '')).slice(0,5).toLowerCase();
                                                let inp = id+'Amt';
                                                let sel = id+'Trm';
                                                let res = id+'Res';
                                                let fr = v.default_frequency
                                                let frequency = fr==="Daily"?"D":(fr==="Weekly"?"W":fr==="Fortnightly"?"F":fr==="Monthly"?"M":fr==="Twice/Year"?"B":fr==="Annually"?"A":fr==="Every 2 Yrs"?"E2Y":"Every 3 Yrs"?"E3Y":"Every 4 Yrs"?"E4Y":'M');

                                                return (
                                                <div className="bsc-section-row" key={index}>
                                                    <div className="bsc-label">{v.shopping_category} <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">{v.tooltip}</div></span></span></div>
                                                    <div className="bsc-entry">
                                                        <div className="bsc-input prepend-input-group">
                                                            <span>$</span>
                                                            <input type="number" id={inp} ref={e => this[inp] = e} onChange={this.changeInput} defaultValue={v.default_amount} data-avg={v.ave_amount} className="calc-input" />
                                                        </div>
                                                        <div className="bsc-select">
                                                            <select name="" id={sel} defaultValue={frequency}  ref={e => this[sel] = e} onChange={this.changeInput} data-avg={v.ave_amount} className="calc-input">
                                                                <option value="D">Daily</option> 
                                                                <option value="W">Weekly</option> 
                                                                <option value="F">Fortnightly</option> 
                                                                <option value="M" >Monthly</option> 
                                                                <option value="B">Twice/Year</option> 
                                                                <option value="A">Annually</option> 
                                                                <option value="E2Y">Every 2 Yrs</option> 
                                                                <option value="E3Y">Every 3 Yrs</option> 
                                                                <option value="E4Y">Every 4 Yrs</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="bsc-result"><span className="bsc-res">{ '$'+(Number(this.state.vData[res])).toFixed(2) }</span></div>
                                                </div>)
                                            })

                                            }
                                                
                                            <div className="bsc-section-row">
                                                <div className="bsc-label">How often do you switch energy ?<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">How often do you switch energy</div></span></span></div>
                                                <div className="bsc-entry">
                                                    <div className="bsc-slider" >
                                                        <input type="range" name=""  step="1" min="1" max="5" id="energy-input-frequency" ref={e => this.energyAmt = e} onChange={this.changeSlider} defaultValue="0" className=""/>
                                                        <div className="tickMarks">
                                                            <span >Never</span>
                                                            <span >Every 4 Yrs</span>
                                                            <span >Every 3 Yrs</span>
                                                            <span >Every 2 Yrs</span>
                                                            <span >Annually</span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="bsc-result"><span className="bsc-res">{ '$'+this.state.energyAmt }</span></div>
                                            </div>
                                        
                                            <div className="bsc-section-row">
                                                <div className="bsc-label bsc-center-res-label">Shopping Boost per Annum:</div>
                                                <div className="bsc-result bsc-center-res-text">{'$'+this.addCommas((this.cashbackBoost()).toFixed(2))}</div>
                                            </div>
                                        </div>
                                    } />


                                        <div className="bsc-more-contributions-section">
                                        <Accordion 
                                            header={ ((feesAmount == 0) && (feesPercentage == 0)) ? 
                                            'Include fees (eg fund or investment fees)?' :
                                            'Included Fees : $' + this.addCommas(feesAmount.toFixed(2)) + '  pa.   Percentage : ' + this.addCommas(feesPercentage.toFixed(2)) + '%' }
                                        
                                            headerLeftPadding="33%"
                                            content={
                                                <div className="bsc-section bsc-section-2">
                                                    <div className="bsc-section-row">
                                                        <div className="bsc-label">Fees amount pa<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Fund Fees Amount per annum ($)</div></span></span></div>
                                                        <div className="bsc-entry justify-center">
                                                            <div className="bsc-input prepend-input-group">
                                                                <span>$</span>
                                                                <input 
                                                                    type="number"
                                                                    id="feesAmount"
                                                                    name="feesAmount"
                                                                    onChange={this.changeOtherFundFees} 
                                                                    value={feesAmount}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bsc-section-row">
                                                        <div className="bsc-label">Fees percentage pa<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Fund Fees Percentage per annum (%)</div></span></span></div>
                                                        <div className="bsc-entry justify-center">
                                                            <div className="bsc-input prepend-input-group">
                                                                <span>%</span>
                                                                <input 
                                                                    type="number"
                                                                    id="feesPercentage"
                                                                    name="feesPercentage"
                                                                    onChange={this.changeOtherFundFees}
                                                                    value={feesPercentage} 
                                                                />
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                    }  />
                                    </div>
                                
                                    
                                </div>
                                
                            </div> }
                            </div>
                        
                    
                        <div className="bsc-section">
                        <div className="bsc-disclaimer-assumption-limitation">
                            {/* <div className="bsc-disclaimer-assumption-limitation-title">Disclaimers, Assumptions & Limitations</div> */}
                            <div className="disclaimer">
                                    <div className="pop-over-container">
                                        <h2>Disclaimers</h2>
                                        <PopoverHover
                                            content = {
                                                    <div className="pop-over-content">
                                                        <ul>
                                                            <li>Boost Your Super calculator is a model not a prediction.</li>
                                                            <li>Results are based on the limited information that you provided and assumptions made about the future. They are projected estimates and are in no way guaranteed.</li>
                                                            <li>This calculator cannot predict the effect of using Boost Your Super with certainty because it will depend on your personal circumstances including time out of work etc.</li>
                                                            <li>You are responsible for your own investment decisions and should obtain specific, individual advice from a licensed financial advisor to determine if Boost Your Super is right for you.</li>
                                                            <li>This calculator does not take into account any additional cost of meeting increases in living standards. This may result in the overestimation of the projected retirement amount.</li>
                                                            <li>Percentages earned for different stores and thier products vary from time to time and although we update these percentages on the listed shops regularly, they may be out of date on this calculator and cannot be relied on.</li>
                                                        </ul>
                                                    </div>
                                            }
                                            header = "Disclaimers"
                                            img = {null}
                                            itemToPopover = { 
                                            (<i className="material-icons" style={{ margin: '0 10px 0 10px'}}>info</i>)
                                            }
                                            horizontalPosn = "right"
                                        />
                                    </div>
                            </div>
                            <div className="assumptions">
                                    <div className="pop-over-container">
                                        <h2>Assumptions</h2>
                                        <PopoverHover
                                                content = {
                                                    <div>
                                                        <ul>
                                                            <li>Takes into account incremental increases of employer contributions which are incrementally increasing to 12% on 1 Jul 2025.</li>
                                                            <li>Super fund investment earnings taxed at 15%.</li>
                                                            <li>Employer and other pre-tax contributions taxed at 15%.</li>
                                                        </ul>
                                                    </div>
                                                }
                                                header = "Assumptions"
                                                img = {null}
                                                itemToPopover = { 
                                                (<i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i>)
                                                }
                                                horizontalPosn = "right"
                                        />
                                    </div>
                            </div>
                            <div className="limitations">
                                <div className="pop-over-container">
                                        <h2>Limitations</h2>
                                        <PopoverHover
                                            content = {
                                                <div>
                                                    <ul>
                                                        <li>Calculations are made based on annual contributions being made to super</li>
                                                        <li>This calculator assumes income is less than $250k when calculating taxes on pre-tax salary contributions</li>
                                                    </ul>
                                                </div>
                                            }
                                            header = "Limitations"
                                            img = {null}
                                            itemToPopover = { 
                                            (<i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i>)
                                            }
                                            horizontalPosn = "right"
                                        />
                                </div>

                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </CalculatorWrapper>
        )
    }
}

