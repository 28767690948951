/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import log from "Utils/logger";

import * as ShoppingActionCreators from 'Flux/shopping/actions';
import Deal from './deal';

import styles from './index.css';

const PromotedDealsWrapper = styled.div`${styles}`;

const PromotedDeals = ({
  promotedDeals, authentication, ShoppingActions, currentPromotion,
}) => {
  async function onShopNow(store, type = 'shop') {
    const { merchant_name, retail_store_logo_url, rate } = store;
    const queryStringObject = { merchant_name, retail_store_logo_url, rate };
    const queryString = Object.keys(queryStringObject)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(store[key])}`,
      )
      .join('&');

    try {
      const newTab = window.open('', '_blank');
      newTab.location = `${window.location.origin}/transaction-loading?${queryString}`;

      const getForwardingUrlResult = await ShoppingActions.getForwardingUrl(
        store,
        type,
      );
      window.setTimeout(() => {
        newTab.location = getForwardingUrlResult.data.forwarding_url;
        log({
          event: 'ShopNow',
          type,
          shopper_id: authentication.user.shopper_id,
          retail_store_id: store.retail_store_id,
          source: 'account dashboard',
          ...store,
          ...(type === 'deal' ? { coupon_id: store.coupon_id } : {}),
        });
      }, 2000);

      //setShowModalDialog(true);
      //setClickedStore(store);
    } catch (exception) {
      log({
        shopper_id: authentication.user.shopper_id,
        event: "System-Error",
        source: "ShopNow On Promoted",
        info: exception,
        ...exception,
      });
      console.log(exception);
    }
  }

  function onSetSharedItem() {

  }

  return (
    <PromotedDealsWrapper>
      <div className="content-container">
        {
          currentPromotion ? (
            <div className="deals-header">
              <h2 className="promoted-deals-title">{currentPromotion.name}</h2>
              <p className="promoted-deals-description">{currentPromotion.description}</p>
            </div>
          ) : null
        }
        <Masonry
          className="deals-container"
          options={{ isFitWidth: true }}
        >
          {
            promotedDeals.map((deal) => (
              <Deal deal={deal} onShopNow={onShopNow} onSetSharedItem={onSetSharedItem} />
            ))
          }
        </Masonry>
      </div>
    </PromotedDealsWrapper>
  );
};

PromotedDeals.propTypes = {
  promotedDeals: PropTypes.array.isRequired,
  authentication: PropTypes.object.isRequired,
  ShoppingActions: PropTypes.object.isRequired,
  currentPromotion: PropTypes.object.isRequired,
};

export default connect((state) => ({
  promotedDeals: state.shopping.promotedDeals,
  authentication: state.authentication,
  currentPromotion: state.shopping.currentPromotion,
}), (dispatch) => ({
  ShoppingActions: bindActionCreators(ShoppingActionCreators, dispatch),
}))(PromotedDeals);
