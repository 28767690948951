export default ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: calc(100% - 40px);
      max-width: 1366px;
      flex-direction: column;

      .stats-strip {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .stats-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          max-width: 400px;
          padding: 10px;
          border-radius: 3px;
          /* box-shadow: 0 0 3px 1px ${theme.borderColor}; */
          margin: 5px;
          background-color: #efefef;

          .notification-action {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 10px;
            margin: 5px;
          }
        }
        
      }  

      .transactions-container {
        display: flex;
        width: 98%;
      }

    }
  `;
