import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShareStrip from 'SharedComponents/share-strip';

import ActionButton from 'SharedComponents/action-button';


import styles from './CouponOffer.css';

import './CouponOffer.scss';

const CouponOfferWrapper = styled.div`${styles}`;

const CouponOffer = ({ authentication }) => {
  const { user } = authentication;

  const isOfferValid = moment(user.shopper_registration_date).add(8, 'days') > moment();

  return (
    <CouponOfferWrapper>
      <div className="receipt">
        <div className="receipt-content">
          <div className="coupon-text">
            <h2 className="title">Earn up to 40% Extra for one year</h2>
            <div className="description">
              <p className="text">Each friend referred increases your earnings by extra 10%. Up to max 4 friends. And your friends earn 10% extra too!</p>
            </div>                
            <ShareStrip />
            {/*<Link to="/shops"><ActionButton text="Go&nbsp;to&nbsp;Shops" large /></Link>*/}
          </div>
          {
            isOfferValid ? (
              <div className="coupon-expiry">
                Valid for the next
                {' '}
                {moment(user.shopper_registration_date).add(8, 'days').diff(moment(), 'days')}
                {' '}
days
                            </div>
            ) : (
              <div className="coupon-expiry expired">
                This offer expired
                {' '}
                {moment().diff(moment(user.shopper_registration_date).add(8, 'days'), 'days')}
                {' '}
days ago
                            </div>
            )
          }
          <small className="tcs">T&Cs. Friend must be a new member & sign up with your referral link. Members receiving other Boosts (eg Parental Leave Boost) may not qualify. You may receive a maximum of 40% for 4 friends referred. Extra boost is for up to 1 year. Offer not transferable and can be used in conjuction with other offers provided the total boost does not exceed 50% of base commission earned. </small>
        </div>
      </div>
    </CouponOfferWrapper>
  );
};

CouponOffer.propTypes = {
  authentication: PropTypes.object.isRequired,
};

export default CouponOffer;
