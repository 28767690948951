import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import styles from './PeopleWhoEarnMore.css';

const PeopleWhoEarnMoreWrapper = styled.div`${styles}`;

const PeopleWhoEarnMore = ({ authentication, app }) => {
  const { user, collaboratingCauses } = authentication;
  const ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);
  
  
  return (
    <PeopleWhoEarnMoreWrapper>
      {/* <p className="description">Hi {user.shopper_id} Be sure to email us and let us know if you fall into any of the following categories. We increase the base commission that you earn up to 2x if you are. TnCs apply and will be emailed back to you. </p> */}
      <div className="content-container" id="increase-boost-power">
      <h2 className="title">Increase your Boost Power</h2>
      <div className="content">
        <div className="left-content">
          <p>On occassion we offer opportunities to increase your Boost Power, so you can earn more on each transaction.</p>
          <p>Here are some example opportunities you might find.</p>
        </div>
        <div className="right-content">
          <h3 className="title">Look for offers like these</h3>
          <div className="benefits-container">
            <div className="benefit">
              <div className="image-container">
                <img src={ app.landingPageContent.cash_contributions_from_bys_can_help_image_2 } alt="Parental Leave Bonus Boost" />
              </div>
              <p className="description">25% more on Parental Leave</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={ app.landingPageContent.cash_contributions_from_bys_can_help_image_4 } alt="1st Home Buyer 5% more" />
              </div>
              <p className="description">5% more saving for 1st home</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <i className="material-icons" style={{ color: '#ff4569' }}>favorite</i>
              </div>
              <p className="description">up to 5% more for adding fave shops</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={ app.landingPageContent.cash_contributions_from_bys_can_help_image_5 } alt="Test drive new features" />
              </div>
              <p className="description">Test drive new features</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={app.landingPageContent.cash_contributions_from_bys_can_help_image_3} alt="looking for work" />
              </div>
              <p className="description">25% more on Job Seeker</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={app.landingPageContent.cash_contributions_from_bys_can_help_image_7} alt="short surveys" />
              </div>
              <p className="description">up to 2% more per short survey</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </PeopleWhoEarnMoreWrapper>
  );
}

export default connect((state) => ({ 
  authentication: state.authentication,
  app: state.app,
}))(PeopleWhoEarnMore);