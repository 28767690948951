export default ({ theme }) => {
  const triangleSize = 4;
  const stop1 = triangleSize * 1.42;
  const stop2 = triangleSize * 0.7;
  const stop1r = stop1 + 0.01;
  const stop2r = stop2 + 0.01;
  return `
    // filter: drop-shadow(0 2px 1px rgba(#000, 0.1));
    // max-width: 320px;
    // margin: 0 auto;
    //
    // // $triangle-size: 4px;
    // // $stop1: $triangle-size * 1.42;
    // // $stop2: $triangleSize * 0.7;
    // // $stop1r: $stop1 + 0.01;
    // // $stop2r: $stop2 + 0.01;
    // background:
    //   linear-gradient(135deg, transparent ${stop1}px, white ${stop1r}px) top left,
    //   linear-gradient(45deg, white ${stop2}px, transparent ${stop2r}px) top left,
    //   linear-gradient(135deg, white ${stop2}px, transparent ${stop2r}px) bottom left,
    //   linear-gradient(45deg, transparent ${stop1}px, white ${stop1r}px) bottom left;
    // background-repeat: repeat-x;
    // background-size: ${(triangleSize * 2)}px ${triangleSize}px;
    // padding: ${triangleSize}px 0;
    //
    // &-list {
    //   // You could add another linear-gradient for the white background
    //   // but background on this child div keeps the code MUCH simpler
    //   background-color: white;
    // }
  `
};
