export default ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-between;
  align-items: center;
  justify-content: center;
  width: 290px;
  margin: 10px;
  background-color: ${theme.neutralColor};
  box-shadow: 0 0 0 1px #d9d9d9;
  border-radius: 3px;
  flex-direction: column;

  .coupon-container {
    width: calc(100% - 10px);
    padding: 5px;
    // background-color: orange;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom: solid 1px #d9d9d9;
    min-height: 53px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .deal-code-container {
      display: flex;
      flex-direction: column;
      border: none;
      background-color: unset;

      .click-to-copy {
        margin: 3px 0;

        &.active {
          color: ${theme.darkSecondaryColor};
        }
      }

      .code-container {
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        
        .code {
          text-transform: uppercase;
          font-size: 18px;
          background-color: ${theme.borderColor};
          padding: 3px;
          color: ${theme.darkSecondaryColor};
          margin: 3px 0;
          border-radius: 3px;
          text-align: center;
        }

        &:hover {
          filter: brightness(1.1);
          cursor: copy;
        }
      }
    }

    .no-code-required-container {
      display: flex;
    }
  }

  .adornment-container {
    img {
      position: absolute;
      top: 0;
      right: 5px;
      width: 80px;

      &.discount {
        top: -3px;
      }

      &.sale {
        top: -6px;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    width: calc(100% - 10px);

    .store-image-container {
      height: 120px;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-start;
      // margin: 0px 10px 10px 0;
      flex-direction: column;
      // margin: 7px 10px 10px -10px;
      width: 140px;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;

      img.store-image {
        width: 100%;
      }

      small.store-name {
        margin: 10px;
        display: none;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 2px 1px #d9d9d9;
  }

  .deal-info {
    width: calc(100% - 10px);
    padding: 5px;

    .actions-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .merchant-name {
        margin: 5px 0;
      }

      .shop-now {
        margin: 10px 0;
      }

      .share-this-deal {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: solid 1px ${theme.borderColor};
        border-radius: 12.5px;
        outline: none;
        padding: 3px 6px;

        i {
          font-size: 18px;
          margin: 0 5px 0 0;
        }
      }
    }

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    h2 {
      font-size: 26px;
      margin: 10px 0;
    }

    p {

    }

    div.expiry {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span.times {
        display: flex;
        align-items: center;
      }
    }
  }

  &:hover {
    transform: scale(1.01);
  }

  @media(max-width: 425px) {
    flex-direction: column;

    /* .store-image-container, .deal-info, .actions-container {
      // width: 100% !important;
    } */

    .store-image-container {
      img.store-image {
        max-height: 120px;
        width: unset !important;
        max-width: calc(100% - 20px);
        align-self: flex-start;
      }

      small.store-name {
        align-self: flex-start;
        display: none;
      }
    }
  }
`;
