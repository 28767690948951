export default () => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffead1;

  .content-container {
    max-width: 1366px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;

    .deals-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .promoted-deals-title {
        margin: 10px;
      }
    }

    .deals-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;

      .xblock {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
