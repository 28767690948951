export default ({ theme }) => `
    width: calc(100% - 80px);
    padding: 80px 40px 40px;
    background-color: ${theme.lightBorderColor};
    display: flex;
    align-items: center;
    justify-content: center;

    .content-container {
      max-width: 1366px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .title {
        margin: 10px;
      }
    }

    @media(max-width: 425px) {
      width: calc(100% - 20px);
      padding: 10px;
    }
  `;
