import moment from 'moment';
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import CONFIG from 'Config';

import * as AppActionTypes from './constants';

export function setBeforeInstallPromptFire(state) {
  return {
    type: AppActionTypes.SET_BEFORE_INSTALL_PROMPT_FIRE,
    payload: state,
  };
}

export function hideOnboardingJourney() {
  localStorage.setItem('nextShowOnboardingJourneyTime', moment().add(1, 'day').format());
  return {
    type: AppActionTypes.HIDE_ONBOARDING_JOURNEY,
  };
}

export function finishOnboarding() {
  localStorage.setItem('nextShowOnboardingJourneyTime', moment().add(14, 'days').format());
  return {
    type: AppActionTypes.HIDE_ONBOARDING_JOURNEY,
  };
}

export function fetchLandingPageContent() {
  console.log('APP ACTION - fetchLandingPageContent kicked off')
  return () => axios({
    url: `${CONFIG.apiV3Url}/landingpage/resource`,
    method: 'GET',
  });
}

export function setLandingPageContent(content) {
  return {
    type: AppActionTypes.SET_LANDING_PAGE_CONTENT,
    payload: content,
  };
}

export function setPartnerContent(content) {
  console.log('setPartnerContent ACTION function kicked off with content : ', content);
  return {
    type: AppActionTypes.SET_PARTNER_CONTENT,
    payload: content,
  };
}

export function unSetPartnerContent() {
  console.log('unSetPartnerContent ACTION function kicked off');
  return {
    type: AppActionTypes.UNSET_PARTNER_CONTENT,
  };
}

export function fetchPartnerContent(authOrUrl) {
  console.log('fetchPartnerContent kicked off with authOrUrl : ', authOrUrl);
  return () => axios({
    url: `${CONFIG.apiV3Url}/partners/${authOrUrl}`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function setIsExtensionInstalled(state) {
  return {
    type: AppActionTypes.SET_IS_EXTENSION_INSTALLED,
    payload: state,
  };
}
