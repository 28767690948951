export default () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description {
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }

  .stores-strip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;

    .store-image-container {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-attachment: local;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 3px;
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
`;
