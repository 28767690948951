import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import logoAMP from 'Images/logoAMP.png';
import logoAustralianEthical from 'Images/logoAustralianEthical.png';
import logoAustralianRetirementTrust from 'Images/logoAustralianRetirementTrust.png';
import logoAustralianSuper from 'Images/logoAustralianSuper.png';
import logoCBUS from 'Images/logoCBUS.png';
import logoEquip from 'Images/logoEquip.png';
import logoFutureSuper from 'Images/logoFutureSuper.png';
import logoHostPlus from 'Images/logoHostPlus.png';
import logoHostPlus1 from 'Images/logoHostPlus1.png';
import logoHostPlus2 from 'Images/logoHostPlus2.png';
import logoMediaSuper from 'Images/logoMediaSuper.png';
import logoRest from 'Images/logoRest.png';
import logoSpaceship from 'Images/logoSpaceship.png';
import logoSuperHero from 'Images/logoSuperHero.png';
import logoUniSuper from 'Images/logoUniSuper.png';
import logoVirginMoney from 'Images/logoVirginMoney.png';
import logoCSC from 'Images/logoCSC.png';

import styles from './index.css';

const FundsWePayToWrapper = styled.div`${styles}`;

const FundsWePayTo = () => (
  <FundsWePayToWrapper>
    <div className="content-container" id="funds-we-pay-to">
      <h2 className="title">Your cashback is paid to your super fund or SMSF account</h2>
      <div className="content">
          <p>Some popular and requested super funds we pay into weekly</p>
          <div className="logos-container">
                <img className="logo" src={logoAustralianSuper} />
                <img className="logo" src={logoCSC} />
                <img className="logo" src={logoUniSuper} />
                <img className="logo" src={logoSpaceship} />
                <img className="logo" src={logoAustralianEthical} />
                <img className="logo" src={logoCBUS} />
                <img className="logo" src={logoEquip} />
                <img className="logo" src={logoVirginMoney} />                
                <img className="logo" src={logoFutureSuper} />
                <img className="logo" src={logoAustralianRetirementTrust} />
                <img className="logo" src={logoHostPlus1} />
                <img className="logo" src={logoMediaSuper} />
                <img className="logo" src={logoAMP} />
                <img className="logo" src={logoSuperHero} />
                <img className="logo" src={logoRest} />
          </div>
      </div>
    </div>

  </FundsWePayToWrapper>
);

export default FundsWePayTo;
