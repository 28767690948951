/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isMobile, isChrome } from 'react-device-detect';

import ActionButton from 'SharedComponents/action-button';

import * as AppActions from 'Flux/app/actions';

import CouponOffer from 'SharedComponents/onboarding-journey/CouponOffer';
import ShopOnTheGo from 'SharedComponents/onboarding-journey/ShopOnTheGo';
import GetExtension from 'SharedComponents/onboarding-journey/GetExtension';

import styles from './index.css';

const LandingActionsBannerWrapper = styled.div`${styles}`;

const LandingActionsBanner = ({ authentication, app, appActions }) => {
  const { user, collaboratingCauses } = authentication;
  const ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);
  console.log(['user is: ', user]);
  console.log(['collaborating cause is ', collaboratingCauses]);
  console.log(['ownedCase data: ', ownedCause]);

  let arePaymentDetailsIncomplete = false;
  const {
    charity_bank_account, charity_bank_account_no, charity_bank, charity_bsb, charity_country_id,
  } = ownedCause;

  if ((charity_bank_account && !charity_bank_account.replace(/\s/g, '').length) || (charity_bank_account_no && !charity_bank_account_no.replace(/\s/g, '').length) || (charity_bank && !charity_bank.replace(/\s/g, '').length)) {
    arePaymentDetailsIncomplete = true;

    if (ownedCause.charity_country_id === 'AU' && !charity_bsb.replace(/\s/g, '').length) {
      arePaymentDetailsIncomplete = true;
    }
  }

  const showCoupon = moment(user.shopper_registration_date).add(17, 'days') > moment();

  return (
    <LandingActionsBannerWrapper>
      <div className="content">
        { <h2 className="title">Dashboard</h2> }
        <div className="cards-container">
{/*           {
            showCoupon ? (
              <CouponOffer authentication={authentication} />
            ) : null
          } */}
          <div className="card">
            <div className="card-title">
              {
                isMobile ? (
                  <h3 className="header">Shop on the Go</h3> 
                ) : (
                  <h3 className="header">Add to Homescreen</h3>
                )
              } 
            </div>
              <ShopOnTheGo app={app} appActions={appActions} />
          </div>

          {
            (isChrome && !isMobile) ? (
              <div className="card">
                <div className="card-title">
                  <h3 className="header">Get Chrome Extension Notifier</h3>
                </div>
                
                  <GetExtension />
                
              </div>
            ) : null
          }

          <div className="card">
            <div className="card-title">
                <h3 className="header">Your account is ready to raise funds to Boost your super</h3>
            </div>
            <div className="card-body">
              <p className="text">Click through to 100s of famous shops. Each time you purchase we get a commission and share it with you to your super account.</p>
            </div>           
            <div className="action-container">
              <Link to="/shops"><ActionButton text="Go&nbsp;to&nbsp;Shops" large={true} /></Link>
            </div>
          </div>
          <div className="card">
            <div className="card-title">
              <h3 className="header">Your account including superannuation payment details</h3>
            </div>
            <div className="card-body">
              {
                arePaymentDetailsIncomplete ? (
                  <p className="text">You can still raise funds to Boost Your Super but we will be hold them until you provide the payment details.</p>
                ) : (
                    <p className="text">Update your payment details if they have changed in order for us to make payments to the correct Superannuation account.</p>
                  )
              }
            </div>  
            <div className="action-container">
              <Link to="/account"><ActionButton text="My&nbsp;Account" large={true} /></Link>
            </div>
          </div>
          <div className="card">
            <div className="card-title">
              <h3 className="header">See the status of funds raised from purchases you have made</h3>
            </div>
            <div className="card-body">
            </div>    
            <div className="action-container">
              <Link to="/transactions"><ActionButton text="Transactions" large={true} /></Link>
            </div>
            
          </div>
          {/*
      <XBlock>
        <div className="card">
          <h3 className="header">Earn 10x more with the Super notifier</h3>
          <p className="text">The Boost Your Super extension will notify you when you visit participating shops so you never miss free Super. Users who install it raise over 10x more.</p>
          <div className="action">
            <ActionButton text="Get Notifier" large={true} />
          </div>
        </div>
      </XBlock>
      */}
          <div className="card-filler">&nbsp;</div>
        </div>
      </div>
    </LandingActionsBannerWrapper>
  );
};

LandingActionsBanner.propTypes = {
  authentication: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
};

export default connect((state) => ({
  shopping: state.shopping,
  app: state.app,
  authentication: state.authentication,
}), (dispatch) => ({
  AppActions: bindActionCreators(AppActions, dispatch),
}))(LandingActionsBanner);
