import React, { useEffect } from 'react';
import styled from 'styled-components';

import styles from './HowBoostWorks.css';

const HowBoostWorksWrapper = styled.div`${styles}`;

const HowBoostWorks = ({ shopping }) => {

  useEffect(() => {
    return () => {
      localStorage.setItem('learntHowItWorks', true);
    }
  })
  const { stores } = shopping;
  return (
    <HowBoostWorksWrapper>
      <p className="description">Click through to hundreds of famous shops. When you make a purchase we get a commission and share it with you to your superannuation account.</p>
      <div className="stores-strip">
        {
          stores.sort((a, b) => b.clicks - a.clicks).slice(0, 5).map((store) => (
            <div className="store-image-container" key={store.retail_store_id} style={{ backgroundImage: `url(${store.retail_store_logo_url})` }}></div>
          ))
        }
      </div>
    </HowBoostWorksWrapper>
  );
}

export default HowBoostWorks;
