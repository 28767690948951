export default [{
  label: 'Join',
  tag: 'sign-up',
  compulsoryAction: true,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
}, {
  label: 'How Boost Your Super Works',
  tag: 'learn-how',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnDesktop: false,
},
{
  label: 'Increase Your Boost Power ',
  tag: 'people-who-earn-more',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
},
{
  label: 'Earn Much More with Chrome Extension',
  tag: 'get-extension',
  compulsoryAction: true,
  excludeOnSafariMac: true,
  excludeOnMobile: true,
  excludeOnDesktop: false,
},
{
  label: 'Hacks for Your Super',
  tag: 'hacks-for-your-super',
  compulsoryAction: true,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
},
{
  label: 'Shop On The Go',
  tag: 'shop-on-the-go',
  compulsoryAction: false,
  excludeOnSafariMac: true,
  excludeOnMobile: false,
  excludeOnDesktop: true,
},
{
  label: 'Add to Homescreen',
  tag: 'add-to-homescreen',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnMobile: true,
  excludeOnDesktop: false,
},
{
  label: 'Add fav shops for 5% Bonus Boost',
  tag: 'add-fav-shops',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
}];