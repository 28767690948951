import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from "styled-components";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { browserVersion, deviceType, browserName, osName, mobileModel } from 'react-device-detect';
import log from "Utils/logger";
import { isMobile } from "react-device-detect";

import ContributeNowButton from 'SharedComponents/contribute-now-button';
//import OnboardingJourney from "SharedComponents/onboarding-journey";

import styles from "./index.css";

import NavigationBar from "./components/navigation-bar";
import LandingNavigationBar from './components/landing-navigation-bar';
import BottomNavigation from "./components/bottom-navigation";

import useCheckIsExtensionInstalled from 'SharedHooks/check-extension-installed';
//import useBroadcastAbly from 'SharedHooks/receive-broadcast';


import * as AppActions from "Flux/app/actions";
import { createGlobalStyle } from "styled-components";


const MainLayoutWrapper = styled.main`
  ${styles}
`;



const GlobalStyles = createGlobalStyle`
  html body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  body {
    font-family: 'Lato';
  }
`;

const MainLayout = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const app = useSelector(state => state.app);
  const authentication = useSelector(state => state.authentication);
  //const roundups = useSelector(state => state.roundups);

  const [isFetchingPartnerContent, setIsFetchingPartnerContent] = useState(false);
  const [hasFetchedPartnerContent, setHasFetchedPartnerContent] = useState(false);
  const [partnerTheme, setPartnerTheme] = useState(null);
  const [partnerContent, setPartnerContent] = useState(
    app && app.partnerContent ? app.partnerContent : null);
  const [hasError, setHasError] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();


  console.log ('MainLayout triggered with app:', app);
  console.log ('MainLayout triggered with childrend:', children);

  // useBroadcastAbly({
  //   channelName: authentication?.user ? `private:shopper.${authentication.user.shopper_id}` : null,
  // });

  useEffect(() => {
    let initialWindowHeight = window.innerHeight; 
    let initialWindowWidth = window.innerWidth; 
    
    //let initalViewPortHeight = window.visualViewport.height;
    console.log('resize: Initial window.innerHeight is : initialWindowHeight: ', initialWindowHeight);
    //console.log('resize: Initial window.visualViewport.height is : initalViewPortHeight: ', initalViewPortHeight);
   
    const handleResize = () => {

      const newWindowHeight = window.innerHeight;
      const newWindowWidth = window.innerWidth;
      //const visualViewportHeight = window.visualViewport.height;
      console.log('resize event window.innerHeight is : newWindowHeight: ', newWindowHeight);
      console.log('resize event initialWindowHeight is : initialWindowHeight: ', initialWindowHeight);
      //console.log('resize event window.visualViewport.height is : visualViewportHeight: ', visualViewportHeight);

      if ((initialWindowHeight !== newWindowHeight) || (initialWindowWidth !== newWindowWidth)) {
        console.log('possible legit resize event');
        if (!isMobile || (isMobile && Math.abs(newWindowHeight - initialWindowHeight) > 60)) {
          console.log('legit resize event');
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        }
        } else {
          console.log('mobile keyboard - fake resize event');
          // Resize is likely due to the keyboard
          // ... your keyboard handling logic (if needed)
        }
        initialWindowHeight = newWindowHeight; // Update for the next resize event
      };
      
  
    const params = new URL(document.location).searchParams;
    const referrer = params.get("referrer");
  
    if (referrer) {
      localStorage.setItem("referrer", referrer);
    }
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);
  

  useEffect(() => {
    const handleError = (error, info) => {
      // Log the error or handle it as needed
      console.error(error);
      console.error(info);
            log({
        shopper_id: authentication.user ? authentication.user.shopper_id : "000",
        event: 'System-Error',
        errorIs: error,
        information: info,
      });
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    if (app.partnerContent && app.partnerContent !== partnerContent) {
      console.log('Setting partnerTheme based on app.partnerContent : ', app.partnerContent);
      setPartnerContent(app.partnerContent);
      setPartnerTheme({
        // Define theme based on partner content
        primaryColor: app.partnerContent.primary_color,
        lightPrimaryColor: app.partnerContent.light_primary_color,
        darkPrimaryColor: app.partnerContent.dark_primary_color,
        primaryTextColor: app.partnerContent.primary_text_color,
        secondaryColor: '#212121',
        lightSecondaryColor: '#484848',
        darkSecondaryColor: '#000000',
        lightBackgroundColor: 'floralwhite',
        promotedBackgroundColor: 'floralwhite',
        darkBackgroundColor: '#efefef',
        activeNotificationColor: '#ffba00',
        secondaryTextColor: app.partnerContent.secondary_text_color,
        neutralColor: '#ffffff',
        neutralTextColor: '#000000',
        borderColor: '#c7c7c7',
        lightBorderColor: '#efefef',
        shadowColor: '#c7c7c7',
        headerHeight: 65,
        successColor: '#47e6b1',
        errorBackgroundColor: '#FF6961',
        infoBackgroundColor: '#e5f8f7',
        warningBackgroundColor: '#f9a826',
        accentColor: app.partnerContent.accent_color,
        alternateAccentColor: app.partnerContent.alternate_accent_color
      });
    }
  }, [app.partnerContent]); // Run effect only when partnerContent changes

  useEffect(() => {
    console.log('MainEntry - useEffect - *** Main component mounted');
  
    return () => {
      console.log('MainEntry - useEffect - *** Main component unmounted');
    };
  }, []);
  //if (!app.partnerContent &&

  useEffect(() => {
    if (!app.partnerContent && !isFetchingPartnerContent) {
      console.log('No partner Content on MAIN load - lets fetchPartnerContent.');
      fetchPartnerContent();
    }
    // if (authentication.user && !isFetchingPartnerContent) {
    //   console.log('User signed in or out - fetching partnerContent');
    //   fetchPartnerContent();
    // }
  }, [authentication.user]); // Run effect only on user authentication changes and isFetchingPartnerContent changes

  /*  useEffect(() => {
    if (location.pathname === "/transaction-loading") {
      // Logic for handling transaction-loading route
      return (
        <MainLayoutWrapper
          style={{ width: "100%" }}
          isRightDrawerOpen={app.isRightDrawerOpen}
          id="main"
          data-shopper={JSON.stringify(window.localStorage.getItem('auth_token'))}
        >
          <section className="main no-nav">
            <Helmet>
              <meta name="theme-color" content={partnerTheme.lightPrimaryColor} />
              <meta name="background-color" content={partnerTheme.darkPrimaryColor} />
            </Helmet>
            <div className="content">
              {this.props.children}
              <div style={{ width: "100%", height: 56 }} />
            </div>
          </section>
        </MainLayoutWrapper>
      );
    }
  }, [location.pathname]); //  Run effect only on location pathname changes*/
  const fetchPartnerContent = async () => {
    const backUpPartnerContent = 
      //This needs to be removed once the partner content is fixed. 
      {
        "status": true,
        "partner_domain": "boostyoursuper.com.au",
        "partner_name": "Boost Your Super",
        "referrer_hash": null,
        "partner_background_image": "https:\/\/assets.boostyoursuper.com.au\/partners\/CSC\/1400x600_hero_image_rocket_boy.png",
        "partner_statement": null,
        "partner_logo": "https:\/\/assets.boostyoursuper.com.au\/assets\/BYS_Logo780x120_White_2023V3.png",
        "partner_logo_small": "https:\/\/assets.boostyoursuper.com.au\/assets\/400pxBoostYourSuperLogoSquareBlackV4.png",
        "partner_logo_long": null,
        "partner_web_name": null,
        "allow_registration": true,
        "allow_facebook_login": true,
        "allow_google_login": true,
        "force_update_password": false,
        "boosts_enabled": true,
        "dark_primary_color": "#c7c7c7",
        "light_primary_color": "#ffffff",
        "primary_color": "#fafafa",
        "text_icon_color": "#FFFFFF",
        "accent_color": "#000000",
        "alternate_accent_color": "#FFBA00",
        "primary_text_color": "#000000",
        "secondary_text_color": "#ffffff",
        "divider_color": "#BDBDBD",
        "partner_ext_banner_url": "https:\/\/assets.boostyoursuper.com.au\/assets\/BYSDefaultExtBanner2000x640.png",
        "partner_banner_large_url": null,
        "partner_banner_medium_url": null,
        "partner_campaign_message": null,
        "partner_message_box_style": "style=\"background-color: #E8F0FE;\"",
        "partner_tag_line": "Boost Your Super",
        "partner_button_styling": "style=\"background-color: black;\"",
        "partner_member_status": 2

    };

    setIsFetchingPartnerContent(true);

    try {
      const fetchPartnerContentResult = await dispatch(AppActions.fetchPartnerContent(!authentication.user ? 'resource' : 'shopper'));
      console.log('Main fetchPartnerContentResult.data: ', fetchPartnerContentResult.data);
      dispatch(AppActions.setPartnerContent(fetchPartnerContentResult.data));
      setHasFetchedPartnerContent(true);
    } catch (exception) {
      console.log('Error Fetching Partner Content with exception: ', exception);
      //console.info('fPC exc: ', exception);
      log({
        shopper_id: authentication.user ? authentication.user.shopper_id : "000",
        event: "System-Error",
        source: "Error Fetching Partner Content - MAIN. Setting FE backup partnerContent", 
        info: exception,
        ...exception,
      });
      dispatch(AppActions.setPartnerContent(backUpPartnerContent));
      setHasFetchedPartnerContent(false);
    } finally {
      setIsFetchingPartnerContent(false);
    }
  };


  const renderContent = () => {
    if (partnerTheme === null) {
      return null;
    }
  
    if (hasError) {
      return renderErrorBoundary();
    }
  
    if (location.pathname === "/transaction-loading") {
      return renderTransactionLoading();
    }
    console.log('MainLayout - renderContent - trigger renderMainLayout')
    return renderMainLayout();
  };
  
  const renderErrorBoundary = () => (
    //<ErrorBoundary>
      <div>
        <p>yipes something went wrong :(. We have logged the error and will look into it. Please refresh the page.</p>
        <button
          onClick={() => {
            log({
              shopper_id: authentication.user ? authentication.user.shopper_id : "000",
              event: "System-Error",
              source: "User clicked Reload button", 
            });
            window.location.reload(true);
          }}
        >
          Reload
        </button>
      </div>
    //</ErrorBoundary>
  );
  
  const renderTransactionLoading = () => (
    //<ErrorBoundary>
      <MainLayoutWrapper
        className="hide-scrollbars"
        style={{ 
          width: "100%",
        }}
        id="main"
        data-shopper={JSON.stringify(window.localStorage.getItem('auth_token'))}
      >
        <section className="main no-nav">
          <Helmet>
            <meta name="theme-color" content={partnerTheme.lightPrimaryColor} />
            <meta name="background-color" content={partnerTheme.darkPrimaryColor} />
          </Helmet>
          <div className="content">
            {children}
            <div style={{ width: "100%", height: 56 }} />
          </div>
        </section>
      </MainLayoutWrapper>
    //</ErrorBoundary>
  );
  
  const renderMainLayout = () => (
    //<ErrorBoundary>
      <ThemeProvider theme={partnerTheme}>
        <GlobalStyles />
        <MainLayoutWrapper
          className="hide-scrollbars"
          style={{ width: "100%" }}
          id="main"
          data-shopper={JSON.stringify(window.localStorage.getItem('auth_token'))}
        >
          <Helmet>
            <meta name="theme-color" content={partnerTheme.lightPrimaryColor} />
            <meta name="background-color" content={partnerTheme.darkPrimaryColor} />
          </Helmet>
          <section className="main">
            {windowSize.width > 1023 ? (
              <DesktopLayout />
            ) : (
              <MobileLayout />
            )}
          </section>
        </MainLayoutWrapper>
      </ThemeProvider>
    //</ErrorBoundary>
  );
  
  const DesktopLayout = () => (
    <div className="desktop">
      <div className="navigation">
        {location.pathname === '/' && !authentication.user ? (
          //<LandingNavigationBar
          <NavigationBar 
            fixed 
          />
        ) : (
          <NavigationBar 
            fixed
          />
        )}
      </div>
      <div className="content">
        {children}
        <div style={{ width: "100%", height: 120 }} />
      </div>
       <div className="contribute-now-floating-button">
        <ContributeNowButton
          largeButton={true}
          contributeAndShop={false}
        />
      </div>
    </div>
  );
  
  const MobileLayout = () => (
    <div className="mobile">
      <div className="app-bar">
        {location.pathname === '/' && !authentication.user ? (
          //<LandingNavigationBar minimal />
          <NavigationBar minimal />
        ) : (
          <NavigationBar minimal />
        )}
      </div>
      <div className="content">
        {children}
        <div style={{ width: "100%", height: 135 }} />
      </div>
      <div className="contribute-now-floating-button">
        <ContributeNowButton
          largeButton={false}
          contributeAndShop={false}
        />
      </div>
      <div className="bottom-navigation">
        <BottomNavigation />
      </div>
    </div>
  );
  
  return renderContent();
  
  
};
  

  MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
  };

export default MainLayout;