export default ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description {
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }

  .ios-tap-share {
    background-color: ${theme.lightBorderColor};
    // width: calc(100% + 20px);
    margin: 10px 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ios-share-icon {
      width: 30px;
      margin-bottom: -8px;
    }

    .a2hs-text {
      font-weight: bolder;
      font-size: larger;
    }
  }

  .already-installed {
    background-color: ${theme.successColor};
    width: calc(100% + 20px);
    margin: 10px 0;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .install-now {
    background-color: #fff;
    width: 100%;
    margin: 10px 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
  }

  .indeterminate {
    background-color: #FF9820;
    width: calc(100% + 20px);
    margin: 10px 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      margin: 5px 10px;
    }
  }
`;
