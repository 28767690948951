import React from 'react';
import styled from 'styled-components';
import ShopOnTheGo from './ShopOnTheGo'
import styles from './AddToHomeScreen.css';

const AddToHomeScreenWrapper = styled.div`${styles}`;

const AddToHomeScreen = ({ app, appActions }) => {

  return (
    <AddToHomeScreenWrapper>
    <ShopOnTheGo app={app} appActions={appActions} />
    </AddToHomeScreenWrapper>
  );
};

export default AddToHomeScreen;