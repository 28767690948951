export default ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .description {
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }
`;
