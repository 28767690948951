/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ActionButton from 'SharedComponents/action-button';
import SaleBadge from 'Images/sale-badge.svg';
import DiscountBadge from 'Images/discount-badge.svg';

import styles from './deal.css';

const DealWrapper = styled.div`${styles}`;

const Deal = ({
  deal, onShopNow, onSetSharedItem, showShareButton = true,
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const endDateObject = new Date(deal.end_date);
  let visibleExpiry;

  if (endDateObject.toString() !== 'Invalid Date') {
    if (moment(deal.end_date).diff(moment(), 'hours') < 48) {
      if (moment(deal.end_date).diff(moment(), 'minutes') < 60) {
        visibleExpiry = moment(deal.end_date).fromNow(true);
      } else {
        visibleExpiry = `${moment(deal.end_date).diff(moment(), 'hours')} hours`;
      }
    } else {
      visibleExpiry = moment(deal.end_date).fromNow(true);
    }
  }
  return (
    <DealWrapper deal={deal}>
      <div className="coupon-container">
        <div className="code-container">
          {
            deal.clipboardCode && deal.clipboardCode.length ? (
              <Clipboard
                className="deal-code-container"
                data-clipboard-text={deal.code}
                onSuccess={() => {
                  setCopiedToClipboard(true);
                  setTimeout(() => {
                    setCopiedToClipboard(false);
                  }, 3000);
                }}
              >
                <small className={classNames('click-to-copy', { active: copiedToClipboard })}>{!copiedToClipboard ? 'Click to copy to clipboard' : 'Copied to clipboard'}</small>
                <div className="code-container">
                  <strong className="code">{deal.code}</strong>
                </div>
              </Clipboard>
            ) : (
                <div className="no-code-required-container">
                  <h3 className="promotion-type-title">{deal.promotionTypeTitle}</h3>
                </div>
              )
          }
        </div>
        <div className="adornment-container">
          {
            deal.clipboardCode && deal.clipboardCode.length ? (
              <img src={DiscountBadge} alt="promo" className="deal-adornment discount" />
            ) : (
                <img src={SaleBadge} alt="promo" className="deal-adornment sale" />
              )
          }
        </div>
      </div>
      <div className="header">
        <button type="button" className="store-image-container" onClick={() => onShopNow(deal, 'deal')}>
          <img className="store-image" src={deal.retail_store_logo_url} alt={deal.merchant_name} />
        </button>
      </div>
      <div className="deal-info">
        <div className="actions-container">
          <ActionButton text="SHOP NOW" className="shop-now" onClick={() => onShopNow(deal, 'deal')} style={{ margin: '10px 0' }} />
        </div>
        <h2 className="promotion-type">{deal.promotion_type}</h2>
        <p className="description">{deal.description}</p>
        <div className="expiry">
          {
            endDateObject.toString() === 'Invalid Date' ? (
              <span className="times">
                {`${moment(deal.start_date).fromNow(true)} old`}
                <span className="coupon-expiry">NO EXPIRY</span>
              </span>
            ) : (
                <span className="times">
                  {`${moment(deal.start_date).fromNow(true)} old`}
                  <span className="coupon-expiry" style={{ marginLeft: 15, color: '#16addc !important' }} />
                  <i className="material-icons">timer</i>
                  &nbsp;
                <span>{visibleExpiry}</span>
                </span>
              )
          }
        </div>
      </div>
    </DealWrapper>
  );
};

Deal.propTypes = {
  deal: PropTypes.object.isRequired,
  onShopNow: PropTypes.func.isRequired,
  onSetSharedItem: PropTypes.func.isRequired,
  showShareButton: PropTypes.bool.isRequired,
};

export default Deal;
