import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { isSafari, isIOS, isMobile } from 'react-device-detect';

import ActionButton from 'SharedComponents/action-button';
import * as AppActions from 'Flux/app/actions';
import HowBoostWorks from './HowBoostWorks';
import GetExtension from './GetExtension';
import ShopOnTheGo from './ShopOnTheGo';
import GetStarted from './GetStarted';
import PeopleWhoEarnMore from './PeopleWhoEarnMore';
import HacksForYourSuper from './HacksForYourSuper';
import AddToHomeScreen from './AddToHomeScreen';
import ReferToEarnMore from './AddToHomeScreen';
import CampaignAddFavStores from './CampaignAddFavStores';
import steps from './steps';


import styles from './index.css';

const OnboardingJourneyWrapper = styled.section`${styles}`;

const OnboardingJourney = ({
  shopping, AppActions, app, authentication,
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(getStartStep());
  const { showOnboardingJourney } = app;

  const filteredSteps = steps.filter((step) => {
    if ((isSafari && !isIOS) && step.excludeOnSafariMac) {
      return false;
    }

    if (isMobile && step.excludeOnMobile) {
      return false;
    }

    if (!isMobile && step.excludeOnDesktop) {
      return false;
    }

    return true;
  });

  const activeStep = filteredSteps[activeStepIndex];

  function getStartStep() {
    if (localStorage.getItem('learntHowItWorks')) {
      return 2;
    }

    return 1;
  }

  function onNext() {
    if (activeStepIndex < steps.length - 1) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  }

  function onPrevious() {
    if (activeStepIndex > 1) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  }

  function onFinish() {
    console.log('[AppActions]', AppActions);
    AppActions.finishOnboarding();
  }

  if (!showOnboardingJourney) {
    return false;
  }

  if (!isMobile) {
    return (
      <OnboardingJourneyWrapper>
        <h2>Get More out of Boost Your Super</h2>
        <Stepper activeStep={activeStepIndex} alternativeLabel className="stepper">
          {filteredSteps.map(({ label }, index) => (
            <Step key={label} className={classNames('step', { active: index <= activeStepIndex })}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="active-step">
          {
            activeStep.tag === 'learn-how' ? (
              <HowBoostWorks shopping={shopping} onNext={onNext} />
            ) : activeStep.tag === 'people-who-earn-more' ? (
              <PeopleWhoEarnMore app={app} onNext={onNext} />
            ) : activeStep.tag === 'get-extension' ? (
              <GetExtension app={app} onNext={onNext} />
            ) : activeStep.tag === 'hacks-for-your-super' ? (
              <HacksForYourSuper app={app} onNext={onNext} />
            ) : activeStep.tag === 'shop-on-the-go' ? (
              <ShopOnTheGo app={app} AppActions={AppActions} onNext={onNext} />
            ) : activeStep.tag === 'add-to-homescreen' ? (
              <AddToHomeScreen app={app} AppActions={AppActions} onNext={onNext} />      
            ) : activeStep.tag === 'add-fav-shops' ? (
              <CampaignAddFavStores onNext={onNext} authentication={authentication} />
            ) : (
                      <GetStarted onNext={onNext} authentication={authentication} />
                    )
          }
        </div>
        <div className="stepper-control">
          <ActionButton text="Finish&nbsp;Later" small onClick={() => AppActions.hideOnboardingJourney()} icon="update" />
          <div className="button-group">
            <ActionButton text="Back" icon="keyboard_backspace" onClick={onPrevious} disabled={activeStepIndex === 1} />
            <ActionButton text={activeStepIndex + 1 === filteredSteps.length ? 'Finish' : 'Continue'} icon={activeStepIndex + 1 === filteredSteps.length ? null : 'navigate_next'} onClick={activeStepIndex === filteredSteps.length - 1 ? onFinish : onNext} />
          </div>
        </div>
      </OnboardingJourneyWrapper>
    );
  }

  return (
    <OnboardingJourneyWrapper>
      <Stepper activeStep={activeStepIndex} alternativeLabel className="stepper vertical" orientation="vertical">
        {filteredSteps.map(({ label }, index) => (
          <Step key={label} className={classNames('step', { active: index <= activeStepIndex })}>
            <StepLabel className="step-label">{label}</StepLabel>
            <StepContent className="step-content">
              <div className="active-step">
                {
            activeStep.tag === 'learn-how' ? (
              <HowBoostWorks shopping={shopping} onNext={onNext} />
            ) : activeStep.tag === 'people-who-earn-more' ? (
              <PeopleWhoEarnMore app={app} onNext={onNext} />
            ) : activeStep.tag === 'earn-more' ? (
              <GetExtension app={app} onNext={onNext} />
            ) : activeStep.tag === 'hacks-for-your-super' ? (
              <HacksForYourSuper app={app} onNext={onNext} />
            ) : activeStep.tag === 'shop-on-the-go' ? (
              <ShopOnTheGo app={app} AppActions={AppActions} onNext={onNext} />
            ) : activeStep.tag === 'add-to-homescreen' ? (
              <AddToHomeScreen app={app} AppActions={AppActions} onNext={onNext} />      
            ) : activeStep.tag === 'add-fav-shops' ? (
              <CampaignAddFavStores onNext={onNext} authentication={authentication} />
            ) : (                            <GetStarted onNext={onNext} authentication={authentication} />
                          )
                }
              </div>
              <div className="stepper-control">
                <ActionButton text="Finish&nbsp;Later" small onClick={() => AppActions.hideOnboardingJourney()} icon="update" />
                <div className="button-group">
                  <ActionButton text="Back" icon="keyboard_backspace" onClick={onPrevious} disabled={activeStepIndex === 1} />
                  <ActionButton text={activeStepIndex + 1 === filteredSteps.length ? 'Finish' : 'Continue'} icon={activeStepIndex + 1 === filteredSteps.length ? null : 'navigate_next'} onClick={activeStepIndex === filteredSteps.length - 1 ? onFinish : onNext} />
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </OnboardingJourneyWrapper>
  );
};

export default connect((state) => ({
  shopping: state.shopping,
  app: state.app,
  authentication: state.authentication,
}), (dispatch) => ({
  AppActions: bindActionCreators(AppActions, dispatch),
}))(OnboardingJourney);
