export default () => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1366px;
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 20px 20px 0;

    h2.title {
      text-align: center;
      // margin: 30px 0px;
      width: 100%;
      font-size: 40px;

      @media(max-width: 768px) {
        font-size: 25px;
      }
    }

    .cards-container {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 20px 20px 0;
      margin: 20px 10px 20px;
      overflow: auto;

      .card {
        box-shadow: 0 0 0 2px #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 300px;
        /* width: calc(100% - 40px); */
        padding: 10px;
        border-radius: 8px;
        background-color: #fff;
        margin: 10px;
        min-height: 250px;
        position: relative;
        min-width: 292px;
        
        .card-title{
          min-height: 85px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .card-body {
          height: 76px;
        }

        .action-container{
          display: flex;
          height: 75px;
          width: 100%;
          margin: 10px;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
        }



        @media(min-width: 1670px) {
          // min-height: 430px;
        }

        @media(min-width: 1745px) {
          // min-height: 390px;
        }

        .action {
          position: absolute;
          bottom: 20px;
        }

        &:hover {
          box-shadow: 0 0 4px 2px #d9d9d9;
          cursor: pointer;
        }
      }

      .card-filler {
        width: 50px;
        height: 100%;
      }
    }
  }

  @media(max-width: 768px) {
    .content {
      width: calc(100% - 0px);
      padding: 0px;

      .cards-container {
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;
        
               
        width: calc(100% - 0px);
        padding: 0px;
        margin: 10px 0px;
      }
    }
  }

  @media(max-width: 425px) {
    .content {

      .cards-container {
        //justify-content: flex-start;    
        //flex-wrap: nowrap;
        //flex-direction: column;
    
     
       .card {
         width: 310px;
         min-width: 210px;
         min-height: 300px;
       }
      }
     
    }  
  }
`;
