import React, { memo } from 'react';
import styled from 'styled-components';

import ActionButton from 'SharedComponents/action-button';

import styles from './index.css';

const DashboardCardWrapper = styled.div`${styles}`;

const DashboardCard = ({ title, howItWorks, openInNewWin, valuePropText, buttonLink, buttonDisabled, buttonText, cardImageContent, highlight, onClick, icon}) => {
  //const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  console.log('DashboardCard triggered with DashboardCard title', title);

  function openInNewWindow() {
    console.log('openInNewWindow triggered with typeof : ', typeof buttonLink);
    console.log('openInNewWindow buttonLink: ', buttonLink);
    if (typeof buttonLink === 'string') {
      const webstoreWindow = window.open(buttonLink, '_blank');
      if (webstoreWindow) {
        webstoreWindow.focus();
      }
    } else if (typeof onClick === 'function') {
      const url = onClick();
      if (url) {
        const webstoreWindow = window.open(url, '_blank');
        if (webstoreWindow) {
          webstoreWindow.focus();
        }
      }
    }
  }
  
  return (
    <DashboardCardWrapper >
      <div className="dashboard-card-container">
        <div className="image-container">
          {cardImageContent}
        </div>
        { valuePropText && 
          (<div className="value-prop">
            {valuePropText}
          </div>
          )
        }
        <div className="card-details">
          <div className="card-info-container">
            <div className="title-and-nav-button">
              <div className="title">{title}</div>
              <div className="nav-buttons">
                {onClick && !buttonDisabled &&
                  <ActionButton 
                    style={{margin: "10px"}}
                    text={buttonText}
                    onClick={onClick}
                    icon={icon}
                    disabled={buttonDisabled}
                  />
                }
                {openInNewWin && !buttonDisabled &&
                  <i className="material-icons" onClick={openInNewWindow}>open_in_new</i>
                }
              </div>
            </div>
            <div className="explanation">{howItWorks}</div>
          </div>
        </div>
      </div>


    </DashboardCardWrapper>
  );
}

export default memo(DashboardCard);
