export default () => `
  padding: 10px;
  background-color: #E5F8F7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 30px;


  >.title {
    font-size: 24px;
    text-align: center;
  }

  >.content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 820px;
    flex-wrap: wrap;

    .section {
      margin: 10px;
      min-width: 200px;
    }

    .left {
      flex: 2 1 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .logo {
        width: 400px;

        @media(max-width: 768px) {
          width: 200px;
        }

      }

      .description {
        text-align: center;
        font-weight: bold;
        font-size: 17px;
      }
    }

    .middle {
      flex: 1 1 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar-banner-container {
        height: 200px;
        width: 200px;
        border-radius: 100px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar-banner {
          height: 100%;
        }
      }
    }

    .right {
      flex: 2 1 0px;

      .title {
        font-weight: bold;
        font-weight: 18px;
      }

      .subtitle {
        font-weight: bolder;
        font-size: 14px;;
        margin: 0;
      }

      .description {
        font-size: smaller;
        margin: 5px 0 0;
      }
    }
  }
`;
