import React from 'react';
import styled from 'styled-components';
import ActionButton from 'SharedComponents/action-button';
import { isSafari, isIOS, isMobile } from 'react-device-detect';
import iOSShareImage from './ios_share_icon.svg';
import log from 'Utils/logger';

import styles from './ShopOnTheGo.css';

const ShopOnTheGoWrapper = styled.div`${styles}`;

const shareImage = (
  <img src={iOSShareImage} className="ios-share-icon" />
);

const ShopOnTheGo = ({ app, AppActions }) => {
  function checkStandaloneMode() {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
  }

  function checkStandaloneMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    console.log('checkStandaloneMode:', isStandalone);
    console.log('window.matchMedia("(display-mode: standalone)").matches:', window.matchMedia('(display-mode: standalone)').matches);
    console.log('window.navigator.standalone:', window.navigator.standalone);
    return isStandalone;
  }

  function onInstallButtonClicked() {
    if (!window.deferredInstallPrompt) {
      return;
    }
    window.deferredInstallPrompt.prompt();

    window.deferredInstallPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        log({
          'A2HSprompt': 'User accepted the A2HS prompt'
        });
      } else {
        console.log('User dismissed the A2HS prompt');
        log({
          'A2HSprompt': 'User dismissed the A2HS prompt'
        });
      }
      window.deferredInstallPrompt = null;
      AppActions.setBeforeInstallPromptFire(false);
    });
  }

  return (
    <ShopOnTheGoWrapper>
      {
        isMobile ?(
          <p>Add Boost Your Super App to your mobile device HomeScreen for quick access to shop and earn free super.</p>
        ):
          <p>Add Boost Your Super to your Homescreen. Start your online shopping with a quick click.</p>

      }
     
      {
        checkStandaloneMode() ? (
          <div className="already-installed">
            Boost Your Super is already installed on your device
          </div>
        ) : (isSafari && isIOS) ? (
          <div className="ios-tap-share">
            <span>
Just tap
              {' '}
              {shareImage}
              {' '}
then
              {' '}
              <b className="a2hs-text">Add to HomeScreen</b>
            </span>
          </div>
        ) : (app.hasBeforeInstallPromptBeenFired && window.deferredInstallPrompt) ? (
          <div className="install-now">
            <ActionButton text="Add&nbsp;to&nbsp;Homescreen" icon="get_app" large onClick={onInstallButtonClicked} />
          </div>
        ) : (
          <div className="indeterminate">
            <p>May already be installed on your device or your browser may not support installation.</p>
          </div>
        )
      }
    </ShopOnTheGoWrapper>
  );
};

export default ShopOnTheGo;
