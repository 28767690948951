import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CouponOffer from './CouponOffer';

import styles from './GetStarted.css';

const GetStartedWrapper = styled.div`${styles}`;

const GetStarted = ({ authentication }) => {
  return (
    <GetStartedWrapper>
      <p className="description">Up to 40% EXTRA on each transaction.</p>
      <CouponOffer authentication={authentication} />
    </GetStartedWrapper>
  );
}

GetStarted.propTypes = {
  authentication: PropTypes.object.isRequired,
}

export default GetStarted;
