export default ({ theme }) => `

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffead1;
  
  .content-container {
    width: calc(100% - 40px);
    max-width: 1366px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    @media(min-width: 1063px) {
      .left-content {
        p {
          font-size: 40px;
        }
      }
    }

    @media(max-width: 768px) {
      .left-content, .right-content {
        min-width: unset !important;
        width: 100%;
      }

      .content {
        flex-direction: column !important;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      .left-content, .right-content {
        flex: 1 1 0px;
        min-width: 512px;
      }

      .right-content {
        align-items: center;
        justify-content: center;

        h3 {
          font-weight: bold;
          text-align: center;
          font-weight: bolder;
          font-size: 17px;
          color: ${theme.accentColor};
          text-transform: uppercase;
        }

        .benefits-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          .benefit {
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 160px;

            .image-container {
              height: 70px;
              width: 70px;
              border-radius: 35px;
              background-color: rgba(0, 0, 0, 0.09);
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 40px;
              }
            }

            .description {
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }

    h2 {
      font-weight: bolder;
    }

    p {
      font-size: 20px;
    }
  }





  .description {
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }

  .list {
    margin: 10px 0;
    padding: 10px 20px;
    display: flex;
    align-items: left;
    justify-content: center;
  }
`;