/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import IconButton from 'SharedComponents/icon-button';

import styles from './index.css';

const AccordionWrapper = styled.div`
  ${styles}
`;

const Accordion = ({ header, content, link, headerLeftPadding }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <AccordionWrapper isOpen={isOpen} headerLeftPadding={headerLeftPadding}>
      <div className="header" role="button" onClick={() => toggleOpen(!isOpen)}>
        <IconButton onClick={() => toggleOpen(!isOpen)}>
          <i className="material-icons">
            {!isOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
          </i>
        </IconButton>
        <div className="header-container">
          <h3>{header}</h3>
        </div>
      </div>
      <div className="content">
        <p className="content">
          {content}
          {link}
        </p>
      </div>
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.node,
};

export default Accordion;
